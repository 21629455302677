import { PlayerControls } from '@axteams-one/bws-cloud-bwo-player'
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { Temporal } from '@js-temporal/polyfill'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import useKeyPress from '../../hooks/useKeyPress'
import { Stream } from '../../util/stream'
import ControlButtons from './ControlButtons'
import Timeline from './Timeline'
import VideoOverlayControls from './VideoOverlayControls'
import { PlayerState } from './VideoPlayer'

export interface VideoControls extends PlayerControls {
  /**  Open in google maps */
  OpenInGoogleMaps: () => void
  /** Toggle info / debug overlay. */
  toggleInfoOverlay: () => void
  /** Sends acknowledgement to the device currently streaming. */
  acknowledgeStream: () => void
  fullscreen: () => void
}

type VideoControlsProps = {
  shouldBeLive: boolean
  setShouldBeLive: React.Dispatch<React.SetStateAction<boolean>>
  stream: Stream
  controls: VideoControls
  showInfoOverlay: boolean
  fullscreen: boolean
  state: PlayerState
  setLastInteract: React.Dispatch<React.SetStateAction<Temporal.Instant>>
  lastKnownLocation?: GeolocationPosition
}

/**
 * 'The factor to increase size of timeline and scrubber when scrubbing, i.e. on
 * hover or active.
 */
const SCRUBBING_SIZE_FACTOR = 1.2
/**
 * The min distance between current player position and live to be considered
 * live, i.e. trigger 'go to live', in seconds.
 */
const LIVE_GAP = 0.5

const useStyles = makeStyles({
  live: {
    '& .videoProgress': {
      // Live red color
      backgroundColor: '#FF0000',
    },
    '& .timeline': {
      // Add margin to retain video controls height when height of timeline is
      // reduced by 1px, from 2px to 1px.
      marginBlockEnd: '1px',
      height: '1px',
    },
    '& .liveDot': {
      backgroundColor: '#FF0000',
    },
    '@media(hover: hover) and (pointer: fine)': {
      '& .timelineSection:hover, & .timelineSection:active': {
        '& .scrubber': {
          animationName: {
            '0%': {
              transform: `translate(50%, -50%) scale(${
                SCRUBBING_SIZE_FACTOR * 0.95
              }, 0.95)`,
              boxShadow: '0 0 0 0 #FF0000B3, 0 4px 4px #00003F',
            },
            '70%': {
              transform: `translate(50%, -50%) scale(${
                SCRUBBING_SIZE_FACTOR * 1.2
              }, 1.2)`,
              boxShadow: '0 0 0 12px #FF00001A, 0 4px 4px #00003F',
            },
            '100%': {
              transform: `translate(50%, -50%) scale(${
                SCRUBBING_SIZE_FACTOR * 0.95
              }, 0.95)`,
              boxShadow: '0 0 0 0 #FF00001A, 0 4px 4px #00003F',
            },
          },
        },
      },
    },
    '& .scrubber': {
      // Live red color
      backgroundColor: '#FF0000',
      boxShadow: '0 0 0 4px #FF00007F, 0 4px 4px #00003F',
      animationDuration: '2s',
      animationIterationCount: 'infinite',
      animationName: {
        '0%': {
          transform: 'translate(50%, -50%) scale(0.95)',
          boxShadow: '0 0 0 0 #FF0000B3, 0 4px 4px #00003F',
        },
        '70%': {
          transform: 'translate(50%, -50%) scale(1.2)',
          boxShadow: '0 0 0 12px #FF00001A, 0 4px 4px #00003F',
        },
        '100%': {
          transform: 'translate(50%, -50%) scale(0.95)',
          boxShadow: '0 0 0 0 #FF00001A, 0 4px 4px #00003F',
        },
      },
    },
  },
  recording: {
    '& .videoProgress': { backgroundColor: '#adadad' },
    '& .scrubber': {
      backgroundColor: '#adadad',
      // temporary color from figma file
      boxShadow: '0 0 0 4px #ADADAD7F, 0 4px 4px #00003F',
    },
    '& .liveDot': {
      display: 'none',
    },
  },
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    paddingBlockEnd: tokens.spacingVerticalS,
    rowGap: tokens.spacingVerticalS,
  },
})

export function VideoControls({
  shouldBeLive,
  setShouldBeLive,
  stream,
  controls,
  showInfoOverlay,
  fullscreen,
  state,
  setLastInteract,
  lastKnownLocation,
}: VideoControlsProps) {
  const styles = useStyles()

  const [scrubPosition, setScrubPosition] = useState<number>(0)
  const [previewPosition, setPreviewPosition] = useState<number>(0)
  const timelineRef = useRef<HTMLDivElement>(null)
  const progressWidthRef = useRef<number>(0)
  const [previewWidth, setPreviewWidth] = useState<number | null>(null)
  const isScrubbingRef = useRef<boolean>(false)

  const displayedPosition = isScrubbingRef.current
    ? scrubPosition
    : state.presentedPosition

  // Wrapper for controls to enable/disable enforced live position if position is changed by user.
  // TODO: Move into bwo-player.
  const controlsWrapper: VideoControls = useMemo(
    () =>
      stream.ongoing
        ? {
            ...controls,
            play: () => {
              const secondsFromLive =
                state.presentedDuration - displayedPosition
              // If positioning (scrubbing) to live then video should remain live once pointer is released, i.e !isScrubbing.current.
              const positioningLive = secondsFromLive <= LIVE_GAP
              setShouldBeLive(positioningLive && !isScrubbingRef.current)
              controls.play()
            },
            pause: () => {
              setShouldBeLive(false)
              controls.pause()
            },
            setPosition: (position) => {
              if (!timelineRef.current) {
                return
              }
              controls.setPosition(position)
              progressWidthRef.current =
                (position / state.presentedDuration) *
                timelineRef.current.offsetWidth
            },
            skip: (seconds) => {
              // If skipping to live then video should remain live.
              const skippingToLive =
                seconds > 0 &&
                state.presentedDuration - displayedPosition - seconds <= 1

              // if skipping backwards from live then position relative to
              // presented duration to account for live gap.
              if (shouldBeLive && seconds < 0) {
                setShouldBeLive(skippingToLive)
                controls.setPosition(
                  Math.round(state.presentedDuration + seconds)
                )
              } else {
                setShouldBeLive(skippingToLive)
                controls.skip(seconds)
              }
            },
          }
        : controls,
    [
      controls,
      displayedPosition,
      setShouldBeLive,
      shouldBeLive,
      state.presentedDuration,
      stream.ongoing,
    ]
  )

  const keydownHandler = useCallback(
    (event: KeyboardEvent) => {
      switch (event.code) {
        case 'ArrowLeft':
          controlsWrapper.skip(-15)
          break
        case 'Space':
          event.preventDefault()
          state.isPlaying ? controlsWrapper.pause() : controlsWrapper.play()
          break
        case 'ArrowRight':
          controlsWrapper.skip(15)
          break
      }
      setLastInteract(Temporal.Now.instant())
    },
    [controlsWrapper, setLastInteract, state.isPlaying]
  )

  useKeyPress(['ArrowLeft', 'Space', 'ArrowRight'], keydownHandler)

  useEffect(() => {
    if (state.isLive && state.playbackRate > 1) {
      controlsWrapper.setPlaybackRate(1)
      setShouldBeLive(true)
    }
  }, [controlsWrapper, setShouldBeLive, state.isLive, state.playbackRate])

  useEffect(() => {
    if (!state.isLive && shouldBeLive && stream.ongoing) {
      controlsWrapper.goToLive()
    }
  }, [controlsWrapper, shouldBeLive, state.isLive, stream.ongoing])

  useEffect(() => {
    setShouldBeLive(stream.ongoing)
  }, [setShouldBeLive, stream.ongoing])

  return (
    <div
      className={
        shouldBeLive
          ? mergeClasses(styles.container, styles.live)
          : !stream.ongoing
            ? mergeClasses(styles.container, styles.recording)
            : styles.container
      }
    >
      <VideoOverlayControls // Rename to reflect new design
        stream={stream}
        timelineRef={timelineRef}
        isScrubbingRef={isScrubbingRef}
        progressWidthRef={progressWidthRef}
        previewWidth={previewWidth}
        setPreviewWidth={setPreviewWidth}
        controlsWrapper={controlsWrapper}
        state={state}
        shouldBeLive={shouldBeLive}
        setShouldBeLive={setShouldBeLive}
        displayedPosition={displayedPosition}
        setScrubPosition={setScrubPosition}
        previewPosition={previewPosition}
        setPreviewPosition={setPreviewPosition}
      />
      <Timeline // Rename to reflect new design
        progressWidthRef={progressWidthRef}
        state={state}
        shouldBeLive={shouldBeLive}
        scrubPosition={scrubPosition}
        isScrubbingRef={isScrubbingRef}
      />
      <ControlButtons // Rename to reflect new design
        stream={stream}
        controlsWrapper={controlsWrapper}
        state={state}
        shouldBeLive={shouldBeLive}
        showInfoOverlay={showInfoOverlay}
        fullscreen={fullscreen}
        isScrubbingRef={isScrubbingRef}
        displayedPosition={displayedPosition}
        lastKnownLocation={lastKnownLocation}
      />
    </div>
  )
}

import { makeStyles } from '@fluentui/react-components'
import { useState } from 'react'
import { animated, useTransition } from 'react-spring'

import { useThemeId } from '../../providers/ThemeProvider'

const useStyles = makeStyles({
  backgroundImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'right',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
})

type AnimatedBackgroundImageProps = {
  lightBackgroundImageUrl: string
  darkBackgroundImageUrl: string
}

/**
 * Shows a specific image based on the current theme,
 * with a fade-in transition over 1 second after load.
 * @param lightBackgroundImageUrl
 * @param darkBackgroundImageUrl
 */
export function AnimatedLicenseBackgroundImage({
  lightBackgroundImageUrl,
  darkBackgroundImageUrl,
}: AnimatedBackgroundImageProps) {
  const styles = useStyles()
  const [themeId] = useThemeId()
  const [loaded, setLoaded] = useState(false)

  const backgroundImage =
    themeId === 'light'
      ? `linear-gradient(to right, white 40%, transparent 65%), url(${lightBackgroundImageUrl})`
      : `linear-gradient(to right, rgba(0, 0, 0, 0.85) 40%, transparent 75%), url(${darkBackgroundImageUrl})`

  const imageTransition = useTransition(0, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: { duration: 1000 },
  })

  return (
    <>
      <img
        style={{ display: 'none' }}
        src={darkBackgroundImageUrl}
        onLoad={() => setLoaded(true)}
        alt={''}
      />
      {loaded &&
        imageTransition((style) => (
          <animated.div
            className={styles.backgroundImage}
            style={{
              backgroundImage: backgroundImage,
              ...style,
            }}
          />
        ))}
    </>
  )
}

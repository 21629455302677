import { useCallback, useEffect, useState } from 'react'
import { Client } from 'urql'

import {
  GetStreamsDocument,
  GetStreamsQuery,
} from '../graphql/__generated__/graphql'
import { streamMapper } from '../util/mapper'
import { Stream, compareTriggerTimestamp } from '../util/stream'

type useGetStreamsProps = {
  client?: Client
  organizationId: string
  recipients?: string[]
}

function useGetStreams({
  client,
  organizationId,
  recipients,
}: useGetStreamsProps): [Stream[] | undefined, () => void] {
  const [streams, setStreams] = useState<Stream[]>()

  const getStreams: () => void = useCallback(() => {
    if (!client) {
      return
    }

    client
      .query<GetStreamsQuery>(
        GetStreamsDocument,
        {
          options: { organizationId, recipients },
        },
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then((result) => {
        if (result.error) {
          console.error(result.error.message)
        } else {
          const data = result.data?.getStreams

          if (data && organizationId) {
            const streams = data.map(streamMapper)
            streams.sort(compareTriggerTimestamp)
            setStreams(streams)
          }
        }
      })
  }, [client, organizationId, recipients])

  useEffect(() => {
    getStreams()
  }, [getStreams])

  return [streams, getStreams]
}

export { useGetStreams }

import {
  DisclaimSystemDocument,
  DisclaimSystemMutation,
  ErrorCode,
} from '../graphql/__generated__/graphql'
import { ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

function useDisclaimSystem() {
  const client = useUrqlClient(Api.Bwo)

  return function (systemId: string) {
    return client
      ?.mutation<DisclaimSystemMutation>(DisclaimSystemDocument, {
        input: {
          systemID: systemId,
        },
      })
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode =
            (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
            InternalErrorCode.NetworkError
          return {
            status: ApiStatus.Rejected,
            error: errorCode,
          }
        } else {
          return {
            status: ApiStatus.Resolved,
          }
        }
      })
  }
}

export { useDisclaimSystem }

import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { ReactNode } from 'react'

const useStyles = makeStyles({
  selected: {
    '& .content': {
      backgroundColor: tokens.colorNeutralBackground1Selected,
    },
    '& .arrow': {
      fill: tokens.colorNeutralBackground1Selected,
    },
  },
  hovered: {
    '& .content': {
      backgroundColor: tokens.colorNeutralBackground3Hover,
    },
    '& .arrow': {
      fill: tokens.colorNeutralBackground3Hover,
    },
  },
  container: {
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transform: 'translate(-50%, -100%)',
  },
  content: {
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
    pointerEvents: 'auto',
    backgroundColor: tokens.colorNeutralBackground1,
    borderRadius: tokens.spacingVerticalXS,
    padding: `${tokens.spacingVerticalXS} ${tokens.spacingHorizontalS}`,
    boxShadow: tokens.shadow8,
  },
  arrow: {
    zIndex: 100,
    cursor: 'pointer',
    pointerEvents: 'auto',
    '> path': {
      fill: tokens.colorNeutralBackground1,
      boxShadow: tokens.shadow8,
    },
  },
})

type LabelProps = {
  subject: string
  selected: boolean
  hovered: boolean
  onClick: (subject: string) => void
  onHover: (subject?: string) => void
  children: ReactNode
  position: [x: number, y: number]
}

export default function Label({
  subject,
  position,
  selected,
  hovered,
  onClick,
  onHover,
  children,
}: LabelProps) {
  const styles = useStyles()

  return (
    <div
      className={className(selected, hovered)}
      style={{
        left: `${position[0]}px`,
        top: `${position[1]}px`,
      }}
    >
      <div
        onClick={() => onClick(subject)}
        onMouseEnter={() => onHover(subject)}
        onMouseLeave={() => onHover()}
        className={mergeClasses(styles.content, 'content')}
      >
        {children}
      </div>
      <svg
        className={styles.arrow}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="8"
        viewBox="0 0 16 8"
      >
        <path d="M0,0 l8,8 l8,-8" className="arrow" />
      </svg>
    </div>
  )

  function className(selected: boolean, hovered: boolean) {
    let classes = styles.container
    if (selected) {
      classes = mergeClasses(classes, styles.selected)
    }

    if (hovered) {
      classes = mergeClasses(classes, styles.hovered)
    }

    return classes
  }
}

import { Button, makeStyles } from '@fluentui/react-components'
import { Delete20Regular } from '@fluentui/react-icons'
import { useTranslation } from 'react-i18next'

import { WebhookEventSubscription } from '../../graphql/__generated__/graphql'

type WebhookUrlListProps = {
  list: WebhookEventSubscription[]
  onDelete: (id: string) => void
}

const useStyles = makeStyles({
  urlColumn: {
    width: '100%',
  },
})

function WebhookUrlList({ list, onDelete }: WebhookUrlListProps) {
  const styles = useStyles()
  const { t } = useTranslation()

  if (!list) {
    return null
  }

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th className={styles.urlColumn}>{t('common:url')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.map((subscription) => {
            return (
              <tr key={subscription.subscriptionArn}>
                <td title={subscription.url}>{subscription.url}</td>
                <td>
                  <Button
                    appearance="subtle"
                    icon={<Delete20Regular />}
                    onClick={() => {
                      onDelete(subscription.subscriptionArn)
                    }}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export { WebhookUrlList }

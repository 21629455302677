import {
  MediaErrorDark,
  MediaErrorLight,
  NoAccessDark,
  NoAccessLight,
  NotFoundDark,
  NotFoundLight,
} from '@axiscommunications/fluent-illustrations'
import { Spinner, makeStyles, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorCode } from '../../graphql/__generated__/graphql'
import { ApiStatus } from '../../hooks/types'
import { StoredKey } from '../../hooks/useContentKeys'
import { useGetStream } from '../../hooks/useGetStream'
import { Api, useUrqlClient } from '../../hooks/useUrqlClient'
import { useThemeId } from '../../providers/ThemeProvider'
import { Illustration } from '../Illustration'
import { VideoPlayer } from './VideoPlayer'

const useStyles = makeStyles({
  streamContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  spinner: {
    height: '100%',
    width: '100%',
  },
  errorPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    rowGap: tokens.spacingVerticalM,
    padding: tokens.spacingVerticalL,
  },
})

type VideoPlayerContainerProps = {
  keys: StoredKey[]
  streamId: string
  inGroupView?: boolean
}

function VideoPlayerContainer({
  keys,
  streamId,
  inGroupView = false,
}: VideoPlayerContainerProps) {
  const styles = useStyles()
  const { t } = useTranslation('video-player')
  const [themeId] = useThemeId()

  const client = useUrqlClient(Api.Bwo)

  const recipients = useMemo(() => keys.map((x) => x.thumbprint), [keys])
  const [stream, reexecuteGetStream] = useGetStream(
    client,
    streamId,
    recipients
  )

  if (stream.status === ApiStatus.Loading || stream.status === ApiStatus.Idle) {
    return (
      <Spinner appearance="primary" className={styles.spinner} size="huge" />
    )
  }

  if (stream.status === ApiStatus.Rejected) {
    if (stream.error === ErrorCode.BwoUnauthorized) {
      return (
        <div className={styles.errorPanel}>
          <Illustration
            title={t('no-access-to-stream')}
            description={t('no-access-to-stream-information')}
            illustration={themeId === 'light' ? NoAccessLight : NoAccessDark}
          />
        </div>
      )
    } else if (stream.error === ErrorCode.BwoStreamNotFound) {
      return (
        <div className={styles.errorPanel}>
          <Illustration
            title={t('stream-not-found')}
            description={t('stream-not-found-information')}
            illustration={themeId === 'light' ? NotFoundLight : NotFoundDark}
          />
        </div>
      )
    } else {
      return (
        <div className={styles.errorPanel}>
          <Illustration
            title={t('problem-loading-stream')}
            description={t('try-again-later')}
            illustration={
              themeId === 'light' ? MediaErrorLight : MediaErrorDark
            }
          />
        </div>
      )
    }
  }

  return (
    <div className={styles.streamContainer}>
      <VideoPlayer
        contentKeys={keys}
        key={streamId}
        stream={stream.data}
        reexecuteGetStream={reexecuteGetStream}
        inGroupView={inGroupView}
      />
    </div>
  )
}

export { VideoPlayerContainer }

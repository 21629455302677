import { Temporal } from '@js-temporal/polyfill'

import {
  GetStreamQuery,
  GetStreamsQuery,
} from '../graphql/__generated__/graphql'
import { gpsStringToGeoLocationPosition } from './gps'
import { Metadata, Stream } from './stream'
import { UtcOffsetFromSecondsToString } from './time'

// We're using the same mapper function for the return data from both
// retrieving multiple streams and a single stream. They contain mostly the
// same data but are different types and a single stream contains more data.
type CombinedStreamType =
  | GetStreamQuery['getStream']
  | GetStreamsQuery['getStreams'][number]
function getTimestamp(time: string, offset: string) {
  if (!time || !offset) {
    return
  }
  return new Temporal.ZonedDateTime(
    BigInt(Number(time) * 1000),
    UtcOffsetFromSecondsToString(Number(offset))
  )
}
export function streamMapper(stream: CombinedStreamType): Stream {
  const { __typename, duration, metadata, ...rest } = stream

  // The client includes the __typename in the crypto object, which is in
  // violation of the specification for the metadata, hence, we need to remove
  // it
  const streamWithDash = stream as GetStreamQuery['getStream']
  if (streamWithDash.dash && streamWithDash.dash.crypto) {
    delete streamWithDash.dash.crypto.__typename
  }

  return {
    ...rest,
    duration: Temporal.Duration.from({
      nanoseconds: Number(duration),
    }).round({ largestUnit: 'hours' }),
    metadata: metadataMapper(metadata),
    dash: streamWithDash?.dash,
  }
}

export function metadataMapper(metadata: Record<string, string>): Metadata {
  // URL Decode
  if (metadata) {
    for (const [key, value] of Object.entries(metadata)) {
      try {
        metadata[key.toLowerCase()] =
          value && typeof value === 'string' ? decodeURIComponent(value) : value
      } catch {
        // Do nothing
      }
    }
  }

  const startTimestamp = getTimestamp(metadata?.starttime, metadata?.tzoffset)

  const triggerTimestamp = getTimestamp(
    metadata?.triggertime,
    metadata?.tzoffset
  )

  return {
    bearerName: metadata?.wearername,
    bearerShowId: metadata?.wearershowid,
    gps: gpsStringToGeoLocationPosition(metadata?.gps),
    startTimestamp,
    triggerTimestamp,
  }
}

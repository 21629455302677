export function gpsStringToGeoLocationPosition(
  gpsString?: string
): GeolocationPosition | undefined {
  if (!gpsString) {
    return
  }
  const gpsObject = Object.fromEntries(
    gpsString
      .split(/; ?/)
      .filter((x) => x)
      .map((x) => x.split(/=/))
  )

  const geoLocationPosition = {
    coords: {
      accuracy: Number(gpsObject.eph),
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      latitude: Number(gpsObject.lat),
      longitude: Number(gpsObject.lon),
      speed: null,
    },
    timestamp: Number(gpsObject.ts),
  }
  return geoLocationPosition
}

import { useEffect, useState } from 'react'

/** Returns the current fullscreen element or null if there is no fullscreen
 * element. */
export function useFullscreenElement() {
  const [fullscreenElement, setFullscreenElement] = useState<Element | null>(
    null
  )

  useEffect(() => {
    const fullScreenChangeHandler = () =>
      setFullscreenElement(document.fullscreenElement)
    document.addEventListener('fullscreenchange', fullScreenChangeHandler)

    return () =>
      document.removeEventListener('fullscreenchange', fullScreenChangeHandler)
  }, [])

  return fullscreenElement
}

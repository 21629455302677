import { Card, Text, makeStyles } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'

import { useGetPopulatedTopbarUrl } from '../../providers/TopbarProvider'
import { MySystemsApplicationId } from '../../util/MySystemsApplicationId'
import { ExternalLink } from '../ExternalLink'

const useStyles = makeStyles({
  card: {
    maxWidth: '600px',
  },
  inviteLink: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

export function StreamViewersSettings() {
  const styles = useStyles()
  const userManagementUrl = useGetPopulatedTopbarUrl(MySystemsApplicationId.IAM)
  const { t } = useTranslation('settings-stream-viewers')

  return (
    <Card size="large" className={styles.card}>
      <Text as="h2" size={400} weight="semibold">
        {t('stream-viewers')}
      </Text>
      <Text>{t('instructions')}</Text>
      <div className={styles.inviteLink}>
        <ExternalLink href={userManagementUrl} text="AXIS User Management" />
      </div>
    </Card>
  )
}

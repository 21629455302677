import { Message } from '@axteams-one/bws-cloud-azure-web-pubsub'
import { Position } from '@axteams-one/bws-cloud-maps/layers/tracking'
import { PositionHistory } from '@axteams-one/bws-cloud-maps/react/useTrailsLayer'
import { Temporal } from '@js-temporal/polyfill'
import { useCallback, useEffect, useState } from 'react'

import { CloudEvent, PositionEventData } from '../util/CloudEvent'
import { bearerIdFromSubject, subjectFromBearerId } from '../util/map'
import { Stream } from '../util/stream'
import { PositionFilter } from './positionFilters'

export function usePositioningWebSocketMessageHandler(
  filters: PositionFilter[],
  positionHistoriesDuration: number = 0,
  streams: Stream[]
): {
  positions: Position[]
  positionHistories: Map<string, PositionHistory>
  messageHandler: (event: CustomEvent<Message>) => void
} {
  const [positions, setPositions] = useState<Position[]>([])
  const [positionHistories, setPositionHistories] = useState(
    new Map<string, PositionHistory>()
  )

  const messageHandler = useCallback(
    (event: CustomEvent<Message>) => {
      const message = event.detail.data as CloudEvent<PositionEventData>
      const time = Temporal.Instant.from(message.time).epochMilliseconds
      const localTime = Temporal.Now.instant().epochMilliseconds

      setPositions((previousPositions) => {
        // Ignore old messages.
        const previousPosition = previousPositions.find(
          (position) => position.subject === message.subject
        )
        if (previousPosition && previousPosition.time >= time) {
          return previousPositions
        }

        const position: Position = {
          latitude: message.data.lat,
          longitude: message.data.lon,
          eph: message.data.eph,
          subject: message.subject,
          heading: message.data.heading,
          time,
          localTime,
        }

        // Skip positions older than stream start time (incl. pre-buffer).
        // E.g. old positions sent just after stream start.
        const bearerId = bearerIdFromSubject(position.subject)
        const stream = streams.find((stream) => stream.bearerId === bearerId)
        const streamStartTime =
          stream?.metadata.startTimestamp?.epochMilliseconds
        if (stream && streamStartTime && time < streamStartTime) {
          return previousPositions
        }

        const dt = previousPosition ? position.time - previousPosition.time : 0

        let filteredPosition = position
        if (filters) {
          for (const filter of filters) {
            filteredPosition = filter(
              message.subject,
              filteredPosition,
              dt / 1000
            )
          }
        }

        setPositionHistories((oldPositionHistories) => {
          const newPositionHistories = new Map(oldPositionHistories)

          const newEntry = {
            latitude: message.data.lat,
            longitude: message.data.lon,
            localTime,
          }

          const positionHistory =
            newPositionHistories.get(message.subject) ?? []

          newPositionHistories.set(message.subject, [
            ...positionHistory,
            newEntry,
          ])

          return newPositionHistories
        })

        // Sort the positions so that the rendering order stays the same,
        // in order to prevent positions from changing draw order and flickering
        return [
          ...previousPositions.filter(
            (position) => position.subject !== message.subject
          ),
          filteredPosition,
        ].sort((a: Position, b: Position) => a.subject.localeCompare(b.subject))
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...filters]
  )

  // Remove old positions in history when positions are updated
  useEffect(
    () =>
      setPositionHistories((old) =>
        discardOldPositionHistoryEntries(old, positionHistoriesDuration)
      ),
    [positions, positionHistoriesDuration]
  )

  useEffect(() => {
    // Remove positions belonging to earlier streams
    setPositions((oldPositions) => {
      return oldPositions.filter(({ subject, time }) => {
        const bearerId = bearerIdFromSubject(subject)
        const stream = streams.find((stream) => stream.bearerId === bearerId)
        if (!stream) {
          return true // For non streaming positions compatibility
        }
        const timestamp = stream?.metadata.startTimestamp
        return timestamp && time > timestamp.epochMilliseconds
      })
    })

    // Remove position histories if no stream
    setPositionHistories((oldPositionHistories) => {
      const newPositionHistories = new Map()
      for (const { bearerId } of streams) {
        const subject = subjectFromBearerId(bearerId)
        newPositionHistories.set(subject, oldPositionHistories.get(subject))
      }
      return newPositionHistories
    })
  }, [streams])

  return { positions, positionHistories, messageHandler }
}

function discardOldPositionHistoryEntries(
  positionHistories: Map<string, PositionHistory>,
  positionHistoriesDuration: number = 0
) {
  const now = Temporal.Now.instant().epochMilliseconds
  const newPositionHistories = new Map(positionHistories)
  newPositionHistories.forEach((positionHistory, subject) => {
    if (positionHistory) {
      // Discard too old position history entries (by positionHistoriesDuration)
      const newPositionHistory = positionHistory.filter(
        ({ localTime }) => now - localTime <= positionHistoriesDuration
      )
      newPositionHistories.set(subject, newPositionHistory)
    }
  })
  return newPositionHistories
}

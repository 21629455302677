/** A CloudEvent. CloudEvents is a specification for describing event data in
 * common formats to provide interoperability across services, platforms and
 * systems (see https://github.com/cloudevents/spec).
 */
export interface CloudEvent<T> {
  id: string
  source: string
  type: `${CloudEventType}`
  datacontenttype: string
  dataschema: string
  subject: string
  time: string
  data: T
  arn: string
  eventcorrelationid: string
  specversion: string
  eventversion: string
}

export enum CloudEventType {
  STREAM_STARTED = 'com.axis.bodyworn.dash.stream.started',
  STREAM_ENDED = 'com.axis.bodyworn.dash.stream.ended',
  POSITION = 'com.axis.bodyworn.positioning.position',
}

/** Information about a positioning event. */
export interface PositionEventData {
  time: string
  eph: number
  lat: number
  lon: number
  heading?: number
  indoor?: {
    buildingId?: string
    buildingLabel?: string
    floorIndex?: number
    floorLabel?: string
    roomId?: string
    roomLabel?: string
  }
}

/** Information about a started stream. */
export interface StreamEventData {
  playlistId: string
  bearerId: string
  metadata: Record<string, string>
}

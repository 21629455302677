import { useTimeSource } from '@axteams-one/bws-cloud-time-source/react'
import {
  Caption1,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import { Temporal } from '@js-temporal/polyfill'
import { useEffect, useState } from 'react'

const useStyles = makeStyles({
  container: {
    marginInlineStart: tokens.spacingHorizontalXS,
    whiteSpace: 'nowrap',
  },
})

type TimeLabelProps = {
  timestamp?: Temporal.ZonedDateTime
}

/**
 * Returns caption of 'xx seconds ago' based on input timestamp if less than 60
 * seconds ago. Else, returns a caption of the input timestamp formatted as
 * 'hh:mm' in local time format.
 */
export default function TimeLabel({ timestamp }: TimeLabelProps) {
  const styles = useStyles()
  const { timeSource } = useTimeSource()
  const [timeLabel, setTimeLabel] = useState('')

  useEffect(() => {
    let timeout: NodeJS.Timeout
    function tickTime() {
      const now = timeSource
        ? Math.round(timeSource.getTime())
        : Temporal.Now.instant().epochMilliseconds

      const timeInSixty = (timestamp ? timestamp : Temporal.Now.instant()).add({
        milliseconds: 60 * 1000,
      }).epochMilliseconds
      setTimeLabel(getNewTimeLabel(now, timestamp, timeInSixty))

      // Don't keep ticking, when more than 60 seconds have passed
      if (now < timeInSixty) {
        // Time left until next second (syncs all labels)
        timeout = setTimeout(tickTime, 1000 - (now % 1000))
      }
    }
    tickTime()

    return () => clearInterval(timeout)
  }, [timeSource, timestamp])

  return (
    <Caption1 className={mergeClasses(styles.container, 'timeLabel')}>
      {timeLabel}
    </Caption1>
  )
}

function getNewTimeLabel(
  timeNow: number,
  timestamp: Temporal.ZonedDateTime | undefined,
  timeInSixty: number
): string {
  if (!timestamp) {
    return ''
  }

  if (timeNow > timeInSixty) {
    return timestamp
      .toPlainTime()
      .toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' })
  }

  const secondsAgo = 60 - Math.round((timeInSixty - timeNow) / 1000)
  return `${secondsAgo}s ago`
}

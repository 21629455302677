export enum Flags {
  INACTIVE_POSITION_THRESHOLD = 'ui_inactive_position_threshold',
  LANGUAGE = 'ui_enable_language',
  MAP_HEADING = 'ui_enable_map_heading',
  MAP_EPH_RADIUS = 'ui_enable_map_eph_radius',
  MAP_TAILS = 'ui_enable_map_tails',
  MAP_CLUSTERING = 'ui_enable_map_clustering',
  MAP_CLUSTER_THRESHOLD = 'ui_enable_map_cluster_threshold',
  MAP_CLUSTER_DELTA = 'ui_enable_map_cluster_delta',
  MAP_FRAME_ALL = 'ui_enable_map_frame_all',
  MAP_SIDEBAR_HOVER_BUTTON = 'ui_enable_map_sidebar_hover_button',
  PLAYBACK_RATE = 'ui_enable_playback_rate',
  SOC_SETTINGS = 'ui_enable_soc_settings',
  STREAM_INFO = 'ui_enable_stream_info',
  SCRUBBING = 'ui_enable_scrubbing',
  PIN_GROUPS = 'ui_enable_pin_groups',
  ENABLE_DATADOG_RUM = 'ui_enable_datadog_rum',
}

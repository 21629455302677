import { Position } from '@axteams-one/bws-cloud-maps/layers/tracking'
import { useEffect, useState } from 'react'

import { useCurrentOrganization } from '../providers/CurrentOrganizationProvider'
import { bearerIdFromSubject } from '../util/map'
import { Stream } from '../util/stream'
import { PositionFilter, useEphAverageFilter } from './positionFilters'
import { usePositioningWebSocket } from './usePositioningWebSocket'
import { usePositioningWebSocketMessageHandler } from './usePositioningWebSocketMessageHandler'

const POSITION_HISTORIES_DURATION = 30_000

export default function usePositions(streams: Stream[]) {
  const organizationId = useCurrentOrganization().id

  // Position filters
  const ephAverageFilter = useEphAverageFilter()

  // Order of filters is important because the filters are applied serially,
  // and we use the returned position from the filters.
  const filters: PositionFilter[] = [ephAverageFilter]

  const { positions, positionHistories, messageHandler } =
    usePositioningWebSocketMessageHandler(
      filters,
      POSITION_HISTORIES_DURATION,
      streams
    )
  const error = usePositioningWebSocket({
    organizationId,
    onMessage: messageHandler,
  })

  const [positionsWithStream, setPositionsWithStream] = useState<Position[]>([])
  useEffect(
    () =>
      setPositionsWithStream(filterPositionsWithStreams(positions, streams)),
    [positions, streams]
  )

  return {
    positions: positionsWithStream,
    positionHistories,
    error: error,
  }
}

/**
 * Filters out the positions that do not have a corresponding stream.
 */
function filterPositionsWithStreams(positions: Position[], streams: Stream[]) {
  return positions.filter((position: Position) => {
    const bearerId = bearerIdFromSubject(position.subject)
    return streams.some((stream) => stream.bearerId === bearerId)
  })
}

/**
 * Continuously calculate a moving average
 */
export class MovingAverage {
  public average: number = 0

  private values: number[]
  private count: number = 0
  private sum: number = 0

  /**
   * Constructs a moving average of specified size
   * @param {number} size Number of data points to use for moving average
   */
  constructor(size: number) {
    this.values = new Array<number>(size).fill(0)
  }

  /**
   * Adds a new data point to the moving average
   * @param {number} value new data point
   * @returns new moving average value
   */
  public update(value: number): number {
    const length = this.values.length
    const index = this.count % length
    const oldValue = this.values[index]

    this.sum += value - oldValue
    this.values[index] = value
    this.count++

    this.average = this.sum / Math.min(this.count, length)
    return this.average
  }
}

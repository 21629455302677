import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An Axis Resource Name */
  Arn: { input: any; output: any; }
  /** Extended ISO 8601 date and time string in the format YYYY-MM-DDThh:mm:ss.sssZ */
  DateTime: { input: any; output: any; }
  /** Represents an E-Mail address */
  Email: { input: any; output: any; }
  EntityID: { input: any; output: any; }
  /** Random 32 hexadecimal encoded bytes for validating subscription events */
  EventSigningKey: { input: any; output: any; }
  /**
   * A string based scalar type. It is used to denote registered
   * event types
   *
   * It is in reverse DNS notation and supports wildcards to some degree.
   * Valid values are in the form of "com.axis.<namespace>.<event|*>"
   *
   * This can be several levels deep with subnamespaces, but must end
   * with a specific event or "*".
   */
  EventType: { input: any; output: any; }
  /**
   * The current invitation state of the Principal.
   * Can be Verified or Pending.
   */
  InvitationStatus: { input: any; output: any; }
  Object: { input: any; output: any; }
  Product: { input: any; output: any; }
  /**
   * An expression that sets how updates are scheduled.
   *
   * The value must conform to one of the following formats:
   *     manual: Means no automatic update
   *     window([0-23]-[0-23]): Specifies a window as 24-hour clock when an update can be performed.
   *         For instance, window(23-5) says that an update may occur between
   *         23:00 on the evening and 05:00 on the morning.
   *         Optionally, weekdays can be specified as the second argument.
   *         This will further constrain the update window to only occur on the days specified.
   *         If left unspecified, the update window will apply to all days.
   *         There are 2 formats:
   *         * The first format is a literal format, it is specified as following:
   *             window(0-1, [MONDAY, TUESDAY, SUNDAY])
   *         * The second format is a range format, it is specified as following:
   *             window(0-1, [MONDAY-FRIDAY])
   *         The values for days are: MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY
   */
  ScheduleExpression: { input: any; output: any; }
  /** The schema version. A string with a version number following semantic versioning rules. */
  SchemaVersion: { input: any; output: any; }
  Thumbprint: { input: any; output: any; }
  /**
   * Time zone in the 'Area/Location' format as found in the IANA/tz database.
   * See https://en.wikipedia.org/wiki/Tz_database#Names_of_time_zones
   */
  TimeZone: { input: any; output: any; }
  Timestamp: { input: any; output: any; }
  UUID: { input: string; output: string; }
  /** Represents an URL */
  Url: { input: any; output: any; }
};

export type AdmxFirmwareCount = {
  __typename?: 'ADMXFirmwareCount';
  /** Site ID */
  siteId: Scalars['ID']['output'];
  /** Site name */
  siteName: Scalars['String']['output'];
  /** The number of devices with this firmware on the site */
  value: Scalars['Int']['output'];
};

export type AdmxFirmwareSummary = {
  __typename?: 'ADMXFirmwareSummary';
  /** The name of the device model, e.g. P3367 (if multiple, only one is returned) */
  deviceModelName: Scalars['String']['output'];
  /** Hardware ID (can be the same for multiple model names) */
  hardwareId: Scalars['String']['output'];
  /** Recommended firmware version */
  recommendedVersion?: Maybe<FirmwareVersion>;
  /** Number of devices with this firmware in the Organization */
  totalInOrganization: Scalars['Int']['output'];
  /** Current firmware version */
  version: FirmwareVersion;
};

export type AdmxFirmwareUpgradeInfoInput = {
  /** The ARN of the Organization */
  arn: Scalars['Arn']['input'];
  /** The firmware version */
  currentVersion: Scalars['String']['input'];
  /** The name of the device model, e.g. P3367 */
  deviceModelName: Scalars['String']['input'];
  /** Hardware ID (can be the same for multiple model names) */
  hardwareId: Scalars['String']['input'];
};

export type AdmxFirmwareUpgradeInfoResponse = {
  __typename?: 'ADMXFirmwareUpgradeInfoResponse';
  /** Site distribution of devices with this Firmware in the given organization */
  deviceCountPerSite: Array<AdmxFirmwareCount>;
  /** Recommended firmware version for the given Firmware */
  recommendedVersion?: Maybe<FirmwareVersion>;
  /** Additional supported firmware versions for the given Firmware */
  supportedVersions: Array<FirmwareVersion>;
};

/** AXIS Camera Application Platform */
export type Acap = {
  __typename?: 'Acap';
  /** The ID of the ACAP as found in the ACAP Portal */
  acapId: Scalars['Int']['output'];
  /** The name of the ACAP */
  name: Scalars['String']['output'];
  /** The current status of the ACAP */
  status: AcapStatus;
  /** Version of the ACAP */
  version: Scalars['String']['output'];
};

/** The different statuses of an ACAP. */
export enum AcapStatus {
  Running = 'RUNNING',
  Stopped = 'STOPPED'
}

export type AcceptInvitationInput = {
  /** ARN of the invitation to accept */
  invitationArn: Scalars['Arn']['input'];
};

export type AcceptInvitationResponse = {
  __typename?: 'AcceptInvitationResponse';
  /** ARN of the Organization the invitations was for */
  organizationArn: Scalars['Arn']['output'];
  /** The MyAxis Principal in the Organization, connected to the active MyAxis user */
  principalArn: Scalars['Arn']['output'];
};


export type AcceptInvitationResponseOrganizationArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};


export type AcceptInvitationResponsePrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represents an access to a resource */
export type Access = {
  __typename?: 'Access';
  /** The Arn of the Access */
  arn: Scalars['Arn']['output'];
  /** The Principals that have been assigned to this Access */
  assignedPrincipals: Array<PrincipalReference>;
  /** When the Access was created */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the Access */
  description?: Maybe<Scalars['String']['output']>;
  /** The name of the Access */
  name: Scalars['String']['output'];
  /** Role given to Principals with this Access */
  role: AccessRole;
  /** The target to which this Access applies */
  target: AccessTargetReference;
};


/** Represents an access to a resource */
export type AccessArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessFilterInput = {
  /** Only query for Accesses created under the provided Application IDs. */
  applications?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Ignore the current client context and return all Accesses. */
  ignoreClient?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccessResponse = {
  __typename?: 'AccessResponse';
  /** The returned Accesses */
  access: Array<Access>;
  /** Pagination info */
  page: PaginationResponse;
};

export type AccessRole = {
  __typename?: 'AccessRole';
  /** ID of Application */
  applicationId?: Maybe<Scalars['String']['output']>;
  /** Name of Application */
  applicationName?: Maybe<Scalars['String']['output']>;
  /** ID of the Role */
  roleId: Scalars['ID']['output'];
  /** Name of the Role */
  roleName: Scalars['String']['output'];
};

/** A type that can be set as a target in an Access. */
export type AccessTarget = {
  /** Lists the Access that reference this as a target */
  appliedAccesses?: Maybe<AccessResponse>;
  /** ARN of the target */
  arn: Scalars['Arn']['output'];
};


/** A type that can be set as a target in an Access. */
export type AccessTargetAppliedAccessesArgs = {
  filter?: InputMaybe<AccessFilterInput>;
  page?: InputMaybe<PaginationInput>;
};


/** A type that can be set as a target in an Access. */
export type AccessTargetArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A reference to an Access target. Can be either an Organization or Resource Group. */
export type AccessTargetReference = {
  __typename?: 'AccessTargetReference';
  /** ARN of the target */
  arn: Scalars['Arn']['output'];
  /** Name of the target */
  name: Scalars['String']['output'];
};


/** A reference to an Access target. Can be either an Organization or Resource Group. */
export type AccessTargetReferenceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivateAdmxTrialLicenseInput = {
  /** ARN of the Organization to activate ADMX trial license for */
  arn: Scalars['Arn']['input'];
};

export type ActivateAdmxTrialLicenseResponse = {
  __typename?: 'ActivateADMXTrialLicenseResponse';
  /** Reason the trial license was not activated */
  error?: Maybe<LicenseError>;
};

/** The Active Directory Group is a Principal entity providing access rights within the Organization. */
export type ActiveDirectoryGroup = Principal & {
  __typename?: 'ActiveDirectoryGroup';
  /** Accesses assigned to this Principal */
  accesses?: Maybe<Array<Access>>;
  arn: Scalars['Arn']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** Name of the AD Group in Axis IAM */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Name of the AD Group in the AD */
  name: Scalars['String']['output'];
  properties: PrincipalProperties;
  updatedAt: Scalars['DateTime']['output'];
};


/** The Active Directory Group is a Principal entity providing access rights within the Organization. */
export type ActiveDirectoryGroupArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * An Active Directory Integration representing the connection between
 * an AD account and AXIS IAM.
 */
export type ActiveDirectoryIntegration = {
  __typename?: 'ActiveDirectoryIntegration';
  arn: Scalars['Arn']['output'];
  /** Date when this Integration was created */
  createdAt: Scalars['DateTime']['output'];
  /** Optional description of the Integration */
  description?: Maybe<Scalars['String']['output']>;
  /** Active Directory Groups that belong to this Integration */
  groups?: Maybe<Array<ActiveDirectoryGroup>>;
  /** ID in the AD of the Integration */
  integrationId: Scalars['ID']['output'];
  /** The name of the Integration in AXIS IAM */
  name: Scalars['String']['output'];
  /** Date when this Integration was last updated */
  updatedAt: Scalars['DateTime']['output'];
};


/**
 * An Active Directory Integration representing the connection between
 * an AD account and AXIS IAM.
 */
export type ActiveDirectoryIntegrationArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AdIntegrationsResponse = {
  __typename?: 'AdIntegrationsResponse';
  adIntegrations: Array<ActiveDirectoryIntegration>;
  page: PaginationResponse;
};

export type AllChildrenInput = {
  /** The Organization or Resource Group where the query should start from. */
  arn: Scalars['Arn']['input'];
};

export type AllChildrenResponse = {
  __typename?: 'AllChildrenResponse';
  children: Array<ChildReference>;
  page: PaginationResponse;
};

/** Represents an Axis Application. */
export type Application = {
  __typename?: 'Application';
  /** ID of Application. */
  id: Scalars['ID']['output'];
  /** Name of Application. */
  name: Scalars['String']['output'];
  /** List of roles associated with this Application. */
  roles: Array<ApplicationRole>;
};

export type ApplicationInput = {
  /** Return the so called Use case Application instead of current Application. */
  usecase?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ApplicationRole = {
  __typename?: 'ApplicationRole';
  /** ID of the Role */
  id: Scalars['ID']['output'];
  /** Name of the Role */
  name: Scalars['String']['output'];
};

export type ArchiveOrganizationInput = {
  organizationArn: Scalars['Arn']['input'];
};

export type ArchiveOrganizationResponse = {
  __typename?: 'ArchiveOrganizationResponse';
  organizationArn: Scalars['Arn']['output'];
};


export type ArchiveOrganizationResponseOrganizationArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssignAccessInput = {
  /** Arn of the Access to assign the Principal */
  accessArn: Scalars['Arn']['input'];
  /** Arn of the Principal that is to be assigned to the Access */
  principalArn: Scalars['Arn']['input'];
};

export type AssignAccessResponse = {
  __typename?: 'AssignAccessResponse';
  /** Arn of the Access the Principal was assigned to */
  accessArn: Scalars['Arn']['output'];
  /** Arn of the Principal assigned to the Access */
  principalArn: Scalars['Arn']['output'];
};


export type AssignAccessResponseAccessArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};


export type AssignAccessResponsePrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssignAccessesFailureResponse = {
  __typename?: 'AssignAccessesFailureResponse';
  /** Access ARN where assignment failed. */
  arn: Scalars['Arn']['output'];
  /** Reason for failure if any. */
  reason?: Maybe<Scalars['String']['output']>;
};

export type AssignAccessesInput = {
  /**
   * List of Access ARNs to assign to the Principal.
   *
   * Maximum input of 20.
   */
  accessArns: Array<Scalars['Arn']['input']>;
  /** ARN of the Principal to assign Accesses for. */
  principalArn: Scalars['Arn']['input'];
};

export type AssignAccessesResponse = {
  __typename?: 'AssignAccessesResponse';
  /** ARN of Accesses where assignment was performed successfully. */
  accessArns?: Maybe<Array<Scalars['Arn']['output']>>;
  /** Accesses that failed to assign. */
  failed?: Maybe<Array<AssignAccessesFailureResponse>>;
  /** ARN of the Principal assigned to the Access */
  principalArn: Scalars['Arn']['output'];
};

export type AuditLogInput = {
  /** ARN of the entity to request audit log for, e.g Organization */
  arn: Scalars['Arn']['input'];
  /** End time, in unix timestamp, of the audit log */
  endTime?: InputMaybe<Scalars['Int']['input']>;
  /** Last key in audit log */
  lastKey?: InputMaybe<Scalars['Int']['input']>;
  /** Start time, in unix timestamp, of the audit log */
  startTime: Scalars['Int']['input'];
};

export type AuditLogResponse = {
  __typename?: 'AuditLogResponse';
  entriesCount: Scalars['Int']['output'];
  fileContent: Scalars['String']['output'];
  lastKey?: Maybe<Scalars['Int']['output']>;
};

/** Axis Camera device. */
export type AxisCamera = AxisDevice & {
  __typename?: 'AxisCamera';
  /** ACAPs installed on the AXIS Camera */
  acaps: Array<Acap>;
  /** Arn of the camera */
  arn: Scalars['Arn']['output'];
  /** Custom data */
  attributes?: Maybe<AxisDeviceAttributes>;
  /** A timestamp that represents when the camera was registered in a resource group */
  createdAt: Scalars['DateTime']['output'];
  firmware?: Maybe<Firmware>;
  model?: Maybe<AxisDeviceModel>;
  /** The onboarding state of the Axis camera */
  onboarding: AxisDeviceOnboarding;
  /** The serial number of the Axis camera */
  serial: Scalars['String']['output'];
  /** The timezone configured for the Axis camera */
  timeZone?: Maybe<Scalars['TimeZone']['output']>;
  updateConfiguration?: Maybe<UpdateConfiguration>;
};


/** Axis Camera device. */
export type AxisCameraArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Generic Axis device */
export type AxisDevice = {
  /** ACAPs installed on the AXIS Device */
  acaps: Array<Acap>;
  /** Arn of the device */
  arn: Scalars['Arn']['output'];
  /** Custom data */
  attributes?: Maybe<AxisDeviceAttributes>;
  /** A timestamp that represents when the device was registered in a resource group */
  createdAt: Scalars['DateTime']['output'];
  firmware?: Maybe<Firmware>;
  model?: Maybe<AxisDeviceModel>;
  /** The onboarding state of the Axis device */
  onboarding: AxisDeviceOnboarding;
  /** The serial number of the Axis device */
  serial: Scalars['String']['output'];
  /** The timezone configured for the Axis device */
  timeZone?: Maybe<Scalars['TimeZone']['output']>;
  updateConfiguration?: Maybe<UpdateConfiguration>;
};


/** Generic Axis device */
export type AxisDeviceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AxisDeviceAttributes = {
  __typename?: 'AxisDeviceAttributes';
  /** Custom description of the device */
  description?: Maybe<Scalars['String']['output']>;
  /** Custom name of the device */
  name?: Maybe<Scalars['String']['output']>;
};

/** Information about the Axis Device model or product. */
export type AxisDeviceModel = {
  __typename?: 'AxisDeviceModel';
  /** PIA Product name of the device model. */
  name?: Maybe<Scalars['String']['output']>;
  /**
   * PIA Product Variant ID of the device model.
   * This can be used to see an image for this device: https://www.axis.com/images/products/item-id.png.
   */
  piaItemId?: Maybe<Scalars['String']['output']>;
};

export type AxisDeviceOnboarding = {
  __typename?: 'AxisDeviceOnboarding';
  /** Current state of the onboarding. */
  currentState: DeviceOnboardingState;
  /**
   * An error message, if available, if the state is in any kind of error
   * @deprecated Use errorDescription - will be removed after 2024-03-31
   */
  error?: Maybe<Scalars['String']['output']>;
  /** A code corresponding to the type of error. */
  errorCode?: Maybe<AxisDeviceOnboardingErrorCode>;
  /** An error description, if available, if the state is in any kind of error */
  errorDescription?: Maybe<Scalars['String']['output']>;
  /**
   * State of the device onboarding.
   * @deprecated Use currentState
   */
  state: AxisDeviceOnboardingState;
};

export enum AxisDeviceOnboardingErrorCode {
  AcapError = 'ACAP_ERROR',
  CurrentFirmwareTooOld = 'CURRENT_FIRMWARE_TOO_OLD',
  EnsureDefaultedDeviceError = 'ENSURE_DEFAULTED_DEVICE_ERROR',
  FirmwareTooOld = 'FIRMWARE_TOO_OLD',
  FirmwareUpgradeError = 'FIRMWARE_UPGRADE_ERROR',
  LatestFirmwareTooOld = 'LATEST_FIRMWARE_TOO_OLD',
  TimeOut = 'TIME_OUT',
  UnknownError = 'UNKNOWN_ERROR'
}

/** Represents the state of an Axis Device onboarding */
export enum AxisDeviceOnboardingState {
  /** The onboarding failed and is in an error state */
  Error = 'ERROR',
  /** The onboarding of the Axis device is finished */
  Finished = 'FINISHED',
  /** The Axis device is not registered with onboarding */
  NotRegistered = 'NOT_REGISTERED',
  /**
   * The Axis device has been registered but the onboarding
   * of the Axis device has not started yet
   */
  NotStarted = 'NOT_STARTED',
  /**
   * The onboarding of the Axis device has started
   * The button has been pressed
   */
  Started = 'STARTED'
}

export type BatchDeleteAccessInput = {
  /** The Arns of the Accesses to delete. */
  arns: Array<Scalars['Arn']['input']>;
};

export type BatchDeleteAccessResponse = {
  __typename?: 'BatchDeleteAccessResponse';
  /** The Arns of the Accesses that was successfully deleted. */
  arns: Array<Scalars['Arn']['output']>;
};


export type BatchDeleteAccessResponseArnsArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CancelInvitationInput = {
  /** ARN of the invitation to cancel */
  invitationArn: Scalars['Arn']['input'];
};

export type CancelInvitationResponse = {
  __typename?: 'CancelInvitationResponse';
  /** ARN of the canceled invitation */
  invitationArn: Scalars['Arn']['output'];
};


export type CancelInvitationResponseInvitationArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum CancelTaskGroupError {
  /** Generic error */
  GenericError = 'GENERIC_ERROR'
}

export type CancelTaskGroupInput = {
  /** The ARN of the Organization */
  arn: Scalars['Arn']['input'];
  /** ID of task group to cancel */
  id: Scalars['ID']['input'];
};

export type CancelTaskGroupResponse = {
  __typename?: 'CancelTaskGroupResponse';
  /** Reason the cancel failed */
  error?: Maybe<CancelTaskGroupError>;
};

export type ChildReference = {
  __typename?: 'ChildReference';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent: Scalars['ID']['output'];
};

export type ChildrenResponse = {
  __typename?: 'ChildrenResponse';
  /** The Resource Groups below this Resource Group/Organization */
  children: Array<ResourceGroupReference>;
  /** Pagination Info */
  page: PaginationResponse;
};

/** Options to the claimSystem mutation. */
export type ClaimSystemInput = {
  /** The authorization code of the system to claim. */
  authorizationCode: Scalars['String']['input'];
  /** The IAM resource group in which the system's resource group will be created. */
  resourceParent: Scalars['EntityID']['input'];
  /** ID of the system to claim. */
  systemID: Scalars['UUID']['input'];
};

export enum ConfigureDeviceError {
  /** Generic error */
  GenericError = 'GENERIC_ERROR'
}

export type ConfigureDeviceInput = {
  /**
   * The ARNs to which the configuration applies to.
   * Array of devices to be configured based on their serialnumber. Must belong to the same organization
   */
  arn: Array<Scalars['Arn']['input']>;
  /** Configure settings on the device. */
  configuration: DeviceConfiguration;
};

export type ConfigureDeviceResponse = {
  __typename?: 'ConfigureDeviceResponse';
  /** Reason the configurations failed */
  error?: Maybe<ConfigureDeviceError>;
  /** All configurations gets assigned the same groupId. If there are no successful configurations, the groupId will be an empty string. */
  groupId?: Maybe<Scalars['String']['output']>;
};

export type ContactInfo = {
  __typename?: 'ContactInfo';
  /** The e-mail of the MyAxis principal */
  email?: Maybe<Scalars['Email']['output']>;
  /** A combination of first name and family name */
  name?: Maybe<Scalars['String']['output']>;
};

export type CreateAccessInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  principalArns?: InputMaybe<Array<Scalars['Arn']['input']>>;
  roleId: Scalars['ID']['input'];
  targetArn: Scalars['Arn']['input'];
};

export type CreateAccessResponse = {
  __typename?: 'CreateAccessResponse';
  accessArn: Scalars['Arn']['output'];
};


export type CreateAccessResponseAccessArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateActiveDirectoryGroupInput = {
  /** ARN of the AD Integration this Group belongs to. */
  activeDirectoryIntegrationArn: Scalars['Arn']['input'];
  /** Display name of the AD Group. Has no bearing on the AD connection. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /**
   * Name of the AD Group. This must be the same name of the Group
   * as it is in the actual AD.
   */
  name: Scalars['String']['input'];
};

export type CreateActiveDirectoryGroupResponse = {
  __typename?: 'CreateActiveDirectoryGroupResponse';
  /** ARN of the created AD Group */
  activeDirectoryGroupArn: Scalars['Arn']['output'];
};

export type CreateActiveDirectoryIntegrationInput = {
  /** The description the AD Integration will have. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ID of the AD Integration. */
  integrationId: Scalars['ID']['input'];
  /** The name the AD Integration will have. */
  name: Scalars['String']['input'];
  /** ARN of the Organization this integration should be created in. */
  organizationArn: Scalars['Arn']['input'];
};

export type CreateActiveDirectoryIntegrationResponse = {
  __typename?: 'CreateActiveDirectoryIntegrationResponse';
  /** ARN of the created AD Integration. */
  activeDirectoryIntegrationArn: Scalars['Arn']['output'];
};

export type CreateDeviceIdentityInput = {
  /** Description of the device. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of the device. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The Resource Group where the device will be located. */
  resourceGroupArn: Scalars['Arn']['input'];
  /**
   * Serial of a physical device to register with this identity.
   *
   * This does not mean that it is registered for any type of onboarding.
   */
  serial?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDeviceIdentityResponse = {
  __typename?: 'CreateDeviceIdentityResponse';
  deviceArn: Scalars['Arn']['output'];
};


export type CreateDeviceIdentityResponseDeviceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateEmailEventSubscriptionInput = {
  /**
   * The events that will trigger an email notification.
   * All event types will not trigger email notifiations.
   */
  eventTypes: Array<Scalars['EventType']['input']>;
  /** The email address of the recipient that should receive emails notifications. */
  recipient: Scalars['Email']['input'];
  /**
   * The resource that will be monitored for event occurrences.
   * Valid ARN types are Organization, Resource Group and Device.
   */
  resourceArn: Scalars['Arn']['input'];
};

export type CreateEmailEventSubscriptionResponse = {
  __typename?: 'CreateEmailEventSubscriptionResponse';
  /** The created subscription. */
  subscription: EmailEventSubscription;
};

export type CreateOrganizationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateOrganizationResponse = {
  __typename?: 'CreateOrganizationResponse';
  organizationArn: Scalars['Arn']['output'];
  /**
   * ARN of the principal who created the organization
   * and has owner access to it.
   */
  ownerArn: Scalars['Arn']['output'];
};


export type CreateOrganizationResponseOrganizationArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CreateOrganizationResponseOwnerArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreatePartnerOrganizationInput = {
  /** The CSR, encoded in Base64 format, for the Service Principal that will be created. */
  csr: Scalars['String']['input'];
  /** Input for the organization. */
  organization: CreateOrganizationInput;
};

export type CreatePartnerOrganizationResponse = {
  __typename?: 'CreatePartnerOrganizationResponse';
  /** ARN of the new Organization. */
  organizationArn: Scalars['Arn']['output'];
  /** Arn of the Partner Principal */
  partnerPrincipalArn: Scalars['Arn']['output'];
  /** Arn of the new Service Principal. */
  servicePrincipalArn: Scalars['Arn']['output'];
  /** The Service Principal certificate encoded in Base64 format. */
  servicePrincipalCert: Scalars['String']['output'];
};


export type CreatePartnerOrganizationResponseOrganizationArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CreatePartnerOrganizationResponsePartnerPrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CreatePartnerOrganizationResponseServicePrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateResourceGroupInput = {
  /** A description of the new Resource Group */
  description: Scalars['String']['input'];
  /** The name of the new Resource Group */
  name: Scalars['String']['input'];
  /**
   * The ARN of the parent to the new Resource Group. This can be either
   * an Organization ARN or a Resource Group ARN
   */
  parentArn: Scalars['Arn']['input'];
};

export type CreateResourceGroupResponse = {
  __typename?: 'CreateResourceGroupResponse';
  /** The ARN of the created Resource Group */
  resourceGroupArn: Scalars['Arn']['output'];
};


export type CreateResourceGroupResponseResourceGroupArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateServicePrincipalInput = {
  /** The CSR encoded in Base64 format. */
  csr: Scalars['String']['input'];
  /** Indicates if the created Service Principal will be an owner of the Organization it belongs to. */
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  /** ARN of the organization to create the Service Principal in. */
  organizationArn: Scalars['Arn']['input'];
};

export type CreateServicePrincipalResponse = {
  __typename?: 'CreateServicePrincipalResponse';
  servicePrincipalArn: Scalars['Arn']['output'];
  /** The certificate encoded in Base64 format. */
  servicePrincipalCert: Scalars['String']['output'];
};


export type CreateServicePrincipalResponseServicePrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateWebhookEventSubscriptionInput = {
  /** The events that will trigger the call to the webhook */
  eventTypes: Array<Scalars['EventType']['input']>;
  /** The resource that will be monitored for event occurrences */
  resourceArn: Scalars['Arn']['input'];
  /** Key used to sign events, used by the recipient to validate authenticity */
  signingKey?: InputMaybe<Scalars['EventSigningKey']['input']>;
  /**
   * The URL that will be called when the given event occurs
   * on the given resource
   */
  url: Scalars['Url']['input'];
};

export type CreateWebhookEventSubscriptionResponse = {
  __typename?: 'CreateWebhookEventSubscriptionResponse';
  /** The created subscription */
  subscription: WebhookEventSubscription;
};

export type DashCryptoContext = {
  version: Scalars['String']['output'];
};

export type DashCryptoContextV1 = DashCryptoContext & {
  __typename?: 'DashCryptoContextV1';
  keys?: Maybe<Scalars['Object']['output']>;
  version: Scalars['String']['output'];
};

/** A dash playlist. */
export type DashPlaylist = {
  __typename?: 'DashPlaylist';
  /** Crypto context. Only available if the contents are encrypted. */
  crypto?: Maybe<DashCryptoContext>;
  /** The MPD file's contents. */
  mpd: Scalars['String']['output'];
  /** The base path at which segments are stored. */
  segmentBasePath: Scalars['String']['output'];
  /** The storage location of the system. */
  storageLocationId: Scalars['String']['output'];
  /** SAS token to access the content referred to by the MPDs. */
  token: Scalars['String']['output'];
};

export type DeleteAccessInput = {
  /** The Arn of the Access to delete */
  accessArn: Scalars['Arn']['input'];
};

export type DeleteAccessResponse = {
  __typename?: 'DeleteAccessResponse';
  /** The Arn of the deleted Access */
  accessArn: Scalars['Arn']['output'];
};


export type DeleteAccessResponseAccessArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteActiveDirectoryIntegrationInput = {
  /** ARN of the AD Integration to delete. */
  arn: Scalars['Arn']['input'];
};

export type DeleteActiveDirectoryIntegrationResponse = {
  __typename?: 'DeleteActiveDirectoryIntegrationResponse';
  /** ARN of the deleted AD Integration. */
  arn: Scalars['Arn']['output'];
};

export type DeleteEventSubscriptionInput = {
  /** The event subscription that will be deleted */
  arn: Scalars['Arn']['input'];
};

export type DeleteEventSubscriptionResponse = {
  __typename?: 'DeleteEventSubscriptionResponse';
  /** The event subscription that was deleted */
  arn: Scalars['Arn']['output'];
};


export type DeleteEventSubscriptionResponseArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeletePrincipalInput = {
  /** The ARN of the principal to remove from the organization */
  principalArn: Scalars['Arn']['input'];
};

export type DeletePrincipalResponse = {
  __typename?: 'DeletePrincipalResponse';
  principalArn: Scalars['Arn']['output'];
};


export type DeletePrincipalResponsePrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteResourceGroupInput = {
  /** The ARN of the ResourceGroup to delete */
  resourceGroupArn: Scalars['Arn']['input'];
};

export type DeleteResourceGroupResponse = {
  __typename?: 'DeleteResourceGroupResponse';
  /** The ARN of the deleted ResourceGroup */
  resourceGroupArn: Scalars['Arn']['output'];
};


export type DeleteResourceGroupResponseResourceGroupArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeviceConfiguration = {
  /** Configure text overlay on the device(s) */
  textOverlay?: InputMaybe<TextOverlayConfiguration>;
  /** Configure time related settings on the device(s) */
  time?: InputMaybe<TimeConfiguration>;
};

export type DeviceFilterInput = {
  /** Include devices not registered for OneClick Onboarding. */
  includeNotRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only return devices matching the serial. */
  serial?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the state of an Axis Device onboarding */
export enum DeviceOnboardingState {
  /** The device is connected to the Onboarding server and placed in an onboarding queue. */
  ClientConnected = 'CLIENT_CONNECTED',
  /** Various configurations are applied. For example enabling WebRTC or EdgeLink. */
  Configuring = 'CONFIGURING',
  /** The device is being factory defaulted. */
  Defaulting = 'DEFAULTING',
  /** The onboarding failed and is in an error state." */
  Error = 'ERROR',
  /** The onboarding of the Axis device is finished, successfully or with an error. */
  Finished = 'FINISHED',
  /** The core system composition of the device is being installed. */
  Installing = 'INSTALLING',
  /** The Axis device is not registered for onboarding. */
  NotRegistered = 'NOT_REGISTERED',
  /** The device is preparing for a firmware upgrade and/or factory default. */
  Preparing = 'PREPARING',
  /** The Axis device has been registered with the Onboarding server. */
  Registered = 'REGISTERED',
  /** Firmware upgrade is in progress. */
  UpgradingFirmware = 'UPGRADING_FIRMWARE'
}

/**
 * List of IANA time zones representing regions with common UTC offsets.
 * In this context, "stable" means that the timezone has been around long
 * enough so that it will be accepted by older camera models, and represents
 * a major timezone, so it can be used by other zones and is unlikely to disappear.
 * https://en.wikipedia.org/wiki/Time_zone
 * https://en.wikipedia.org/wiki/List_of_UTC_offsets
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 * https://www.iana.org/time-zones
 */
export enum DeviceTimeZone {
  /** UTC+00:00 (GMT) */
  AfricaAbidjan = 'Africa__Abidjan',
  /** UTC+02:00/UTC+03:00 (EET/EEST) */
  AfricaCairo = 'Africa__Cairo',
  /** UTC+00:00/UTC+01:00 (WET/WEST) */
  AfricaCasablanca = 'Africa__Casablanca',
  /** UTC+01:00 (WAT) */
  AfricaLagos = 'Africa__Lagos',
  /** UTC+02:00 (CAT) */
  AfricaMaputo = 'Africa__Maputo',
  /** UTC+03:00 (EAT) */
  AfricaNairobi = 'Africa__Nairobi',
  /** UTC+02:00 (EET) */
  AfricaTripoli = 'Africa__Tripoli',
  /** UTC+01:00 (CET) */
  AfricaTunis = 'Africa__Tunis',
  /** UTC-10:00/UTC-09:00 (HST/HDT) */
  AmericaAdak = 'America__Adak',
  /** UTC-09:00/UTC-08:00 (AKST/AKDT) */
  AmericaAnchorage = 'America__Anchorage',
  /** UTC-03:00 (ART) */
  AmericaArgentinaBuenosAires = 'America__Argentina__Buenos_Aires',
  /** UTC-03:00 (ART) */
  AmericaArgentinaCordoba = 'America__Argentina__Cordoba',
  /** UTC-04:00/UTC-03:00 (PYT/PYST) */
  AmericaAsuncion = 'America__Asuncion',
  /** UTC-05:00 (COT) */
  AmericaBogota = 'America__Bogota',
  /** UTC-06:00/UTC-05:00 (CST/CDT) */
  AmericaChicago = 'America__Chicago',
  /** UTC-07:00/UTC-06:00 (MST/MDT) */
  AmericaDenver = 'America__Denver',
  /** UTC-07:00/UTC-06:00 (MST/MDT) */
  AmericaEdmonton = 'America__Edmonton',
  /** UTC-04:00/UTC-03:00 (AST/ADT) */
  AmericaHalifax = 'America__Halifax',
  /** UTC-05:00/UTC-04:00 (CST/CDT) */
  AmericaHavana = 'America__Havana',
  /** UTC-04:00 (BOT) */
  AmericaLaPaz = 'America__La_Paz',
  /** UTC-05:00 (PET) */
  AmericaLima = 'America__Lima',
  /** UTC-08:00/UTC-07:00 (PST/PDT) */
  AmericaLosAngeles = 'America__Los_Angeles',
  /** UTC-04:00 (AMT) */
  AmericaManaus = 'America__Manaus',
  /** UTC-06:00/UTC-05:00 (CST/CDT) */
  AmericaMatamoros = 'America__Matamoros',
  /** UTC-07:00 (MST) */
  AmericaMazatlan = 'America__Mazatlan',
  /** UTC-06:00 (CST) */
  AmericaMexicoCity = 'America__Mexico_City',
  /** UTC-05:00/UTC-04:00 (EST/EDT) */
  AmericaNewYork = 'America__New_York',
  /** UTC-02:00 (FNT) */
  AmericaNoronha = 'America__Noronha',
  /** UTC-03:00/UTC-02:00 (WGT/WGST) */
  AmericaNuuk = 'America__Nuuk',
  /** UTC-05:00 (EST) */
  AmericaPanama = 'America__Panama',
  /** UTC-07:00 (MST) */
  AmericaPhoenix = 'America__Phoenix',
  /** UTC-04:00 (AST) */
  AmericaPuertoRico = 'America__Puerto_Rico',
  /** UTC-06:00 (CST) */
  AmericaRegina = 'America__Regina',
  /** UTC-04:00/UTC-03:00 (CLT/CLST) */
  AmericaSantiago = 'America__Santiago',
  /** UTC-03:00 (BRT) */
  AmericaSaoPaulo = 'America__Sao_Paulo',
  /** UTC-01:00/UTC+00:00 (EGT/EGST) */
  AmericaScoresbysund = 'America__Scoresbysund',
  /** UTC-03:30/UTC-02:30 (NST/NDT) */
  AmericaStJohns = 'America__St_Johns',
  /** UTC-08:00/UTC-07:00 (PST/PDT) */
  AmericaTijuana = 'America__Tijuana',
  /** UTC-05:00/UTC-04:00 (EST/EDT) */
  AmericaToronto = 'America__Toronto',
  /** UTC-08:00/UTC-07:00 (PST/PDT) */
  AmericaVancouver = 'America__Vancouver',
  /** UTC-07:00 (MST) */
  AmericaWhitehorse = 'America__Whitehorse',
  /** UTC-06:00/UTC-05:00 (CST/CDT) */
  AmericaWinnipeg = 'America__Winnipeg',
  /** UTC+06:00 (ALMT) */
  AsiaAlmaty = 'Asia__Almaty',
  /** UTC+07:00 (ICT) */
  AsiaBangkok = 'Asia__Bangkok',
  /** UTC+04:00 (UAE) */
  AsiaDubai = 'Asia__Dubai',
  /** UTC+08:00 (HKT) */
  AsiaHongKong = 'Asia__Hong_Kong',
  /** UTC+09:00 (WIT) */
  AsiaJayapura = 'Asia__Jayapura',
  /** UTC+02:00/UTC+03:00 (IST/IDT) */
  AsiaJerusalem = 'Asia__Jerusalem',
  /** UTC+04:30 (AFT) */
  AsiaKabul = 'Asia__Kabul',
  /** UTC+12:00 (PETT) */
  AsiaKamchatka = 'Asia__Kamchatka',
  /** UTC+05:00 (PKT) */
  AsiaKarachi = 'Asia__Karachi',
  /** UTC+05:45 (NPT) */
  AsiaKathmandu = 'Asia__Kathmandu',
  /** UTC+05:30 (IST) */
  AsiaKolkata = 'Asia__Kolkata',
  /** UTC+08:00 (PHST) */
  AsiaManila = 'Asia__Manila',
  /** UTC+07:00 (NOVT) */
  AsiaNovosibirsk = 'Asia__Novosibirsk',
  /** UTC+09:00 (KST) */
  AsiaSeoul = 'Asia__Seoul',
  /** UTC+08:00 (CST) */
  AsiaShanghai = 'Asia__Shanghai',
  /** UTC+08:00 (SGT) */
  AsiaSingapore = 'Asia__Singapore',
  /** UTC+11:00 (SRET) */
  AsiaSrednekolymsk = 'Asia__Srednekolymsk',
  /** UTC+03:30 (IRST) */
  AsiaTehran = 'Asia__Tehran',
  /** UTC+09:00 (JST) */
  AsiaTokyo = 'Asia__Tokyo',
  /** UTC+10:00 (VLAT) */
  AsiaVladivostok = 'Asia__Vladivostok',
  /** UTC+06:30 (MMT) */
  AsiaYangon = 'Asia__Yangon',
  /** UTC-01:00/UTC+00:00 (AZOT/AZOST) */
  AtlanticAzores = 'Atlantic__Azores',
  /** UTC-01:00 (CVT) */
  AtlanticCapeVerde = 'Atlantic__Cape_Verde',
  /** UTC+09:30/UTC+10:30 (ACST/ACDT) */
  AustraliaAdelaide = 'Australia__Adelaide',
  /** UTC+09:30 (ACST) */
  AustraliaDarwin = 'Australia__Darwin',
  /** UTC+08:45 (ACWST) */
  AustraliaEucla = 'Australia__Eucla',
  /** UTC+10:30/UTC+11:00 (LHST/LHDT) */
  AustraliaLordHowe = 'Australia__Lord_Howe',
  /** UTC+08:00 (AWST) */
  AustraliaPerth = 'Australia__Perth',
  /** UTC+10:00/UTC+11:00 (AEST/AEDT) */
  AustraliaSydney = 'Australia__Sydney',
  /** UTC */
  EtcUtc = 'Etc__Utc',
  /** UTC+02:00/UTC+03:00 (EET/EEST) */
  EuropeAthens = 'Europe__Athens',
  /** UTC+01:00/UTC+02:00 (CET/CEST) */
  EuropeBerlin = 'Europe__Berlin',
  /** UTC+03:00 (TRT) */
  EuropeIstanbul = 'Europe__Istanbul',
  /** UTC+00:00/UTC+01:00 (WET/WEST) */
  EuropeLisbon = 'Europe__Lisbon',
  /** UTC+00:00/UTC+01:00 (GMT/BST) */
  EuropeLondon = 'Europe__London',
  /** UTC+03:00 (MSK) */
  EuropeMoscow = 'Europe__Moscow',
  /** UTC+01:00/UTC+02:00 (CET/CEST) */
  EuropeParis = 'Europe__Paris',
  /** UTC+12:00/UTC+13:00 (NZST/NZDT) */
  PacificAuckland = 'Pacific__Auckland',
  /** UTC+11:00 (BST) */
  PacificBougainville = 'Pacific__Bougainville',
  /** UTC+12:45/UTC+13:45 (CHAST/CHADT) */
  PacificChatham = 'Pacific__Chatham',
  /** UTC-06:00/UTC-05:00 */
  PacificEaster = 'Pacific__Easter',
  /** UTC+12:00 (FJT) */
  PacificFiji = 'Pacific__Fiji',
  /** UTC-09:00 */
  PacificGambier = 'Pacific__Gambier',
  /** UTC+11:00 (SBT) */
  PacificGuadalcanal = 'Pacific__Guadalcanal',
  /** UTC-10:00 (HST) */
  PacificHonolulu = 'Pacific__Honolulu',
  /** UTC+13:00 (PHOT) */
  PacificKanton = 'Pacific__Kanton',
  /** UTC+14:00 (LINT) */
  PacificKiritimati = 'Pacific__Kiritimati',
  /** UTC-09:30 (MART) */
  PacificMarquesas = 'Pacific__Marquesas',
  /** UTC-11:00 */
  PacificNiue = 'Pacific__Niue',
  /** UTC+11:00/UTC+12:00 (NFT/NFDT) */
  PacificNorfolk = 'Pacific__Norfolk',
  /** UTC-11:00 (SST) */
  PacificPagoPago = 'Pacific__Pago_Pago',
  /** UTC-08:00 (PST) */
  PacificPitcairn = 'Pacific__Pitcairn',
  /** UTC+10:00 (PGT) */
  PacificPortMoresby = 'Pacific__Port_Moresby',
  /** UTC+12:00 (GILT) */
  PacificTarawa = 'Pacific__Tarawa'
}

export type DevicesResponse = {
  __typename?: 'DevicesResponse';
  /** The returned devices */
  devices?: Maybe<Array<AxisDevice>>;
  /** Pagination Info */
  page: PaginationResponse;
};

/** Options to the disclaimSystem mutation. */
export type DisclaimSystemInput = {
  /** ID of the system to disclaim. */
  systemID: Scalars['UUID']['input'];
};

/** A Email event subscription */
export type EmailEventSubscription = EventSubscription & {
  __typename?: 'EmailEventSubscription';
  /** The events that will trigger the call to the registered recipient */
  eventTypes: Array<Scalars['EventType']['output']>;
  /**
   * True if the email address has not yet been confirmed after creating
   * the subscription. The recipient must confirm the subscription
   * by visiting the link sent via email.
   */
  pending: Scalars['Boolean']['output'];
  /**
   * The registered email that will receive events that happens
   * on the resource
   */
  recipient: Scalars['Email']['output'];
  /** An ARN that represents the monitored resource */
  resourceArn: Scalars['Arn']['output'];
  /** An ARN that represents the registered event subscription */
  subscriptionArn: Scalars['Arn']['output'];
};

/** ErrorCode describes all the available error codes. */
export enum ErrorCode {
  /** The request is badly formed or otherwise incorrect. */
  BwoBadRequest = 'BWO_BAD_REQUEST',
  /**
   * The requesting party is unauthorized to claim the specified system due to a
   * bad authorization code being provided.
   */
  BwoClaimUnauthorized = 'BWO_CLAIM_UNAUTHORIZED',
  /** The Trial length is either negative or too long. */
  BwoInvalidTrialLength = 'BWO_INVALID_TRIAL_LENGTH',
  /** The query or mutation is not implemented. */
  BwoNotImplemented = 'BWO_NOT_IMPLEMENTED',
  /** The organization does not exist. */
  BwoOrganizationNotFound = 'BWO_ORGANIZATION_NOT_FOUND',
  /**
   * The request did not meet its preconditions. This can occur if changes were
   * made to a resource in parallel to the request, causing a collision.
   */
  BwoPreconditionFailed = 'BWO_PRECONDITION_FAILED',
  /** The requested stream does not exist. */
  BwoStreamNotFound = 'BWO_STREAM_NOT_FOUND',
  /** The system is already claimed. */
  BwoSystemAlreadyClaimed = 'BWO_SYSTEM_ALREADY_CLAIMED',
  /** A BWS system used in a query or mutation was not found. */
  BwoSystemNotFound = 'BWO_SYSTEM_NOT_FOUND',
  /** The system's state is not in sync with the cloud. */
  BwoSystemStateOutOfSync = 'BWO_SYSTEM_STATE_OUT_OF_SYNC',
  /** The requesting party has issued too many requests. */
  BwoTooManyRequests = 'BWO_TOO_MANY_REQUESTS',
  /** The organization has already started a trial at some point. */
  BwoTrialAlreadyStarted = 'BWO_TRIAL_ALREADY_STARTED',
  /** The requesting party is unauthorized to fulfill the request. */
  BwoUnauthorized = 'BWO_UNAUTHORIZED',
  /** An internal server error occurred. */
  BwoUnknownError = 'BWO_UNKNOWN_ERROR'
}

/** An event subscription is  */
export type EventSubscription = {
  /** The events that will trigger the call to the registered URL */
  eventTypes: Array<Scalars['EventType']['output']>;
  /** An ARN that represents the monitored resource */
  resourceArn: Scalars['Arn']['output'];
  /** An ARN that represents the registered event subscription */
  subscriptionArn: Scalars['Arn']['output'];
};

export type EventSubscriptions = {
  __typename?: 'EventSubscriptions';
  /** All registered subscriptions */
  subscriptions?: Maybe<Array<EventSubscription>>;
  /**
   * All registered webhook subscriptions
   * @deprecated Use subscriptions - will be removed after 2024-01-31
   */
  webhooks?: Maybe<Array<WebhookEventSubscription>>;
};

export type EventTypePatch = {
  eventType: Scalars['EventType']['input'];
  operation: EventTypePatchOperation;
};

export enum EventTypePatchOperation {
  /** Sets the target event type as inactive. */
  Disable = 'DISABLE',
  /**
   * Sets the target event type as active.
   * This operation will add the event type to the list
   * if it doesn't already exists.
   */
  Enable = 'ENABLE'
}

export type FailedInviteMyAxisPrincipals = {
  __typename?: 'FailedInviteMyAxisPrincipals';
  /** Email address that failed. */
  email: Scalars['Email']['output'];
  /** Message describing reason for failure if any. */
  reason?: Maybe<Scalars['String']['output']>;
};

export type FailedSyncPushSubscriptionsOrganization = {
  __typename?: 'FailedSyncPushSubscriptionsOrganization';
  /** ARN of the Organization that failed to sync. */
  organizationArn: Scalars['Arn']['output'];
  /** Message describing reason for failure if any. */
  reason?: Maybe<Scalars['String']['output']>;
};

export type Firmware = {
  __typename?: 'Firmware';
  /**
   * Firmware information for an Axis device.
   * Available after onboarding is finished.
   *
   * Limitation: If the device has been onboarded in the same Organization more than once, firmware information might be empty.
   */
  information: FirmwareInformation;
};

/** An AXIS OS firmware */
export type FirmwareInformation = {
  __typename?: 'FirmwareInformation';
  /** The pubAPI ID of the firmware version */
  id?: Maybe<Scalars['ID']['output']>;
  /** The firmware track (LTS2022, 8.50, 9.50, ...) */
  track?: Maybe<Scalars['String']['output']>;
  /** The firmware version string, typically like '1.2.34' */
  version: Scalars['String']['output'];
};

export type FirmwareVersion = {
  __typename?: 'FirmwareVersion';
  /** Link to release notes */
  releaseNotesUrl?: Maybe<Scalars['String']['output']>;
  /** The track, e.g. 'active', 'LTS-2020' */
  track: Scalars['String']['output'];
  /** The firmware version */
  value: Scalars['String']['output'];
};

/** Options to the GetNotificationsWebSocketUrl query. */
export type GetNotificationsWebSocketUrlOptions = {
  /** The id of the organization. */
  organizationId: Scalars['UUID']['input'];
};

/** Options to the GetPositioningWebSocketUrl query. */
export type GetPositioningWebSocketUrlOptions = {
  /** The id of the organization. */
  organizationId: Scalars['UUID']['input'];
};

/** Options to the getStream query. */
export type GetStreamOptions = {
  /** Thumbprints of recipients to retrieve keys for. */
  recipients?: InputMaybe<Array<Scalars['Thumbprint']['input']>>;
};

/** Options to the getStreams query. */
export type GetStreamsOptions = {
  /** The id of the organization. */
  organizationId: Scalars['UUID']['input'];
  /** Thumbprints of recipients to retrieve keys for. */
  recipients?: InputMaybe<Array<Scalars['Thumbprint']['input']>>;
};

/** Options to the getSystems query. */
export type GetSystemsOptions = {
  /** The id of the organization. */
  organizationId: Scalars['UUID']['input'];
};

export type IddSettings = {
  __typename?: 'IddSettings';
  /**
   * Indicates whether In-Device Diagnostics (IDD) logging has been enabled for this organization
   *
   * The default value for a new organization is 'false'
   */
  enabled: Scalars['Boolean']['output'];
};

export type IddSettingsInput = {
  /** Indicates whether In-Device Diagnostics (IDD) logging should be enabled for this organization */
  enabled: Scalars['Boolean']['input'];
};

export type Invitation = {
  __typename?: 'Invitation';
  createdAt: Scalars['DateTime']['output'];
  invitationArn: Scalars['Arn']['output'];
  invitee: Invitee;
  inviter: Inviter;
  organization: OrganizationInfo;
};


export type InvitationInvitationArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InviteMyAxisPrincipalInput = {
  /** Email of the MyAxis Principal */
  email: Scalars['Email']['input'];
  /** ARN of the Organization the invitation is for */
  organizationArn: Scalars['Arn']['input'];
};

export type InviteMyAxisPrincipalResponse = {
  __typename?: 'InviteMyAxisPrincipalResponse';
  /** ARN of the invitation */
  invitationArn: Scalars['Arn']['output'];
  /** ARN of the created principal */
  principalArn: Scalars['Arn']['output'];
};


export type InviteMyAxisPrincipalResponseInvitationArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};


export type InviteMyAxisPrincipalResponsePrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InviteMyAxisPrincipalsInput = {
  /**
   * Registered MyAxis emails to create invitations for.
   *
   * Maximum input of 20.
   */
  emails: Array<Scalars['Email']['input']>;
  /** ARN of the Organization to create Invitations in. */
  organizationArn: Scalars['Arn']['input'];
};

export type InviteMyAxisPrincipalsResponse = {
  __typename?: 'InviteMyAxisPrincipalsResponse';
  /** Unsuccessful invitations. */
  failed?: Maybe<Array<Maybe<FailedInviteMyAxisPrincipals>>>;
  /** Invitations successfully created. */
  invitations?: Maybe<Array<Maybe<SuccessfulInviteMyAxisPrincipalsResult>>>;
};

export type Invitee = {
  __typename?: 'Invitee';
  /** Email address of the invited MyAxis user. */
  email: Scalars['String']['output'];
  /** ARN of the Principal created for the invited MyAxis user. */
  principalArn: Scalars['Arn']['output'];
};


export type InviteePrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Inviter = {
  __typename?: 'Inviter';
  /** Email address of the Principal that created the Invitation. */
  email: Scalars['String']['output'];
};

export type License = {
  __typename?: 'License';
  /** Number of days the license is valid */
  daysLeft: Scalars['Int']['output'];
  /** The timestamp when this type of license expires. */
  expires: Scalars['Timestamp']['output'];
  /** The kind of license */
  kind: LicenseKind;
  /** The type of the license (annual, trial, demo). */
  type: LicenseType;
};

export enum LicenseError {
  /** There was already a trial license used for this Organization */
  TrialAlreadyEnabled = 'TRIAL_ALREADY_ENABLED'
}

export type LicenseInfo = {
  __typename?: 'LicenseInfo';
  /**
   * Licensed is true if the organization has purchased enough licenses or there is a valid trial/demo
   * period in progress.
   */
  licensed: Scalars['Boolean']['output'];
  /** Licenses contains all the types of licenses this organization has had. */
  licenses: Array<License>;
  /** The type of product this license is valid for. */
  product: Scalars['Product']['output'];
};

export enum LicenseKind {
  /** Default license, no trial available */
  Free = 'FREE',
  /** Default license, after expired full license */
  FreeExpiredFull = 'FREE_EXPIRED_FULL',
  /** Default license, after expired trial license */
  FreeExpiredTrial = 'FREE_EXPIRED_TRIAL',
  /** Default license, trial still available */
  FreePreTrial = 'FREE_PRE_TRIAL',
  /** Purchased license allowing full functionality */
  Full = 'FULL',
  /** A 1-time free license allowing full functionality */
  Trial = 'TRIAL'
}

export enum LicenseType {
  Annual = 'ANNUAL',
  Demo = 'DEMO',
  Trial = 'TRIAL'
}

/** The root mutation */
export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation?: Maybe<AcceptInvitationResponse>;
  /**
   * Acknowledge a stream
   * streamId: The id of the stream
   */
  acknowledgeStream?: Maybe<Scalars['Boolean']['output']>;
  /** Activate an ADMX trial license for an Organization */
  activateADMXTrialLicense: ActivateAdmxTrialLicenseResponse;
  /**
   * Archiving an organization will put it in a soft delete state.
   * The organization will no longer appear in any queries.
   */
  archiveOrganization?: Maybe<ArchiveOrganizationResponse>;
  /** Assigns a Principal to an Access */
  assignAccess?: Maybe<AssignAccessResponse>;
  /** Assign multiple Accesses to one Principal. */
  assignAccesses?: Maybe<AssignAccessesResponse>;
  /**
   * Deletes a batch of Accesses by Arn.
   * This will use best effort to delete the given Accesses
   * meaning no error is returned on failures from missing permissions, etc.
   */
  batchDeleteAccess?: Maybe<BatchDeleteAccessResponse>;
  /**
   * Cancel a pending invitation to an Organization.
   * Can cancel if the active principal is the invited MyAxis principal or,
   * if the active principal is allowed to manage invitations for the target Organization
   */
  cancelInvitation?: Maybe<CancelInvitationResponse>;
  /**
   * Cancel a task group. It will only cancel tasks that have not been
   * dispatched to an Edge Host yet.
   */
  cancelTaskGroup: CancelTaskGroupResponse;
  /** Claim and assign a Body Worn system to an IAM organization. */
  claimSystem?: Maybe<System>;
  /** Configure devices for devices matching the input criteria */
  configureDevice: ConfigureDeviceResponse;
  /** This mutation will create a new Access. */
  createAccess?: Maybe<CreateAccessResponse>;
  /**
   * Create an ActiveDirectory Group for an Integration.
   * ActiveDirectoryGroup act as a Principal, and can be assigned accesses.
   */
  createActiveDirectoryGroup?: Maybe<CreateActiveDirectoryGroupResponse>;
  /**
   * Create a representation of an AD Integration between an Organization
   * and an external AD.
   */
  createActiveDirectoryIntegration?: Maybe<CreateActiveDirectoryIntegrationResponse>;
  /**
   * Create a new device identity.
   *
   * Optionally register it with serial.
   */
  createDeviceIdentity?: Maybe<CreateDeviceIdentityResponse>;
  /**
   * Create an event subscription for a particular email address.
   * This will notify that email address for valid events on the
   * resource being monitored.
   */
  createEmailEventSubscription?: Maybe<CreateEmailEventSubscriptionResponse>;
  /**
   * This mutation will create a new Organization, either as an independent Organization
   * or one owned by a Tenant.
   *
   * The identity that the caller has logged in with will be associated with a new Principal
   * and that Principal will be the owner of the new Organization.
   */
  createOrganization?: Maybe<CreateOrganizationResponse>;
  /**
   * Creates a new organization and provisions a Service Principal
   * that will be set as organization owner.
   */
  createPartnerOrganization?: Maybe<CreatePartnerOrganizationResponse>;
  /**
   * Creates a new Resource Group in either an Organization
   * or another Resource Group.
   */
  createResourceGroup?: Maybe<CreateResourceGroupResponse>;
  /**
   * Creates a new Service Principal in the given Organization.
   *
   * This mutation can only be executed by a Partner Service Principal
   * or MyAxis Principal.
   *
   * Note that, when making the request as a MyAxis Principal, there
   * are requirements on the CSR subject:
   *   - O:  this must be set to the organization ID
   *   - OU: a non-empty string
   *   - CN: a unique identifier in UUID v4 format
   */
  createServicePrincipal?: Maybe<CreateServicePrincipalResponse>;
  /** Will create a webhook event subscription */
  createWebhookEventSubscription: CreateWebhookEventSubscriptionResponse;
  /** This mutation will delete the Access with the given Arn */
  deleteAccess?: Maybe<DeleteAccessResponse>;
  /** Deletes an AD Integration. */
  deleteActiveDirectoryIntegration?: Maybe<DeleteActiveDirectoryIntegrationResponse>;
  /** Will delete a registered event subscription */
  deleteEventSubscription: DeleteEventSubscriptionResponse;
  /** Deletes a principal by ID from an organization. */
  deletePrincipal?: Maybe<DeletePrincipalResponse>;
  /**
   * Deletes a Resource Group.
   * A Resource Group cannot be deleted if a device is registered in it.
   *
   * NOTE: Deleting the parent of a Resource Group with registered devices is still possible.
   */
  deleteResourceGroup?: Maybe<DeleteResourceGroupResponse>;
  /** Disclaim a Body Worn system from an IAM organization. */
  disclaimSystem?: Maybe<System>;
  /**
   * Create an invitation to an Organization for a MyAxis principal.
   * This will allow the invited user to see and accept this invitation.
   */
  inviteMyAxisPrincipal?: Maybe<InviteMyAxisPrincipalResponse>;
  /**
   * Create invitations to an Organization for several MyAxis registered email addresses.
   * With each invitation a Principal is created in the Organization, as well as an Invitation that
   * the user with the registered MyAxis email address will be able to list and accept or deny.
   */
  inviteMyAxisPrincipals?: Maybe<InviteMyAxisPrincipalsResponse>;
  /** Updates push event subscriptions from a list of patch operations. */
  patchPushEventSubscriptions?: Maybe<PushEventTypesResponse>;
  /** Create a device identity (if one is not supplied) and register it for OneClick onboarding. */
  registerAxisDevice?: Maybe<RegisterAxisDeviceResponse>;
  /**
   * Removes an Axis device from the system.
   *
   * This will disable the possibility to use EdgeLink and WebRTC as well.
   */
  removeAxisDevice?: Maybe<RemoveAxisDeviceResponse>;
  /** Start or restart an ACAP application. */
  restartAcap?: Maybe<RestartAcapResponse>;
  /**
   * Restarts the process of onboarding a device. This is relevant
   * if the process ends in an ERROR state or if it stalls
   */
  retryOnboardingAxisDevice?: Maybe<RetryOnboardingAxisDeviceResponse>;
  /**
   * Set the properties of a Principal.
   * Omit a field to leave property unchanged.
   */
  setPrincipalProperties: SetPrincipalPropertiesResponse;
  /** Sets the update schedule for device firmware and ACAPs on an Organization */
  setUpdateSchedule: SetUpdateScheduleResponse;
  /** Starts a Trial for the organization if not already started. */
  startTrial: LicenseInfo;
  /**
   * Sync push subscriptions for the identity that the caller has logged in with.
   * This will create push subscriptions for all Organizations the caller has access to using the
   * identity configuration for the caller.
   */
  syncPushEventSubscriptions?: Maybe<SyncPushEventSubscriptionsResponse>;
  /** Unassign a Principal from an Access */
  unassignAccess?: Maybe<UnassignAccessResponse>;
  /** Update the description of an Organization, Resource Group, Access or Device */
  updateDescription: UpdateDescriptionResponse;
  /** Update the display name of a ActiveDirectoryGroup. */
  updateDisplayName?: Maybe<UpdateDisplayNameResponse>;
  /** Update the name of an Organization or Resource Group */
  updateName: UpdateNameResponse;
  /**
   * Update push subscriptions configuration for the identity that the caller has logged in with.
   * All push subscriptions that uses identity configuration for the calling identity will be affected.
   */
  updatePushEventSubscriptions?: Maybe<UpdatePushEventSubscriptionsResponse>;
  /** Updates organization wide settings */
  updateSettings: OrganizationSettings;
  /** Upgrade firmware versions for devices matching the input criteria */
  upgradeADMXFirmware: UpgradeAdmxFirmwareResponse;
};


/** The root mutation */
export type MutationAcceptInvitationArgs = {
  input: AcceptInvitationInput;
};


/** The root mutation */
export type MutationAcknowledgeStreamArgs = {
  streamId: Scalars['UUID']['input'];
};


/** The root mutation */
export type MutationActivateAdmxTrialLicenseArgs = {
  input: ActivateAdmxTrialLicenseInput;
};


/** The root mutation */
export type MutationArchiveOrganizationArgs = {
  input: ArchiveOrganizationInput;
};


/** The root mutation */
export type MutationAssignAccessArgs = {
  input: AssignAccessInput;
};


/** The root mutation */
export type MutationAssignAccessesArgs = {
  input: AssignAccessesInput;
};


/** The root mutation */
export type MutationBatchDeleteAccessArgs = {
  input: BatchDeleteAccessInput;
};


/** The root mutation */
export type MutationCancelInvitationArgs = {
  input: CancelInvitationInput;
};


/** The root mutation */
export type MutationCancelTaskGroupArgs = {
  input: CancelTaskGroupInput;
};


/** The root mutation */
export type MutationClaimSystemArgs = {
  input: ClaimSystemInput;
};


/** The root mutation */
export type MutationConfigureDeviceArgs = {
  input: ConfigureDeviceInput;
};


/** The root mutation */
export type MutationCreateAccessArgs = {
  input: CreateAccessInput;
};


/** The root mutation */
export type MutationCreateActiveDirectoryGroupArgs = {
  input: CreateActiveDirectoryGroupInput;
};


/** The root mutation */
export type MutationCreateActiveDirectoryIntegrationArgs = {
  input: CreateActiveDirectoryIntegrationInput;
};


/** The root mutation */
export type MutationCreateDeviceIdentityArgs = {
  input: CreateDeviceIdentityInput;
};


/** The root mutation */
export type MutationCreateEmailEventSubscriptionArgs = {
  input: CreateEmailEventSubscriptionInput;
};


/** The root mutation */
export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


/** The root mutation */
export type MutationCreatePartnerOrganizationArgs = {
  input: CreatePartnerOrganizationInput;
};


/** The root mutation */
export type MutationCreateResourceGroupArgs = {
  input: CreateResourceGroupInput;
};


/** The root mutation */
export type MutationCreateServicePrincipalArgs = {
  input: CreateServicePrincipalInput;
};


/** The root mutation */
export type MutationCreateWebhookEventSubscriptionArgs = {
  input: CreateWebhookEventSubscriptionInput;
};


/** The root mutation */
export type MutationDeleteAccessArgs = {
  input: DeleteAccessInput;
};


/** The root mutation */
export type MutationDeleteActiveDirectoryIntegrationArgs = {
  input: DeleteActiveDirectoryIntegrationInput;
};


/** The root mutation */
export type MutationDeleteEventSubscriptionArgs = {
  input: DeleteEventSubscriptionInput;
};


/** The root mutation */
export type MutationDeletePrincipalArgs = {
  input: DeletePrincipalInput;
};


/** The root mutation */
export type MutationDeleteResourceGroupArgs = {
  input: DeleteResourceGroupInput;
};


/** The root mutation */
export type MutationDisclaimSystemArgs = {
  input: DisclaimSystemInput;
};


/** The root mutation */
export type MutationInviteMyAxisPrincipalArgs = {
  input: InviteMyAxisPrincipalInput;
};


/** The root mutation */
export type MutationInviteMyAxisPrincipalsArgs = {
  input: InviteMyAxisPrincipalsInput;
};


/** The root mutation */
export type MutationPatchPushEventSubscriptionsArgs = {
  input: PatchPushEventSubscriptionsInput;
};


/** The root mutation */
export type MutationRegisterAxisDeviceArgs = {
  input: RegisterAxisDeviceInput;
};


/** The root mutation */
export type MutationRemoveAxisDeviceArgs = {
  input: RemoveAxisDeviceInput;
};


/** The root mutation */
export type MutationRestartAcapArgs = {
  input: RestartAcapInput;
};


/** The root mutation */
export type MutationRetryOnboardingAxisDeviceArgs = {
  input: RetryOnboardingAxisDeviceInput;
};


/** The root mutation */
export type MutationSetPrincipalPropertiesArgs = {
  input: SetPrincipalPropertiesInput;
};


/** The root mutation */
export type MutationSetUpdateScheduleArgs = {
  input: SetUpdateScheduleInput;
};


/** The root mutation */
export type MutationStartTrialArgs = {
  input: StartTrialInput;
};


/** The root mutation */
export type MutationUnassignAccessArgs = {
  input: UnassignAccessInput;
};


/** The root mutation */
export type MutationUpdateDescriptionArgs = {
  input: UpdateDescriptionInput;
};


/** The root mutation */
export type MutationUpdateDisplayNameArgs = {
  input: UpdateDisplayNameInput;
};


/** The root mutation */
export type MutationUpdateNameArgs = {
  input: UpdateNameInput;
};


/** The root mutation */
export type MutationUpdatePushEventSubscriptionsArgs = {
  input: UpdatePushEventSubscriptionsInput;
};


/** The root mutation */
export type MutationUpdateSettingsArgs = {
  input: OrganizationSettingsInput;
};


/** The root mutation */
export type MutationUpgradeAdmxFirmwareArgs = {
  input: UpgradeAdmxFirmwareInput;
};

export type MyAxisPrincipal = Principal & {
  __typename?: 'MyAxisPrincipal';
  /** Accesses assigned to this Principal. Returns a maximum of 100. */
  accesses?: Maybe<Array<Access>>;
  /**
   * The MyAxis Principal has full to ADMX functionality
   * @deprecated Should be handled by the License on the Principal
   */
  admxFullAccess: Scalars['Boolean']['output'];
  arn: Scalars['Arn']['output'];
  contactInfo?: Maybe<ContactInfo>;
  createdAt: Scalars['DateTime']['output'];
  invitationStatus: Scalars['InvitationStatus']['output'];
  properties: PrincipalProperties;
};


export type MyAxisPrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MySystemApplication = {
  __typename?: 'MySystemApplication';
  name: Scalars['String']['output'];
  url: Scalars['Url']['output'];
};

export type MySystemsApplication = {
  __typename?: 'MySystemsApplication';
  beta: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  url: Scalars['Url']['output'];
};

export type NtpConfiguration = {
  /** Specify in which mode the ntp client on the device should operate */
  mode: NtpMode;
  /** Provide optional list of static servers in conjunction with STATIC mode */
  servers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum NtpMode {
  /** Enable dynamic time synchronization on the device via DHCP advertising servers */
  Dhcp = 'DHCP',
  /** Disable the NTP client on the device */
  Disable = 'DISABLE',
  /** Enable time synchronization on the device via static configured servers */
  Static = 'STATIC'
}

export type OnboardingSettings = {
  __typename?: 'OnboardingSettings';
  /**
   * The template for the organization
   *
   * Currently not possible to extract!
   */
  template?: Maybe<Scalars['ID']['output']>;
};

export type OnboardingSettingsInput = {
  /**
   * Indicates that the template for the organization should be
   * removed. Default if not present is false
   */
  removeTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  /** The template for the organization */
  template?: InputMaybe<Scalars['ID']['input']>;
};

/** Represents a tenant. */
export type Organization = AccessTarget & {
  __typename?: 'Organization';
  /** Lists all accesses defined in the Organization */
  access?: Maybe<AccessResponse>;
  /** The Active Directory Integrations for this Organization */
  adIntegrations?: Maybe<AdIntegrationsResponse>;
  /** Returns all devices in this Organization */
  allDevices?: Maybe<DevicesResponse>;
  /** Lists the Access that reference this Organization as a target */
  appliedAccesses?: Maybe<AccessResponse>;
  arn: Scalars['Arn']['output'];
  /** This query returns the Resource Group children of an Organization. */
  children?: Maybe<ChildrenResponse>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** All registered event subscriptions in the organization */
  eventSubscriptions?: Maybe<EventSubscriptions>;
  /** See all pending invitations to this Organization */
  invitations?: Maybe<Array<Invitation>>;
  /** The licenses available for the active Principal in the Organization */
  licenses?: Maybe<Array<License>>;
  /** @deprecated Use mySystemsApplications. Removed after client switched. */
  mySystemApplications?: Maybe<Array<MySystemApplication>>;
  /** Retrieve the list of MySystems applications that the principal is permitted to see. */
  mySystemsApplications?: Maybe<Array<MySystemsApplication>>;
  name: Scalars['String']['output'];
  principals?: Maybe<PrincipalsResponse>;
  /**
   * EXPERIMENTAL! HEAVY! MIGHT BE REMOVED!
   * Render a JSON representation of the Organization.
   *
   * Will only fetch 20 Resource Groups for each level.
   *
   * Example response:
   * orgId: {
   *     name: "org name",
   *     children: {
   *         rgId: {
   *             name: "rg name",
   *         }
   *     }
   * }
   * @deprecated Use allChildren query instead.
   */
  resourceTree?: Maybe<OrganizationTreeResponse>;
  self?: Maybe<OrganizationSelf>;
  /**
   * Settings for this organization
   *
   * This entry contains settings that apply for the
   * whole organization
   */
  settings?: Maybe<OrganizationSettings>;
  summary?: Maybe<OrganizationSummary>;
  /** Task groups for an Organization */
  taskGroups: TaskGroupResponse;
};


/** Represents a tenant. */
export type OrganizationAccessArgs = {
  filter?: InputMaybe<AccessFilterInput>;
  page?: InputMaybe<PaginationInput>;
};


/** Represents a tenant. */
export type OrganizationAdIntegrationsArgs = {
  page?: InputMaybe<PaginationInput>;
};


/** Represents a tenant. */
export type OrganizationAllDevicesArgs = {
  deviceFilter?: InputMaybe<DeviceFilterInput>;
  page?: InputMaybe<PaginationInput>;
};


/** Represents a tenant. */
export type OrganizationAppliedAccessesArgs = {
  filter?: InputMaybe<AccessFilterInput>;
  page?: InputMaybe<PaginationInput>;
};


/** Represents a tenant. */
export type OrganizationArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Represents a tenant. */
export type OrganizationChildrenArgs = {
  filter?: InputMaybe<OrganizationChildrenFilterInput>;
  page?: InputMaybe<PaginationInput>;
};


/** Represents a tenant. */
export type OrganizationPrincipalsArgs = {
  page?: InputMaybe<PaginationInput>;
};


/** Represents a tenant. */
export type OrganizationTaskGroupsArgs = {
  input?: InputMaybe<TaskGroupInput>;
};

export type OrganizationChildrenFilterInput = {
  /**
   * Disregard the current client context and return all children where the principal has access.
   *
   * Implied if set with "useCase" field.
   */
  ignoreClient?: InputMaybe<Scalars['Boolean']['input']>;
  /** Return children where the active Principal's Accesses fulfill the use case */
  useCase?: InputMaybe<UseCase>;
};

export type OrganizationInfo = {
  __typename?: 'OrganizationInfo';
  arn: Scalars['Arn']['output'];
  /** Description of the Organization at the time the Invitation was created. */
  description?: Maybe<Scalars['String']['output']>;
  /** Name of the Organization at the time the Invitation was created. */
  name: Scalars['String']['output'];
};


export type OrganizationInfoArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationRef = {
  __typename?: 'OrganizationRef';
  arn: Scalars['Arn']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};


export type OrganizationRefArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Information about the active Principal */
export type OrganizationSelf = {
  __typename?: 'OrganizationSelf';
  /** The active MyAxis or Service Principal. */
  principal?: Maybe<Principal>;
  /** The active AD Group Principals. */
  principals?: Maybe<Array<Principal>>;
};

export type OrganizationSettings = {
  __typename?: 'OrganizationSettings';
  /** In-Device Diagnostics (IDD) settings */
  idd?: Maybe<IddSettings>;
  /** Settings that affect onboarding of devices */
  onboarding?: Maybe<OnboardingSettings>;
};

export type OrganizationSettingsInput = {
  /** In-Device Diagnostics (IDD) settings */
  idd?: InputMaybe<IddSettingsInput>;
  onboarding?: InputMaybe<OnboardingSettingsInput>;
  /** The ARN of the organization where settings are updated */
  organization: Scalars['Arn']['input'];
};

export type OrganizationSummary = {
  __typename?: 'OrganizationSummary';
  /** A list of all firmwares in the Organization grouped by deviceModelName */
  admxFirmwareSummary: Array<AdmxFirmwareSummary>;
  admxFirmwareUpgradeInfo: AdmxFirmwareUpgradeInfoResponse;
};


export type OrganizationSummaryAdmxFirmwareUpgradeInfoArgs = {
  input: AdmxFirmwareUpgradeInfoInput;
};

export type OrganizationTreeResponse = {
  __typename?: 'OrganizationTreeResponse';
  resourceTree?: Maybe<Scalars['String']['output']>;
};

export type OrganizationsResponse = {
  __typename?: 'OrganizationsResponse';
  organizations: Array<OrganizationRef>;
  page: PaginationResponse;
};

/**
 * Request a specific chunk of a list by providing the indices of the items to be retrieved
 * (in fact, you're mostly providing the start index (skip) as well as a count of items to
 * be retrieved (take)).
 */
export type PaginationInput = {
  /**
   * The elements in the list that are skipped before collecting the items to be returned.
   * If skip is not provided, it is 0 by default. The pagination then always starts from
   * the beginning of the list.
   */
  skip?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The number of elements to take after the provided start index. If it is
   * not provided it is 100 by default
   */
  take?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * This type is returned in the response object of all paginated listings. It shows the
 * values used when producing the result. If no pagination input was given the default
 * values will be used.
 */
export type PaginationResponse = {
  __typename?: 'PaginationResponse';
  skip: Scalars['Int']['output'];
  take: Scalars['Int']['output'];
};

export type PatchPushEventSubscriptionsInput = {
  eventTypes: Array<EventTypePatch>;
};

export type Principal = {
  /** Accesses assigned to this Principal. Returns a maximum of 100. */
  accesses?: Maybe<Array<Access>>;
  arn: Scalars['Arn']['output'];
  createdAt: Scalars['DateTime']['output'];
  properties: PrincipalProperties;
};


export type PrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Properties on a Principal that applies to the entire Organization */
export type PrincipalProperties = {
  __typename?: 'PrincipalProperties';
  /** Allows the Principal to manage Accesses in the Organization. */
  isAccessAdmin: Scalars['Boolean']['output'];
  /** Allows viewing Accesses in the Organization */
  isAccessViewer: Scalars['Boolean']['output'];
  /** Owner allows the Principal to do all AXIS IAM operations, including archiving the Organization. */
  isOwner: Scalars['Boolean']['output'];
  /** Allows management of Principals in the Organization, such as creating invitations. */
  isPrincipalAdmin: Scalars['Boolean']['output'];
  /** Allows listing Principals in the Organization */
  isPrincipalViewer: Scalars['Boolean']['output'];
};

export type PrincipalPropertiesInput = {
  isAccessAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isAccessViewer?: InputMaybe<Scalars['Boolean']['input']>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isPrincipalAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isPrincipalViewer?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A shallow representation of a Principal */
export type PrincipalReference = {
  __typename?: 'PrincipalReference';
  /** The Arn of the Principal. */
  arn: Scalars['Arn']['output'];
};


/** A shallow representation of a Principal */
export type PrincipalReferenceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PrincipalsResponse = {
  __typename?: 'PrincipalsResponse';
  page: PaginationResponse;
  principals?: Maybe<Array<Principal>>;
};

export type PushDevice = {
  /** What application to enable push notifications for. */
  application: Scalars['String']['input'];
  /** The unique token for the Device. */
  deviceToken: Scalars['String']['input'];
  /** Push platform type for the Device. */
  platform: PushPlatform;
};

/** A Push event subscription */
export type PushEventSubscription = EventSubscription & {
  __typename?: 'PushEventSubscription';
  /** The events that will trigger the call to the registered devices */
  eventTypes: Array<Scalars['EventType']['output']>;
  /** An ARN that represents the monitored resource */
  resourceArn: Scalars['Arn']['output'];
  /** An ARN that represents the registered event subscription */
  subscriptionArn: Scalars['Arn']['output'];
};

export type PushEventType = {
  __typename?: 'PushEventType';
  enabled: Scalars['Boolean']['output'];
  eventType: Scalars['EventType']['output'];
};

export type PushEventTypeInput = {
  enabled: Scalars['Boolean']['input'];
  eventType: Scalars['EventType']['input'];
};

export type PushEventTypesResponse = {
  __typename?: 'PushEventTypesResponse';
  eventTypes: Array<PushEventType>;
};

export enum PushPlatform {
  ApnsIos = 'APNS_IOS',
  ApnsIosSandbox = 'APNS_IOS_SANDBOX',
  ApnsVoip = 'APNS_VOIP',
  ApnsVoipSandbox = 'APNS_VOIP_SANDBOX',
  Fcm = 'FCM'
}

/** The root query */
export type Query = {
  __typename?: 'Query';
  /**
   * Returns all Accesses from the given Arns. The Arns typically come
   * from a query that returns an AccessResponse
   *
   * Maximum length of input is 50 Arns
   */
  access: Array<Access>;
  /**
   * Query for all Resource Groups in an Organization.
   * This will retrieve Resource Groups of all levels, where Access permits it.
   *
   * In order to represent a tree, the client must build it using the "id" and "parent" fields.
   */
  allChildren?: Maybe<AllChildrenResponse>;
  /** Gets the Application associated with the active client (if any). */
  application?: Maybe<Application>;
  /** Gets all Applications */
  applications: Array<Application>;
  /**
   * This query will export the audit log for a specific Organization.
   * Note that it is only the Organization owner who can export the audit log.
   */
  auditLog?: Maybe<AuditLogResponse>;
  /**
   * Returns the Axis Devices the ARNs refer to.
   *
   * Maximum length of input is 50 ARNs.
   */
  devices: Array<AxisDevice>;
  /** Fetches the license information for the organization. */
  getLicenseInfo: LicenseInfo;
  /** Retrieve a WebSocketUrl to subscribe for notifications data from systems within the organization. */
  getNotificationsWebSocketUrl: WebSocketUrl;
  /** Retrieve a WebSocketUrl to subscribe for positional data from bearers. */
  getPositioningWebSocketUrl: WebSocketUrl;
  /**
   * Retrieve a stream.
   * streamId: The id of the stream.
   */
  getStream: Stream;
  /**
   * Retrieve streams for an organization.
   * Retrieves all streams within an organization that the user has access to.
   */
  getStreams: Array<Stream>;
  /**
   * Retrieve systems for an organization.
   * Retrieves all systems within an organization that the user has access to.
   */
  getSystems: Array<System>;
  /** See all pending invitations for the active MyAxis user */
  invitations?: Maybe<Array<Invitation>>;
  /** This query will return an organization from its ARN. */
  organization?: Maybe<Organization>;
  /**
   * This query lists all Organizations that the calling user is a member of.
   * The list of Organizations consists of shallow organizations with no deep
   * fields present that allow expansion of data.
   */
  organizations?: Maybe<OrganizationsResponse>;
  /**
   * Gets a list of Principals by ARNs.
   *
   * Restrictions:
   *   Maximum length of input is 50 ARNs
   *   All ARNs must belong to the same Organization
   */
  principals: Array<Principal>;
  /**
   * Returns the Resource Groups of the given ARNs
   *
   * Maximum length of input is 50 ARNs
   */
  resourceGroups: Array<ResourceGroup>;
  version: SchemaVersionResponse;
};


/** The root query */
export type QueryAccessArgs = {
  arns: Array<Scalars['Arn']['input']>;
};


/** The root query */
export type QueryAllChildrenArgs = {
  input: AllChildrenInput;
  page?: InputMaybe<PaginationInput>;
};


/** The root query */
export type QueryApplicationArgs = {
  input?: InputMaybe<ApplicationInput>;
};


/** The root query */
export type QueryAuditLogArgs = {
  input: AuditLogInput;
};


/** The root query */
export type QueryDevicesArgs = {
  deviceArn: Array<Scalars['Arn']['input']>;
};


/** The root query */
export type QueryGetLicenseInfoArgs = {
  organizationId: Scalars['UUID']['input'];
  product: Scalars['Product']['input'];
};


/** The root query */
export type QueryGetNotificationsWebSocketUrlArgs = {
  options: GetNotificationsWebSocketUrlOptions;
};


/** The root query */
export type QueryGetPositioningWebSocketUrlArgs = {
  options: GetPositioningWebSocketUrlOptions;
};


/** The root query */
export type QueryGetStreamArgs = {
  options?: InputMaybe<GetStreamOptions>;
  streamId: Scalars['UUID']['input'];
};


/** The root query */
export type QueryGetStreamsArgs = {
  options: GetStreamsOptions;
};


/** The root query */
export type QueryGetSystemsArgs = {
  options: GetSystemsOptions;
};


/** The root query */
export type QueryOrganizationArgs = {
  organizationArn: Scalars['Arn']['input'];
};


/** The root query */
export type QueryOrganizationsArgs = {
  page?: InputMaybe<PaginationInput>;
};


/** The root query */
export type QueryPrincipalsArgs = {
  arns: Array<Scalars['Arn']['input']>;
};


/** The root query */
export type QueryResourceGroupsArgs = {
  arns: Array<Scalars['Arn']['input']>;
};

export type RegisterAxisDeviceInput = {
  /** Description of the device. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Provide the device ARN of an existing device identity. */
  deviceArn?: InputMaybe<Scalars['Arn']['input']>;
  /** Name of the device. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The OAK code of the device to register */
  oak: Scalars['String']['input'];
  /** The ARN of the Resource Group this Axis device will belong to */
  resourceGroupArn: Scalars['Arn']['input'];
  /** The serial number of the device */
  serial: Scalars['String']['input'];
  /** Specifies a time zone to configure the device with. */
  timeZone?: InputMaybe<Scalars['TimeZone']['input']>;
};

export type RegisterAxisDeviceResponse = {
  __typename?: 'RegisterAxisDeviceResponse';
  /** The ARN of the device that has been registered */
  deviceArn: Scalars['Arn']['output'];
};


export type RegisterAxisDeviceResponseDeviceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RemoveAxisDeviceInput = {
  /** The ARN of the device to remove. */
  deviceArn: Scalars['Arn']['input'];
  /**
   * Only remove the reference to the physical device and retain
   * the identity of this device in the system.
   */
  retainIdentity?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RemoveAxisDeviceResponse = {
  __typename?: 'RemoveAxisDeviceResponse';
  /** The ARN of the Axis device that was removed */
  deviceArn: Scalars['Arn']['output'];
};


export type RemoveAxisDeviceResponseDeviceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * ResourceGroup represents an "unit" of access. It groups resources that have
 * the same access or other logical structure
 */
export type ResourceGroup = AccessTarget & {
  __typename?: 'ResourceGroup';
  /**
   * Lists the Access that reference this ResourceGroup as a target.
   * This includes all accesses that reference any parent"
   */
  appliedAccesses?: Maybe<AccessResponse>;
  /** The ARN of the ResourceGroup */
  arn: Scalars['Arn']['output'];
  /** Lists ResourceGroups below this Resource Group. */
  children?: Maybe<ChildrenResponse>;
  /** A timestamp set to the time the ResourceGroup was created */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the ResourceGroup */
  description?: Maybe<Scalars['String']['output']>;
  /** Returns devices in this ResourceGroup */
  devices?: Maybe<DevicesResponse>;
  /** The name of the ResourceGroup */
  name: Scalars['String']['output'];
};


/**
 * ResourceGroup represents an "unit" of access. It groups resources that have
 * the same access or other logical structure
 */
export type ResourceGroupAppliedAccessesArgs = {
  filter?: InputMaybe<AccessFilterInput>;
  page?: InputMaybe<PaginationInput>;
};


/**
 * ResourceGroup represents an "unit" of access. It groups resources that have
 * the same access or other logical structure
 */
export type ResourceGroupArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * ResourceGroup represents an "unit" of access. It groups resources that have
 * the same access or other logical structure
 */
export type ResourceGroupChildrenArgs = {
  page?: InputMaybe<PaginationInput>;
};


/**
 * ResourceGroup represents an "unit" of access. It groups resources that have
 * the same access or other logical structure
 */
export type ResourceGroupDevicesArgs = {
  deviceFilter?: InputMaybe<DeviceFilterInput>;
  page?: InputMaybe<PaginationInput>;
};

/** A shallow representation, or reference, of a Resource Group. */
export type ResourceGroupReference = {
  __typename?: 'ResourceGroupReference';
  /** The ARN of the ResourceGroup */
  arn: Scalars['Arn']['output'];
  /** A timestamp set to the time the ResourceGroup was created */
  createdAt: Scalars['DateTime']['output'];
  /** A description of the ResourceGroup */
  description?: Maybe<Scalars['String']['output']>;
  /** The name of the ResourceGroup */
  name: Scalars['String']['output'];
};


/** A shallow representation, or reference, of a Resource Group. */
export type ResourceGroupReferenceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RestartAcapInput = {
  /** ID of the ACAP to be started or restarted. */
  acapId: Scalars['Int']['input'];
  /** Arn of the device hosting the ACAP. */
  deviceArn: Scalars['Arn']['input'];
};

export type RestartAcapResponse = {
  __typename?: 'RestartAcapResponse';
  acapId: Scalars['Int']['output'];
  deviceArn: Scalars['Arn']['output'];
};


export type RestartAcapResponseDeviceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RetryOnboardingAxisDeviceInput = {
  /** The ARN of the Axis device that shall restart the onboarding process */
  deviceArn: Scalars['Arn']['input'];
};

export type RetryOnboardingAxisDeviceResponse = {
  __typename?: 'RetryOnboardingAxisDeviceResponse';
  /** The ARN of the device the onboarding process was restarted for */
  deviceArn: Scalars['Arn']['output'];
};


export type RetryOnboardingAxisDeviceResponseDeviceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SchemaVersionResponse = {
  __typename?: 'SchemaVersionResponse';
  /** @deprecated Obsolete. */
  version: Scalars['SchemaVersion']['output'];
};

export type ServicePrincipal = Principal & {
  __typename?: 'ServicePrincipal';
  /** Accesses assigned to this Principal. Returns a maximum of 100. */
  accesses?: Maybe<Array<Access>>;
  arn: Scalars['Arn']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  properties: PrincipalProperties;
};


export type ServicePrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetPrincipalPropertiesInput = {
  principalArn: Scalars['Arn']['input'];
  properties: PrincipalPropertiesInput;
};

export type SetPrincipalPropertiesResponse = {
  __typename?: 'SetPrincipalPropertiesResponse';
  principalArn: Scalars['Arn']['output'];
  properties: PrincipalProperties;
};


export type SetPrincipalPropertiesResponsePrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetUpdateScheduleInput = {
  /**
   * The ARN to which the update applies to.
   * This can be either an Organization, Resource Group or an Axis Device.
   */
  arn: Scalars['Arn']['input'];
  /** When updates should occur */
  schedule: Scalars['ScheduleExpression']['input'];
};

export type SetUpdateScheduleResponse = {
  __typename?: 'SetUpdateScheduleResponse';
  arn: Scalars['Arn']['output'];
};


export type SetUpdateScheduleResponseArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StartTrialInput = {
  /** The id of the organization. */
  organizationId: Scalars['UUID']['input'];
  /** The product to start a Trial for. */
  product: Scalars['Product']['input'];
};

export type Stream = {
  __typename?: 'Stream';
  /** The id of the bearer that recorded the stream. */
  bearerId: Scalars['UUID']['output'];
  /** The time at which the playlist was created. */
  created: Scalars['Timestamp']['output'];
  /** Dash playlist. Only available if type is DASH. */
  dash?: Maybe<DashPlaylist>;
  /** The duration of the stream. Present if ongoing is false. */
  duration?: Maybe<Scalars['Float']['output']>;
  /** The time at which the stream ended. Present if the ongoing is false. */
  ended?: Maybe<Scalars['Timestamp']['output']>;
  /** A globally unique id of the stream. */
  id: Scalars['UUID']['output'];
  /** Arbitrary key values representing metadata fields such as SCU UserID, ExternalID etc. */
  metadata?: Maybe<Scalars['Object']['output']>;
  /** Whether or not the stream is currently ongoing. */
  ongoing: Scalars['Boolean']['output'];
  /** The id of the organization that the recording belongs to. */
  organizationId: Scalars['UUID']['output'];
  /** The type of stream. */
  type: StreamType;
  /** The time at which the playlist was uploaded. */
  uploaded: Scalars['Timestamp']['output'];
};

export enum StreamType {
  Dash = 'DASH'
}

export type SuccessfulInviteMyAxisPrincipalsResult = {
  __typename?: 'SuccessfulInviteMyAxisPrincipalsResult';
  /** The email address that the Invitation was created with. */
  email: Scalars['Email']['output'];
  /** ARN of the created Invitation. */
  invitationArn: Scalars['Arn']['output'];
  /** ARN of the created Principal. */
  principalArn: Scalars['Arn']['output'];
};

export type SyncPushEventSubscriptionsResponse = {
  __typename?: 'SyncPushEventSubscriptionsResponse';
  /** The events that will trigger push notifications. */
  eventTypes: Array<PushEventType>;
  /** Organizations that failed to sync. */
  failed?: Maybe<Array<FailedSyncPushSubscriptionsOrganization>>;
  /** All push subscriptions registered for the calling identity. */
  pushSubscriptions: Array<PushEventSubscription>;
};

/** The System type represents a Body Worn System. */
export type System = {
  __typename?: 'System';
  /** The id of the entity representing the system in IAM. */
  id: Scalars['EntityID']['output'];
  /** The name of the system. */
  name: Scalars['String']['output'];
  /** The id of the organization the system is claimed to. */
  organizationID: Scalars['EntityID']['output'];
  /** The storage location of the system. */
  storageLocationID: Scalars['String']['output'];
  /** The system's id. */
  systemID: Scalars['UUID']['output'];
};

export enum TaskCategory {
  /** Add device to site */
  AddDeviceToSite = 'ADD_DEVICE_TO_SITE',
  /** Clear update window */
  ClearUpdateWindow = 'CLEAR_UPDATE_WINDOW',
  /** Device configuration */
  ConfigureDevice = 'CONFIGURE_DEVICE',
  /** Non-existing or unknown category */
  None = 'NONE',
  /** Restart ACAP */
  RestartAcap = 'RESTART_ACAP',
  /** Set update window */
  SetUpdateWindow = 'SET_UPDATE_WINDOW',
  /** Upgrade firmware */
  UpgradeFirmware = 'UPGRADE_FIRMWARE'
}

export type TaskGroup = {
  __typename?: 'TaskGroup';
  /** Number of canceled tasks */
  canceled: Scalars['Int']['output'];
  /** The task category */
  category: TaskCategory;
  /** Number of failed tasks */
  failed: Scalars['Int']['output'];
  /** ID of task group */
  id: Scalars['ID']['output'];
  /** Number of pending tasks */
  pending: Scalars['Int']['output'];
  /** Number of successfully done task */
  success: Scalars['Int']['output'];
  /** When task was initiated */
  timestamp: Scalars['String']['output'];
  /** Number of unsupported tasks */
  unsupported: Scalars['Int']['output'];
};

export type TaskGroupInput = {
  /**
   * The nextPage value from the previous request. If not specified,
   * the first page is returned. The next page value can be stored
   * and reused for paging backwards.
   */
  page?: InputMaybe<Scalars['String']['input']>;
  /** Number of groups to return for the current page. Default is 50. */
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  /** Sort order of the returned groups. Default is CREATED_DESC. */
  sortBy?: InputMaybe<TaskSortOrder>;
};

export type TaskGroupResponse = {
  __typename?: 'TaskGroupResponse';
  /** List of task groups */
  groups: Array<TaskGroup>;
  /**
   * Reference to the next page. This value can be used in the next request
   * to get the next page.
   */
  nextPage?: Maybe<Scalars['String']['output']>;
  /** The total number of group tasks in the organization */
  totalInOrganization: Scalars['Int']['output'];
};

export enum TaskSortOrder {
  /** By created time in ascending order */
  CreatedAsc = 'CREATED_ASC',
  /** By created time in descending order */
  CreatedDesc = 'CREATED_DESC'
}

export enum TextOverlayColor {
  Black = 'BLACK',
  Red = 'RED',
  SemiTransparent = 'SEMI_TRANSPARENT',
  Transparent = 'TRANSPARENT',
  White = 'WHITE'
}

export type TextOverlayConfiguration = {
  /**
   * Defines the size of the text. Maximum value of 200.
   * Default size is determined relative to the maximal camera resolution if
   * fontSize is omitted.
   */
  fontSize?: InputMaybe<Scalars['Int']['input']>;
  /** Defines the x, y coordinates to place the overlay by predefined positions */
  position?: InputMaybe<TextOverlayPosition>;
  /** The text to be rendered on the overlay. Maximum number of characters is 512. */
  text: Scalars['String']['input'];
  /** Defines which background color to display. Default is transparent */
  textBackgroundColor?: InputMaybe<TextOverlayColor>;
  /** Defines which color the text is displayed in. Default is black */
  textColor?: InputMaybe<TextOverlayColor>;
  /** Defines which outline color to display. Default is transparent */
  textOutlineColor?: InputMaybe<TextOverlayColor>;
};

export enum TextOverlayPosition {
  Bottom = 'BOTTOM',
  BottomLeft = 'BOTTOM_LEFT',
  BottomRight = 'BOTTOM_RIGHT',
  Top = 'TOP',
  TopLeft = 'TOP_LEFT',
  TopRight = 'TOP_RIGHT'
}

export type TimeConfiguration = {
  /** Configure option for the network time protocol client on the device. */
  ntp?: InputMaybe<NtpConfiguration>;
  /** Set time zone */
  timeZone?: InputMaybe<DeviceTimeZone>;
};

export type UnassignAccessInput = {
  /** Arn of the Access that the Principal is to be unassigned from */
  accessArn: Scalars['Arn']['input'];
  /** The Principal to be unassigned */
  principalArn: Scalars['Arn']['input'];
};

export type UnassignAccessResponse = {
  __typename?: 'UnassignAccessResponse';
  /** Arn of the Access the Principal was unassigned from */
  accessArn: Scalars['Arn']['output'];
  /** Arn of the Principal unassigned from the Access */
  principalArn: Scalars['Arn']['output'];
};


export type UnassignAccessResponseAccessArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};


export type UnassignAccessResponsePrincipalArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateConfiguration = {
  __typename?: 'UpdateConfiguration';
  /** Specifies when firmware and Acaps will be updated */
  schedule: Scalars['ScheduleExpression']['output'];
};

export type UpdateDescriptionInput = {
  /** Arn of an Organization, Resource Group, Service Principal, Access or Device to update */
  arn: Scalars['Arn']['input'];
  /** New description to update to */
  description: Scalars['String']['input'];
};

export type UpdateDescriptionResponse = {
  __typename?: 'UpdateDescriptionResponse';
  arn: Scalars['Arn']['output'];
  description: Scalars['String']['output'];
};


export type UpdateDescriptionResponseArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDisplayNameInput = {
  /**
   * ARN of the resource to update.
   *
   * See updateDisplayName mutation for valid resources to update.
   */
  arn: Scalars['Arn']['input'];
  /** New display name. */
  displayName: Scalars['String']['input'];
};

export type UpdateDisplayNameResponse = {
  __typename?: 'UpdateDisplayNameResponse';
  /** ARN of the updated resource. */
  arn: Scalars['Arn']['output'];
  /** New display name. */
  displayName: Scalars['String']['output'];
};

export type UpdateNameInput = {
  /** Arn of an Organization, Resource Group or Device to update */
  arn: Scalars['Arn']['input'];
  /** New name to update to */
  name: Scalars['String']['input'];
};

export type UpdateNameResponse = {
  __typename?: 'UpdateNameResponse';
  arn: Scalars['Arn']['output'];
  name: Scalars['String']['output'];
};


export type UpdateNameResponseArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePushEventSubscriptionsInput = {
  /** Devices to get push notifications. */
  addDevices?: InputMaybe<Array<PushDevice>>;
  /** Enable or disable push event notifications. */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The events that will trigger push notifications. */
  eventTypes?: InputMaybe<Array<PushEventTypeInput>>;
  /** Devices to stop getting push notifications. */
  removeDevices?: InputMaybe<Array<PushDevice>>;
};

export type UpdatePushEventSubscriptionsResponse = {
  __typename?: 'UpdatePushEventSubscriptionsResponse';
  /** If push notifications is enabled or not */
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The events that will trigger push notifications */
  eventTypes?: Maybe<Array<PushEventType>>;
};

export enum UpgradeAdmxFirmwareError {
  /** Generic error */
  GenericError = 'GENERIC_ERROR'
}

export type UpgradeAdmxFirmwareInput = {
  /** The ARN of the Organization */
  arn: Scalars['Arn']['input'];
  /** The current firmware version of the devices to be upgraded */
  currentVersion: Scalars['String']['input'];
  /** The model name of the devices to be upgraded */
  deviceModelName: Scalars['String']['input'];
  /** List of site ids of the devices to be upgraded */
  siteIds: Array<Scalars['ID']['input']>;
  /** The target firmware version */
  targetVersion: Scalars['String']['input'];
};

export type UpgradeAdmxFirmwareResponse = {
  __typename?: 'UpgradeADMXFirmwareResponse';
  /** Reason the upgrade failed */
  error?: Maybe<UpgradeAdmxFirmwareError>;
};

/** UseCases represents abilities across different Access role types. */
export enum UseCase {
  DeviceOnboarding = 'DEVICE_ONBOARDING'
}

/** The WebSocketUrl type represents an object containing a URL with credentials to access an Azure Web PubSub service. */
export type WebSocketUrl = {
  __typename?: 'WebSocketUrl';
  /** A WebSocket client access URL. */
  url: Scalars['String']['output'];
};

/** A Webhook event subscription */
export type WebhookEventSubscription = EventSubscription & {
  __typename?: 'WebhookEventSubscription';
  /** The events that will trigger the call to the registered URL */
  eventTypes: Array<Scalars['EventType']['output']>;
  /** An ARN that represents the monitored resource */
  resourceArn: Scalars['Arn']['output'];
  /** An ARN that represents the registered event subscription */
  subscriptionArn: Scalars['Arn']['output'];
  /**
   * The registered URL that will be called when the event happens
   * on the resource
   */
  url: Scalars['Url']['output'];
};


/** A Webhook event subscription */
export type WebhookEventSubscriptionResourceArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};


/** A Webhook event subscription */
export type WebhookEventSubscriptionSubscriptionArnArgs = {
  v2?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteEventSubscriptionMutationVariables = Exact<{
  input: DeleteEventSubscriptionInput;
}>;


export type DeleteEventSubscriptionMutation = { __typename?: 'Mutation', deleteEventSubscription: { __typename?: 'DeleteEventSubscriptionResponse', arn: any } };

export type CreateWebhookEventSubscriptionMutationVariables = Exact<{
  input: CreateWebhookEventSubscriptionInput;
}>;


export type CreateWebhookEventSubscriptionMutation = { __typename?: 'Mutation', createWebhookEventSubscription: { __typename?: 'CreateWebhookEventSubscriptionResponse', subscription: { __typename?: 'WebhookEventSubscription', eventTypes: Array<any>, resourceArn: any, subscriptionArn: any, url: any } } };

export type OrganizationQueryVariables = Exact<{
  organizationArn: Scalars['Arn']['input'];
}>;


export type OrganizationQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', arn: any, createdAt: any, name: string, eventSubscriptions?: { __typename?: 'EventSubscriptions', subscriptions?: Array<{ __typename?: 'EmailEventSubscription', pending: boolean, recipient: any, eventTypes: Array<any>, resourceArn: any, subscriptionArn: any } | { __typename?: 'PushEventSubscription', eventTypes: Array<any>, resourceArn: any, subscriptionArn: any } | { __typename?: 'WebhookEventSubscription', url: any, eventTypes: Array<any>, resourceArn: any, subscriptionArn: any }> | null } | null } | null };

export type AcknowledgeStreamMutationVariables = Exact<{
  streamId: Scalars['UUID']['input'];
}>;


export type AcknowledgeStreamMutation = { __typename?: 'Mutation', acknowledgeStream?: boolean | null };

export type ClaimSystemMutationVariables = Exact<{
  input: ClaimSystemInput;
}>;


export type ClaimSystemMutation = { __typename?: 'Mutation', claimSystem?: { __typename?: 'System', id: any, systemID: string } | null };

export type DisclaimSystemMutationVariables = Exact<{
  input: DisclaimSystemInput;
}>;


export type DisclaimSystemMutation = { __typename?: 'Mutation', disclaimSystem?: { __typename?: 'System', systemID: string } | null };

export type StartTrialMutationVariables = Exact<{
  input: StartTrialInput;
}>;


export type StartTrialMutation = { __typename?: 'Mutation', startTrial: { __typename?: 'LicenseInfo', licensed: boolean, product: any, licenses: Array<{ __typename?: 'License', expires: any }> } };

export type GetStreamQueryVariables = Exact<{
  streamId: Scalars['UUID']['input'];
  options?: InputMaybe<GetStreamOptions>;
}>;


export type GetStreamQuery = { __typename?: 'Query', getStream: { __typename?: 'Stream', bearerId: string, duration?: number | null, id: string, metadata?: any | null, ongoing: boolean, organizationId: string, dash?: { __typename?: 'DashPlaylist', mpd: string, segmentBasePath: string, token: string, storageLocationId: string, crypto?: { __typename?: 'DashCryptoContextV1', keys?: any | null, version: string } | null } | null } };

export type GetStreamsQueryVariables = Exact<{
  options: GetStreamsOptions;
}>;


export type GetStreamsQuery = { __typename?: 'Query', getStreams: Array<{ __typename?: 'Stream', bearerId: string, duration?: number | null, id: string, metadata?: any | null, ongoing: boolean }> };

export type GetLicenseInfoQueryVariables = Exact<{
  organizationId: Scalars['UUID']['input'];
  product: Scalars['Product']['input'];
}>;


export type GetLicenseInfoQuery = { __typename?: 'Query', getLicenseInfo: { __typename?: 'LicenseInfo', licensed: boolean, product: any, licenses: Array<{ __typename?: 'License', type: LicenseType, expires: any }> } };

export type GetSystemsQueryVariables = Exact<{
  options: GetSystemsOptions;
}>;


export type GetSystemsQuery = { __typename?: 'Query', getSystems: Array<{ __typename?: 'System', id: any, name: string, systemID: string, organizationID: any, storageLocationID: string }> };

export type GetNotificationsWebSocketUrlQueryVariables = Exact<{
  options: GetNotificationsWebSocketUrlOptions;
}>;


export type GetNotificationsWebSocketUrlQuery = { __typename?: 'Query', getNotificationsWebSocketUrl: { __typename?: 'WebSocketUrl', url: string } };

export type GetPositioningWebSocketUrlQueryVariables = Exact<{
  options: GetPositioningWebSocketUrlOptions;
}>;


export type GetPositioningWebSocketUrlQuery = { __typename?: 'Query', getPositioningWebSocketUrl: { __typename?: 'WebSocketUrl', url: string } };


export const DeleteEventSubscriptionDocument = gql`
    mutation deleteEventSubscription($input: DeleteEventSubscriptionInput!) {
  deleteEventSubscription(input: $input) {
    arn
  }
}
    `;

export function useDeleteEventSubscriptionMutation() {
  return Urql.useMutation<DeleteEventSubscriptionMutation, DeleteEventSubscriptionMutationVariables>(DeleteEventSubscriptionDocument);
};
export const CreateWebhookEventSubscriptionDocument = gql`
    mutation createWebhookEventSubscription($input: CreateWebhookEventSubscriptionInput!) {
  createWebhookEventSubscription(input: $input) {
    subscription {
      eventTypes
      resourceArn
      subscriptionArn
      url
    }
  }
}
    `;

export function useCreateWebhookEventSubscriptionMutation() {
  return Urql.useMutation<CreateWebhookEventSubscriptionMutation, CreateWebhookEventSubscriptionMutationVariables>(CreateWebhookEventSubscriptionDocument);
};
export const OrganizationDocument = gql`
    query organization($organizationArn: Arn!) {
  organization(organizationArn: $organizationArn) {
    arn
    createdAt
    name
    eventSubscriptions {
      subscriptions {
        eventTypes
        resourceArn
        subscriptionArn
        ... on WebhookEventSubscription {
          url
        }
        ... on EmailEventSubscription {
          pending
          recipient
        }
      }
    }
  }
}
    `;

export function useOrganizationQuery(options: Omit<Urql.UseQueryArgs<OrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<OrganizationQuery, OrganizationQueryVariables>({ query: OrganizationDocument, ...options });
};
export const AcknowledgeStreamDocument = gql`
    mutation acknowledgeStream($streamId: UUID!) {
  acknowledgeStream(streamId: $streamId)
}
    `;

export function useAcknowledgeStreamMutation() {
  return Urql.useMutation<AcknowledgeStreamMutation, AcknowledgeStreamMutationVariables>(AcknowledgeStreamDocument);
};
export const ClaimSystemDocument = gql`
    mutation claimSystem($input: ClaimSystemInput!) {
  claimSystem(input: $input) {
    id
    systemID
  }
}
    `;

export function useClaimSystemMutation() {
  return Urql.useMutation<ClaimSystemMutation, ClaimSystemMutationVariables>(ClaimSystemDocument);
};
export const DisclaimSystemDocument = gql`
    mutation disclaimSystem($input: DisclaimSystemInput!) {
  disclaimSystem(input: $input) {
    systemID
  }
}
    `;

export function useDisclaimSystemMutation() {
  return Urql.useMutation<DisclaimSystemMutation, DisclaimSystemMutationVariables>(DisclaimSystemDocument);
};
export const StartTrialDocument = gql`
    mutation startTrial($input: StartTrialInput!) {
  startTrial(input: $input) {
    licensed
    licenses {
      expires
    }
    product
  }
}
    `;

export function useStartTrialMutation() {
  return Urql.useMutation<StartTrialMutation, StartTrialMutationVariables>(StartTrialDocument);
};
export const GetStreamDocument = gql`
    query getStream($streamId: UUID!, $options: GetStreamOptions) {
  getStream(streamId: $streamId, options: $options) {
    bearerId
    dash {
      mpd
      segmentBasePath
      token
      crypto {
        version
        ... on DashCryptoContextV1 {
          keys
        }
      }
      storageLocationId
    }
    duration
    id
    metadata
    ongoing
    organizationId
  }
}
    `;

export function useGetStreamQuery(options: Omit<Urql.UseQueryArgs<GetStreamQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStreamQuery, GetStreamQueryVariables>({ query: GetStreamDocument, ...options });
};
export const GetStreamsDocument = gql`
    query getStreams($options: GetStreamsOptions!) {
  getStreams(options: $options) {
    bearerId
    duration
    id
    metadata
    ongoing
  }
}
    `;

export function useGetStreamsQuery(options: Omit<Urql.UseQueryArgs<GetStreamsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStreamsQuery, GetStreamsQueryVariables>({ query: GetStreamsDocument, ...options });
};
export const GetLicenseInfoDocument = gql`
    query getLicenseInfo($organizationId: UUID!, $product: Product!) {
  getLicenseInfo(organizationId: $organizationId, product: $product) {
    licensed
    licenses {
      type
      expires
    }
    product
  }
}
    `;

export function useGetLicenseInfoQuery(options: Omit<Urql.UseQueryArgs<GetLicenseInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLicenseInfoQuery, GetLicenseInfoQueryVariables>({ query: GetLicenseInfoDocument, ...options });
};
export const GetSystemsDocument = gql`
    query getSystems($options: GetSystemsOptions!) {
  getSystems(options: $options) {
    id
    name
    systemID
    organizationID
    storageLocationID
  }
}
    `;

export function useGetSystemsQuery(options: Omit<Urql.UseQueryArgs<GetSystemsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSystemsQuery, GetSystemsQueryVariables>({ query: GetSystemsDocument, ...options });
};
export const GetNotificationsWebSocketUrlDocument = gql`
    query getNotificationsWebSocketUrl($options: GetNotificationsWebSocketUrlOptions!) {
  getNotificationsWebSocketUrl(options: $options) {
    url
  }
}
    `;

export function useGetNotificationsWebSocketUrlQuery(options: Omit<Urql.UseQueryArgs<GetNotificationsWebSocketUrlQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNotificationsWebSocketUrlQuery, GetNotificationsWebSocketUrlQueryVariables>({ query: GetNotificationsWebSocketUrlDocument, ...options });
};
export const GetPositioningWebSocketUrlDocument = gql`
    query getPositioningWebSocketUrl($options: GetPositioningWebSocketUrlOptions!) {
  getPositioningWebSocketUrl(options: $options) {
    url
  }
}
    `;

export function useGetPositioningWebSocketUrlQuery(options: Omit<Urql.UseQueryArgs<GetPositioningWebSocketUrlQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPositioningWebSocketUrlQuery, GetPositioningWebSocketUrlQueryVariables>({ query: GetPositioningWebSocketUrlDocument, ...options });
};
import {
  AcknowledgeStreamDocument,
  AcknowledgeStreamMutation,
  ErrorCode,
} from '../graphql/__generated__/graphql'
import { ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

function useAcknowledgeStream() {
  const client = useUrqlClient(Api.Bwo)

  return function (streamId: string) {
    if (!client) {
      return
    }
    return client
      ?.mutation<AcknowledgeStreamMutation>(AcknowledgeStreamDocument, {
        streamId,
      })
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode =
            (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
            InternalErrorCode.NetworkError
          return {
            status: ApiStatus.Rejected,
            error: errorCode,
          }
        } else {
          return {
            status: ApiStatus.Resolved,
          }
        }
      })
  }
}

export { useAcknowledgeStream }

import { useCallback, useEffect, useState } from 'react'

import {
  EmailEventSubscription,
  ErrorCode,
  OrganizationDocument,
  OrganizationQuery,
  WebhookEventSubscription,
} from '../graphql/__generated__/graphql'
import { ApiResult, ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

export type Organization = {
  arn: string
  name: string
  createdAt: string
  emailSubscriptions?: EmailEventSubscription[]
  webhookSubscriptions?: WebhookEventSubscription[]
}

type UseOrganizationResult = [ApiResult<Organization>, () => void]

export function useOrganization(organizationId: string): UseOrganizationResult {
  const client = useUrqlClient(Api.Acx)
  const [result, setResult] = useState<ApiResult<Organization>>({
    status: ApiStatus.Idle,
  })

  const getOrganization = useCallback(() => {
    if (!client) {
      return
    }
    setResult({ status: ApiStatus.Loading })
    client
      .query<OrganizationQuery>(
        OrganizationDocument,
        {
          organizationArn: `arn:organization:${organizationId}`,
        },
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode = result.error.graphQLErrors[0]?.extensions.code
          setResult({
            status: ApiStatus.Rejected,
            error: errorCode as ErrorCode,
          })
        } else {
          const data = result.data?.organization
          if (data) {
            const organization: Organization = {
              arn: data.arn,
              name: data.name,
              createdAt: data.createdAt,
              emailSubscriptions: [],
              webhookSubscriptions: [],
            }

            if (data.eventSubscriptions?.subscriptions) {
              data.eventSubscriptions?.subscriptions?.forEach(
                (subscription) => {
                  const type = subscription.__typename
                  if (type === 'WebhookEventSubscription') {
                    organization.webhookSubscriptions?.push(subscription)
                  } else if (type === 'EmailEventSubscription') {
                    organization.emailSubscriptions?.push(subscription)
                  }
                }
              )
            }

            setResult({
              status: ApiStatus.Resolved,
              data: organization,
            })
          }
        }
      })
      .catch(() => {
        setResult({
          status: ApiStatus.Rejected,
          error: InternalErrorCode.NetworkError,
        })
      })
  }, [client, organizationId])

  useEffect(() => {
    getOrganization()
  }, [getOrganization])

  return [result, getOrganization]
}

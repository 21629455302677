import { Recorder24Regular } from '@axiscommunications/fluent-icons'
import { useStorageLocations } from '@axteams-one/bws-cloud-discovery/react'
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
  Tooltip,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { Trans, useTranslation } from 'react-i18next'

import { System } from '../../hooks/useSystems'
import { useGetPopulatedTopbarUrl } from '../../providers/TopbarProvider'
import { MySystemsApplicationId } from '../../util/MySystemsApplicationId'
import { ExternalLink } from '../ExternalLink'
import { DisclaimDialog } from './DisclaimDialog'

const useStyles = makeStyles({
  paragraph: {
    display: 'block',
    marginBottom: '1em',
  },
  systemList: {
    marginTop: tokens.spacingVerticalS,
    padding: '0',
    borderTop: `1px solid ${tokens.colorNeutralStroke2}`,
    listStyleType: 'none',
  },
  systemListItem: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingVerticalS,
    padding: `${tokens.spacingVerticalS} 0`,
    '& + li': {
      // Add a border only between list items
      borderTop: `1px solid ${tokens.colorNeutralStroke2}`,
    },
  },
  systemDisclaimButton: {
    flexShrink: 0,
  },
  systemTitle: {
    flexGrow: 1,
    overflowWrap: 'anywhere',
  },
})

type DisclaimSystemListProps = {
  list: System[]
  onDisclaim: (systemId: string) => void
  organizationName: string
}

export function DisclaimSystemList({
  list,
  onDisclaim,
  organizationName,
}: DisclaimSystemListProps) {
  const styles = useStyles()
  const storageLocations = useStorageLocations()
  const userManagementUrl = useGetPopulatedTopbarUrl(MySystemsApplicationId.IAM)
  const { t } = useTranslation('settings-connected-systems')

  if (list.length === 0) {
    const iamPortalLink = (
      <ExternalLink href={userManagementUrl} text="AXIS User Management" />
    )

    return (
      <div>
        <Text className={styles.paragraph}>{t('no-available-systems')}</Text>
        <Trans
          i18nKey="no-available-systems-go-to-iam"
          t={t}
          components={{ iamPortalLink }}
        />
      </div>
    )
  }

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>{t('system')}</TableHeaderCell>
            <TableHeaderCell>{t('storage-location')}</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHeader>
        <TableBody>
          {list.map((system) => {
            const name = system.name?.trim() || system.systemID
            const title = system.name
              ? `${system.name} (${system.systemID})`
              : system.systemID
            const storageLocation = storageLocations.find(
              (storageLocation) =>
                storageLocation.id === system.storageLocationID
            )
            const storageNicename =
              storageLocation?.name ?? system.storageLocationID
            return (
              <TableRow key={system.systemID}>
                <TableCell>
                  <TableCellLayout
                    media={<Avatar icon={<Recorder24Regular />} />}
                    truncate
                  >
                    <Tooltip content={title} relationship="description">
                      <span>{name}</span>
                    </Tooltip>
                  </TableCellLayout>
                </TableCell>
                <TableCell>{storageNicename}</TableCell>
                <TableCell>
                  <TableCellLayout>
                    <DisclaimDialog
                      onDisclaim={onDisclaim}
                      organizationName={organizationName}
                      system={system}
                    />
                  </TableCellLayout>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

import { useCallback, useState } from 'react'
import { SpringValue, useSpring } from 'react-spring'

import {
  DASHBOARD_SIDEBAR_COMPRESSED_WIDTH,
  DASHBOARD_SIDEBAR_MAX_WIDTH,
  DASHBOARD_SIDEBAR_MIN_WIDTH,
} from '../constants'

/**
 * Hook to control sidebar.
 * @returns state, animation style and callback functions needed for sidebar.
 */
export function useSidebar(): {
  showSidebar: boolean
  handleSidebarResize: (xDelta: number, isResizing: boolean) => void
  handleToggleSidebar: () => void
  sidebarAnimationStyle: {
    width: SpringValue<number>
  }
} {
  const [sidebarWidth, setSidebarWidth] = useState(
    getCachedSidebarWidth() ?? DASHBOARD_SIDEBAR_MIN_WIDTH
  )
  const [showSidebar, setShowSidebar] = useState(
    localStorage.getItem('showSidebar') !== 'false'
  )

  const [sidebarAnimationStyle, sidebarAnimationApi] = useSpring(() => ({
    width: showSidebar ? sidebarWidth : DASHBOARD_SIDEBAR_COMPRESSED_WIDTH,
    config: { tension: 800, friction: 70 },
  }))

  const setAndCacheSidebarWidth = useCallback((width: number) => {
    setSidebarWidth(width)
    setCachedSidebarWidth(width)
  }, [])

  const handleSidebarResize = useCallback(
    (xDelta: number, isResizing: boolean) => {
      const width = Math.min(
        Math.max(sidebarWidth + xDelta, DASHBOARD_SIDEBAR_MIN_WIDTH),
        DASHBOARD_SIDEBAR_MAX_WIDTH
      )

      sidebarAnimationApi.start({
        width,
        immediate: true,
      })

      if (!isResizing) {
        setAndCacheSidebarWidth(width)
      }
    },
    [setAndCacheSidebarWidth, sidebarAnimationApi, sidebarWidth]
  )

  const handleToggleSidebar = useCallback(() => {
    setShowSidebar((prev) => {
      const showSidebar = !prev
      sidebarAnimationApi.start({
        width: showSidebar ? sidebarWidth : DASHBOARD_SIDEBAR_COMPRESSED_WIDTH,
        immediate: false,
      })
      localStorage.setItem('showSidebar', showSidebar.toString())

      return showSidebar
    })
  }, [sidebarAnimationApi, sidebarWidth])

  return {
    showSidebar,
    handleSidebarResize,
    handleToggleSidebar,
    sidebarAnimationStyle,
  }
}

// Retrieve the sidebar with from browser local storage.
function getCachedSidebarWidth() {
  const sidebarWidth = localStorage.getItem('sidebarWidth')

  if (!sidebarWidth) {
    return null
  }

  try {
    return JSON.parse(sidebarWidth) as number
  } catch (error) {
    console.warn('Failed parsing cached sidebar width')
  }
  return null
}

// Store the sidebar width in browser local
// storage.
function setCachedSidebarWidth(sidebarWidth: number) {
  try {
    localStorage.setItem('sidebarWidth', JSON.stringify(sidebarWidth))
  } catch (error) {
    console.warn('Failed saving cached sidebar width')
  }
}

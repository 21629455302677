import { JWK, KeyTypeOKP } from '@axteams-one/bws-cloud-crypto/jwk'
import { useCallback, useEffect, useState } from 'react'

export interface StoredKey {
  jwk: JWK<KeyTypeOKP>
  thumbprint: string
}

/** Use and manage configured content keys. */
export function useContentKeys(): [
  StoredKey[],
  React.Dispatch<React.SetStateAction<StoredKey[]>>,
] {
  const [keys, setKeys] = useState<StoredKey[]>(() => {
    const item = localStorage.getItem('keys')
    if (item) {
      try {
        return JSON.parse(item)
      } catch {
        return []
      }
    }
    return []
  })

  // Update local storage when the keys are changed
  useEffect(() => {
    localStorage.setItem('keys', JSON.stringify(keys))
  }, [keys])

  return [keys, setKeys]
}

/**
 * Use and manage a configured content key.
 * Technically we should support multiple keys, but to simplify the UI, we only
 * expose the option to use a single key to the user. Therefore we provide this
 * simplified hook which assumes there's one key.
 * @see useContentKeys
 */
export function useContentKey(): [
  StoredKey | null,
  React.Dispatch<React.SetStateAction<StoredKey | null>>,
] {
  const [keys, setKeys] = useContentKeys()

  const setKey = useCallback(
    (value: React.SetStateAction<StoredKey | null>) => {
      setKeys((keys) => {
        if (typeof value === 'function') {
          const result = value(keys.length > 0 ? keys[0] : null)
          return result ? [result] : []
        } else {
          if (value) {
            return [value]
          } else {
            return []
          }
        }
      })
    },
    [setKeys]
  )

  return [keys.length > 0 ? keys[0] : null, setKey]
}

import { Position } from '@axteams-one/bws-cloud-maps/layers/tracking'
import { TimeSource } from '@axteams-one/bws-cloud-time-source'
import { useTimeSource } from '@axteams-one/bws-cloud-time-source/react/useTimeSource'
import { Temporal } from '@js-temporal/polyfill'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

import { bearerIdFromSubject } from '../util/map'

/**
 * Hook for tracking inactive bearers
 * @param positions - Positions
 * @param inactiveThreshold - Inactivity threshold in ms
 * @returns Inactive bearers
 */
export default function useInactiveBearers(
  positions: Position[],
  inactiveThreshold: number
) {
  const { timeSource } = useTimeSource()
  const [inactiveBearers, setInactiveBearers] = useState<string[]>([])

  // Mark as bearer as inactive if its last position is inactive
  const updateInactiveBearers = useCallback(
    (positions: Position[]) => {
      setInactiveBearers(
        positions
          .filter(includeInactivePositions(inactiveThreshold, timeSource))
          .map(({ subject }) => bearerIdFromSubject(subject))
      )
    },
    [inactiveThreshold, timeSource]
  )

  // Update after inactiveThreshold ms from last positions update
  const updateInactiveBearersDebounced = useRef(
    useDebounceCallback(updateInactiveBearers, inactiveThreshold)
  )
  useEffect(() => {
    updateInactiveBearersDebounced.current(positions)
  }, [updateInactiveBearersDebounced, positions])

  // Update on positions update
  useEffect(
    () => updateInactiveBearers(positions),
    [positions, inactiveThreshold, timeSource, updateInactiveBearers]
  )

  return inactiveBearers
}

/**
 * Return true if last position update is older than threshold.
 * @param {number} positionTime
 * @param {number} inactiveThreshold
 * @param {TimeSource} timeSource
 */
function inactivePosition(
  positionTime: number,
  inactiveThreshold: number,
  timeSource?: TimeSource
) {
  const now = timeSource
    ? timeSource.getTime()
    : Temporal.Now.instant().epochMilliseconds

  return now - positionTime > inactiveThreshold
}

/** Wrapped inactivePosition for filtering. */
function includeInactivePositions(
  inactiveThreshold: number,
  timeSource?: TimeSource
) {
  return (position: Position) =>
    inactivePosition(position.time, inactiveThreshold, timeSource)
}

import { MapType } from '@axteams-one/bws-cloud-maps'
import { useState } from 'react'

import { getCachedMapType, setCachedMapType } from '../util/map'

export default function useMapType() {
  const [mapType, setMapType] = useState<MapType>(
    getCachedMapType() ?? 'roadmap'
  )

  function handleMapTypeChange(mapType: MapType) {
    setCachedMapType(mapType)
    setMapType(mapType)
  }

  return { mapType, handleMapTypeChange }
}

import {
  DeleteEventSubscriptionDocument,
  DeleteEventSubscriptionMutation,
  ErrorCode,
} from '../graphql/__generated__/graphql'
import { ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

type DeleteEventSubscriptionsProps = {
  arn: string
}

export function useDeleteEventSubscription() {
  const client = useUrqlClient(Api.Acx)

  return function ({ arn }: DeleteEventSubscriptionsProps) {
    return client
      ?.mutation<DeleteEventSubscriptionMutation>(
        DeleteEventSubscriptionDocument,
        {
          input: {
            arn,
          },
        }
      )
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode =
            (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
            InternalErrorCode.NetworkError
          return {
            status: ApiStatus.Rejected,
            error: errorCode,
          }
        } else {
          return {
            status: ApiStatus.Resolved,
          }
        }
      })
  }
}

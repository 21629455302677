import { Temporal } from '@js-temporal/polyfill'
import { useEffect } from 'react'

/**
If the application is sleeping (JS code not executing) for more than 2
minutes, reload application once the application wakes up (JS
code resumes execution). Sleep could be caused by a number of reasons,
e.g. tab inactive, OS is in standby, etc. A reload of the window ensures
that the application is running in a 'fresh' state and required tokens
are valid to avoid processes failing in the background, e.g. stream
queries fails,  while user unknowingly assumes everything works as
expected.

Two minutes was selected since we do not want to trigger unnecessary
reloads, e.g. user locks screen and immediately unlocks and resumes
using the  application. However, we also want to allow the use of short
lived tokens (~5 min) without the risk of tokens expiring without reload
during sleep.
 */
export function useReloadOnWakeUp() {
  useEffect(() => {
    let lastTime = Temporal.Now.instant().epochMilliseconds

    setInterval(() => {
      const currentTime = Temporal.Now.instant().epochMilliseconds
      const sleepDuration = currentTime - lastTime

      if (sleepDuration > 120 * 1000) {
        window.location.reload()
      }

      lastTime = currentTime
    }, 60 * 1000)
  }, [])
}

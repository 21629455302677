import {
  NavigationContract20Regular,
  NavigationExpand20Regular,
} from '@axiscommunications/fluent-icons'
import {
  NoContentDark,
  NoContentLight,
} from '@axiscommunications/fluent-illustrations'
import { AuthStatus, useAuth } from '@axteams-one/auth-provider'
import useFlag from '@axteams-one/bws-cloud-flags/react/useFlag'
import {
  Body2,
  Button,
  Toast,
  ToastTitle,
  makeStyles,
  mergeClasses,
  tokens,
  useToastController,
} from '@fluentui/react-components'
import { animated } from '@react-spring/web'
import { DragEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'

import { CreateGroupDialog } from '../components/CreateGroupDialog'
import { LoadingPage } from '../components/Loading'
import { Map as MapViewer } from '../components/Map/Map'
import SidebarResizer from '../components/SidebarResizer/SidebarResizer'
import { StreamList } from '../components/StreamList'
import StreamsFilter from '../components/StreamsFilter'
import StreamsHeader from '../components/StreamsHeader'
import { HEADER_HEIGHT, MAX_GROUP_MEMBER_COUNT, TOASTER_ID } from '../constants'
import { useContentKeys } from '../hooks/useContentKeys'
import useInactiveBearers from '../hooks/useInactiveBearers'
import usePositions from '../hooks/usePositions'
import { useRedirectIfNotLoggedIn } from '../hooks/useRedirectIfNotLoggedIn'
import { useSidebar } from '../hooks/useSidebar'
import { useStreamToasts } from '../hooks/useStreamToasts'
import { useStreams } from '../hooks/useStreams'
import { useStreamsFilter } from '../hooks/useStreamsFilter'
import { useCurrentOrganization } from '../providers/CurrentOrganizationProvider'
import { useNotificationsClient } from '../providers/NotificationsProvider'
import { useThemeId } from '../providers/ThemeProvider'
import { Toast as PositionsWebSocketToast } from '../util/Toasts'
import { Flags } from '../util/flags'
import {
  Bearer,
  Group,
  bearerIdsFromListToString,
  bearerIdsFromStringToList,
  getCachedGroups,
  setCachedGroups,
} from '../util/group'
import {
  bearerIdFromSubject,
  streamFromSubject,
  streamIdFromSubject,
} from '../util/map'
import { Stream, compareTriggerTimestamp } from '../util/stream'
import { NoEndToEndEncryptionPage } from './NoEndToEndEncryptionPage'

const useStyles = makeStyles({
  showMap: {
    '& .map': {
      visibility: 'visible',
      position: 'relative',
    },
    '& .outlet': {
      display: 'none',
    },
  },
  container: {
    display: 'flex',
    height: '100%',
  },
  sidebar: {
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    height: `calc(100vh - ${HEADER_HEIGHT})`,
    top: HEADER_HEIGHT,
    padding: `${tokens.spacingVerticalXL} ${tokens.spacingHorizontalS} ${tokens.spacingVerticalM}`,
    gap: tokens.spacingVerticalM,
    '@supports (height: 100dvh)': {
      height: `calc(100dvh - ${HEADER_HEIGHT})`,
    },
  },
  compressSidebarButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: tokens.spacingVerticalS,
  },
  noStreamsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBlock: tokens.spacingVerticalXL,
    color: tokens.colorNeutralForegroundDisabled,
  },
  streamsFilter: {
    visibility: 'visible',
    transition: 'opacity 500ms, visibility 500ms',
  },
  lists: {
    display: 'flex',
    visibility: 'visible',
    height: '100%',
    flexDirection: 'column',
    overflowY: 'auto',
    opacity: 1,
    transition: 'opacity 500ms, visibility 500ms',
  },
  sidebarCompressed: {
    '& .streamsFilter, & .lists': {
      visibility: 'hidden',
      opacity: 0,
    },
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    position: 'relative',
    boxShadow: tokens.shadow8,
    borderTopLeftRadius: tokens.borderRadiusXLarge,
    overflow: 'hidden',
  },
  outlet: {
    backgroundColor: tokens.colorNeutralBackground2,
    flexGrow: 1,
  },
})

type StreamsPageContextType = {
  streams: Stream[]
}

const INACTIVE_POSITION_TIMEOUT = 15_000

function StreamsPage() {
  const styles = useStyles()
  useRedirectIfNotLoggedIn()
  const [themeId] = useThemeId()
  const containerRef = useRef<HTMLDivElement>(null)
  const { status } = useAuth()
  const organizationId = useCurrentOrganization().id
  const { streamId = '', groupId = '', bearerIds = '' } = useParams()
  const [groups, setGroups] = useState<Map<string, Group>>(getCachedGroups())
  const navigate = useNavigate()
  const [keys, _setKeys] = useContentKeys()
  const { dispatchToast, dismissToast } = useToastController(TOASTER_ID)
  const [draggedStream, setDraggedStream] = useState<Stream | null>(null)
  const [showMap, setShowMap] = useState(
    localStorage.getItem('showMap') !== 'false'
  )

  const [hoveredBearers, setHoveredBearers] = useState<string[]>([])
  const [hoveredStreamId, setHoveredStreamId] = useState<string | null>(null)
  const sidebarRef = useRef<HTMLDivElement>(null)

  const { client: notificationsClient } = useNotificationsClient()
  const streams = useStreams({
    organizationId,
    webPubClient: notificationsClient,
  })

  useStreamToasts({ streams })

  const [hadError, setHadError] = useState(false)
  const { t } = useTranslation('streams')

  // Sidebar
  const {
    showSidebar,
    handleSidebarResize,
    handleToggleSidebar,
    sidebarAnimationStyle,
  } = useSidebar()

  const selectedBearers = useMemo(() => {
    let selectedBearers: string[] = []

    if (streamId) {
      const bearerId = streams?.find(({ id }) => id === streamId)?.bearerId

      selectedBearers = bearerId ? [bearerId] : []
    } else if (bearerIds) {
      selectedBearers = bearerIdsFromStringToList(bearerIds)
    }

    return selectedBearers
  }, [streamId, bearerIds, streams])

  // Reset hover on showMap toggle
  useEffect(() => setHoveredBearers([]), [showMap])

  // Filter streams
  const {
    filter,
    handleFilter,
    search,
    handleSearch,
    filteredStreams,
    filteredGroups,
    streamsInFilteredGroups,
  } = useStreamsFilter(streams, groups)

  const filteredGroupsWithTriggerTimestamp = filteredGroups.map(
    groupTriggerTimestampMapper
  )

  const [
    filteredStreamsAndStreamsInFilteredGroups,
    setFilteredStreamsAndStreamsInFilteredGroups,
  ] = useState<Stream[]>([])
  useEffect(
    () =>
      setFilteredStreamsAndStreamsInFilteredGroups([
        ...filteredStreams,
        ...streamsInFilteredGroups,
      ]),
    [filteredStreams, streamsInFilteredGroups]
  )

  // Positions
  const { positions, positionHistories, error } = usePositions(
    filteredStreamsAndStreamsInFilteredGroups
  )

  useEffect(() => {
    if (error) {
      setHadError(true)
      dispatchToast(
        <PositionsWebSocketToast
          title={t('degraded-experience')}
          body={t('connection-lost-positions')}
          dismissible={true}
          testId={'positionsWebsocketErrorToast'}
        />,
        {
          timeout: -1,
          intent: 'warning',
          position: 'bottom-end',
          toastId: 'positionsWebsocketError',
        }
      )
    } else if (hadError) {
      setHadError(false)
      // In case it manages to connect, without the error having been dismissed
      dismissToast('positionsWebsocketError')
      dispatchToast(
        <PositionsWebSocketToast
          title={t('connection-reestablished')}
          body={t('now-receiving-positions')}
          testId={'positionsWebsocketSuccessToast'}
        />,
        {
          intent: 'success',
          position: 'bottom-end',
          toastId: 'positionsWebsocketSuccess',
        }
      )
    }
  }, [dismissToast, dispatchToast, error, hadError, t])

  // Track inactive bearers
  const inactiveThreshold =
    Number(useFlag(Flags.INACTIVE_POSITION_THRESHOLD)?.value) ||
    INACTIVE_POSITION_TIMEOUT
  const inactiveBearers = useInactiveBearers(positions, inactiveThreshold)

  if (status === AuthStatus.Uninitialized || !streams) {
    return <LoadingPage />
  }

  if (!keys.length) {
    return <NoEndToEndEncryptionPage />
  }

  const noStreamsOrGroups =
    streams.filter((stream) => stream.ongoing).length === 0 && groups.size === 0

  const containerClassName = () => {
    let classes = styles.container
    if (showMap) {
      classes = mergeClasses(classes, styles.showMap)
    }
    if (!showSidebar) {
      classes = mergeClasses(classes, styles.sidebarCompressed)
    }

    return classes
  }

  const isFullscreen =
    document.fullscreenElement !== null &&
    document.fullscreenElement === containerRef.current

  return (
    <div className={containerClassName()}>
      <animated.div
        ref={sidebarRef}
        className={mergeClasses(styles.sidebar, 'sidebar')}
        style={sidebarAnimationStyle}
      >
        <>
          {showSidebar && <SidebarResizer onResize={handleSidebarResize} />}

          <Button
            className={styles.compressSidebarButton}
            appearance="subtle"
            icon={
              showSidebar ? (
                <NavigationContract20Regular />
              ) : (
                <NavigationExpand20Regular />
              )
            }
            onClick={handleToggleSidebar}
          />
        </>
        <div className={mergeClasses(styles.streamsFilter, 'streamsFilter')}>
          <StreamsFilter
            filter={filter}
            onFilter={handleFilter}
            search={search}
            onSearch={handleSearch}
          />
        </div>
        <div className={mergeClasses(styles.lists, 'lists')}>
          {noStreamsOrGroups ? (
            <div className={styles.noStreamsContainer}>
              {themeId === 'light' ? (
                <NoContentLight width={120} />
              ) : (
                <NoContentDark width={120} />
              )}
              <Body2>No active streams</Body2>
            </div>
          ) : (
            <StreamList
              streams={filteredStreams}
              positions={positions}
              groups={filteredGroupsWithTriggerTimestamp}
              selectedGroupId={groupId}
              hoveredStreamId={hoveredStreamId}
              onGroupMapButtonClick={HandleGroupMapButtonClick}
              onGroupClick={handleGroupClick}
              onGroupPointerOver={handleGroupPointerOver}
              onEditGroup={handleEditGroup}
              onRemoveGroup={handleRemoveGroup}
              onStreamMapButtonClick={HandleStreamMapButtonClick}
              onStreamClick={handleStreamClick}
              onStreamPointerOver={handleStreamPointerOver}
              onStreamDrag={handleStreamDrag}
              onGroupDrop={handleDropOnGroupItem}
              onStreamDrop={handleDropOnStreamItem}
              onPointerLeave={handlePointerLeaveStreamList}
            />
          )}
        </div>
        <CreateGroupDialog
          streams={streams}
          onCreateGroup={handleCreateGroup}
        />
      </animated.div>
      <div className={styles.content} ref={containerRef}>
        <StreamsHeader
          onFullscreen={() => handleFullscreen(containerRef.current)}
          onToggleMap={handleToggleMap}
          showMap={showMap}
          isFullscreen={isFullscreen}
        />
        <div
          className={mergeClasses(styles.outlet, 'outlet')}
          onDrop={handleDropOnOutlet}
          onDragOver={(event) => event.preventDefault()}
        >
          <Outlet
            context={
              {
                streams,
              } satisfies StreamsPageContextType
            }
          />
        </div>
        <MapViewer
          streams={filteredStreamsAndStreamsInFilteredGroups}
          positions={positions}
          positionHistories={positionHistories}
          selectedBearers={selectedBearers}
          hoveredBearers={hoveredBearers}
          inactiveBearers={inactiveBearers}
          showMap={showMap}
          onPositionClick={handlePositionClick}
          onPositionHover={handlePositionHover}
          onDrop={handleDropOnOutlet}
          onFullscreen={() => handleFullscreen(containerRef.current)}
          container={containerRef.current}
        />
      </div>
    </div>
  )

  function handleToggleMap() {
    const negatedShowMap = !showMap
    localStorage.setItem('showMap', negatedShowMap.toString())
    setShowMap(negatedShowMap)
  }

  function handlePointerLeaveStreamList() {
    setHoveredBearers([])
  }

  function HandleStreamMapButtonClick() {
    setShowMap(true)
  }

  function handleStreamClick(event: React.MouseEvent<HTMLAnchorElement>) {
    // Prevent click on stream toolbar to trigger stream click.
    const target = event.target as HTMLAnchorElement

    if (target.id === 'streamItem') {
      setShowMap(false)
    }
  }

  function handleStreamPointerOver(stream: Stream) {
    setHoveredBearers([stream.bearerId])
  }

  function handleStreamDrag(
    event: DragEvent<HTMLAnchorElement>,
    stream: Stream
  ) {
    event.preventDefault()
    setDraggedStream(stream)
  }

  function handleDropOnStreamItem(stream: Stream) {
    draggedStream && createGroup(stream, draggedStream)
  }

  function handleDropOnGroupItem(groupId: string) {
    draggedStream && addToGroup(draggedStream, groupId)
  }

  function handleDropOnOutlet() {
    if (!draggedStream) {
      return
    }

    // A group should be created if dropping a stream from the list onto another
    // stream currently in view.
    if (streamId) {
      const currentStream = streams?.find((stream) => stream.id === streamId)
      currentStream && createGroup(currentStream, draggedStream)
    } else if (groupId) {
      addToGroup(draggedStream, groupId)
    } else {
      navigate(`/streams/${draggedStream.id}`)
    }
  }

  function createGroup(currentStream: Stream, draggedStream: Stream) {
    if (draggedStream.id === currentStream.id) {
      dispatchToast(
        <Toast>
          <ToastTitle>{t('create-group-no-duplicate-users')}</ToastTitle>
        </Toast>,
        { intent: 'error' }
      )
      setDraggedStream(null)
      return
    }

    const updatedGroups = getCachedGroups()
    const groupId = crypto.randomUUID()
    const user1 = currentStream.metadata.bearerName ?? 'N/A'
    const user2 = draggedStream.metadata.bearerName ?? 'N/A'
    dispatchToast(
      <Toast>
        <ToastTitle>
          {t('created-new-group-containing-user1-and-user2', { user1, user2 })}
        </ToastTitle>
      </Toast>,
      { intent: 'info' }
    )

    const bearers = new Map()
    bearers.set(currentStream.bearerId, {
      id: currentStream.bearerId || '',
      name: currentStream.metadata?.bearerName || '',
    })
    bearers.set(draggedStream.bearerId, {
      id: draggedStream.bearerId || '',
      name: draggedStream.metadata?.bearerName || '',
    })
    updatedGroups.set(groupId, {
      id: groupId,
      bearers: bearers,
    })
    setAndCacheGroups(updatedGroups)
    setDraggedStream(null)
    navigate(
      `/streams/group/${groupId}/${currentStream.bearerId};${draggedStream.bearerId}`
    )
  }

  function addToGroup(draggedStream: Stream, targetGroupId: string) {
    const group = getCachedGroups().get(targetGroupId)

    if (!group) {
      return
    }

    if (group.bearers.has(draggedStream.bearerId)) {
      dispatchToast(
        <Toast>
          <ToastTitle>{t('user-already-in-group')}</ToastTitle>
        </Toast>,
        { intent: 'error' }
      )
      setDraggedStream(null)
      return
    }

    if (group.bearers.size >= MAX_GROUP_MEMBER_COUNT) {
      dispatchToast(
        <Toast>
          <ToastTitle>{t('group-full')}</ToastTitle>
        </Toast>,
        { intent: 'error' }
      )
      setDraggedStream(null)
      return
    }

    const updatedGroups = getCachedGroups()
    updatedGroups.get(targetGroupId)?.bearers.set(draggedStream.bearerId, {
      id: draggedStream.bearerId || '',
      name: draggedStream.metadata?.bearerName || '',
    })
    setAndCacheGroups(updatedGroups)

    let toastTitle = t('added-user-to-group')
    const bearerName = draggedStream.metadata.bearerName

    if (bearerName && group.name) {
      toastTitle = t('added-to-group', { groupName: group.name, bearerName })
    }

    dispatchToast(
      <Toast>
        <ToastTitle>{toastTitle}</ToastTitle>
      </Toast>,
      { intent: 'info' }
    )
    setDraggedStream(null)

    if (targetGroupId === groupId) {
      const bearerIds = [
        ...(updatedGroups.get(targetGroupId)?.bearers.keys() || []),
      ]
      const bearerIdsParam = bearerIdsFromListToString(bearerIds)
      navigate(`/streams/group/${targetGroupId}/${bearerIdsParam}`)
    }
  }

  function handlePositionClick(subject: string) {
    // Since a camera user's position only exist while streaming, 'streams' and
    // 'stream' will always exist on position click.
    const stream = streamFromSubject(subject, streams || [])
    if (!stream) {
      return
    }
    navigate(`/streams/${stream.id}`)
    setShowMap(false)
  }

  function handlePositionHover(subject?: string | string[]) {
    if (!subject) {
      setHoveredBearers([])
      setHoveredStreamId(null)
      return
    }

    // Hovering cluster
    if (Array.isArray(subject)) {
      setHoveredBearers(subject.map(bearerIdFromSubject))
      // Ignore setting streamId for cluster hovering for now
      return
    }

    const bearerId = bearerIdFromSubject(subject)
    setHoveredBearers([bearerId])
    // Since a camera user's position only exist while streaming, 'streams' and
    // 'streamId' will always exist on position hover.
    const streamId = streamIdFromSubject(subject, streams || [])
    setHoveredStreamId(streamId || null)
  }

  function HandleGroupMapButtonClick(groupId: string) {
    setShowMap(true)
    const bearers = groups.get(groupId)?.bearers
    if (!bearers || bearers.size === 0) {
      dispatchToast(
        <Toast>
          <ToastTitle>{t('cant-select-empty-group')}</ToastTitle>
        </Toast>,
        { intent: 'error' }
      )
      return
    }

    const bearerIds = [...(bearers.keys() || [])]
    const bearerIdsParam = bearerIdsFromListToString(bearerIds)
    navigate(`/streams/group/${groupId}/${bearerIdsParam}`)
  }

  function handleGroupClick(
    event: React.MouseEvent<HTMLDivElement>,
    groupId: string
  ) {
    // Prevent click on group toolbar to trigger group click.
    const target = event.target as HTMLDivElement

    if (target.id === 'groupItem') {
      setShowMap(false)
      const bearers = groups.get(groupId)?.bearers
      const bearerIds = [...(bearers?.keys() || [])]
      const bearerIdsParam = bearerIdsFromListToString(bearerIds)
      navigate(`/streams/group/${groupId}/${bearerIdsParam}`)
    }
  }

  function handleGroupPointerOver(group: Group) {
    setHoveredBearers([...group.bearers.keys()])
  }

  function handleEditGroup(group: Group) {
    const updatedGroups = getCachedGroups()
    updatedGroups.set(group.id, {
      id: group.id,
      name: group.name || '',
      bearers: group.bearers,
    })
    setAndCacheGroups(updatedGroups)
    if (group.id === groupId) {
      const bearerIds = [...group.bearers.keys()]
      const bearerIdsParam = bearerIdsFromListToString(bearerIds)
      navigate(`/streams/group/${groupId}/${bearerIdsParam}`)
    }
  }

  function handleRemoveGroup(group: Group) {
    const updatedGroups = getCachedGroups()
    if (group.id === groupId) {
      navigate('/streams')
    }
    setHoveredBearers([])
    updatedGroups.delete(group.id)
    setAndCacheGroups(updatedGroups)
  }

  function handleCreateGroup(bearers: Bearer[], name?: string) {
    const updatedGroups = getCachedGroups()
    const groupId = crypto.randomUUID()

    updatedGroups.set(groupId, {
      id: groupId,
      name: name,
      bearers: new Map(),
    })

    bearers.forEach((bearer) => {
      updatedGroups.get(groupId)?.bearers.set(bearer.id, {
        id: bearer.id,
        name: bearer.name,
      })
    })

    setAndCacheGroups(updatedGroups)
  }

  function setAndCacheGroups(groups: Map<string, Group>) {
    setGroups(groups)
    setCachedGroups(groups)
  }

  // Add triggerTimestamp to group based on the timestamp of last triggered group
  // stream, or undefined if no ongoing group stream exist.
  function groupTriggerTimestampMapper(group: Group) {
    const groupStreams =
      streams?.filter(
        (stream) =>
          group.bearers.get(stream.bearerId) !== undefined && stream.ongoing
      ) ?? []
    groupStreams.sort(compareTriggerTimestamp)

    return {
      ...group,
      triggerTimestamp: groupStreams[0]?.metadata.triggerTimestamp,
    }
  }
}

function handleFullscreen(element: HTMLDivElement | null) {
  if (!element) {
    return
  }

  if (!document.fullscreenElement) {
    element.requestFullscreen().catch((err) => {
      alert(
        `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
      )
    })
  } else {
    document.exitFullscreen()
  }
}

export function useStreamsPageContext() {
  return useOutletContext<StreamsPageContextType>()
}

export { StreamsPage }

import { axisDarkTheme, axisLightTheme } from '@axiscommunications/fluent-theme'
import { FluentProvider, Theme as ReactTheme } from '@fluentui/react-components'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

type ThemeProviderProps = {
  readonly cache: Pick<Storage, 'getItem' | 'setItem'>
}

export type ThemeId = 'dark' | 'light'

const themes: Record<ThemeId, Theme> = {
  dark: {
    theme: axisDarkTheme,
    label: 'Dark',
  },
  light: {
    theme: axisLightTheme,
    label: 'Light',
  },
}

export type Theme = {
  theme: ReactTheme
  label: string
}

type ThemeContextProps = {
  themes: Record<ThemeId, Theme>
  themeId: ThemeId
  setThemeId: (id: ThemeId) => void
}

const CURRENT_THEME_KEY = 'theme'
const ThemeContext = createContext<ThemeContextProps>({} as ThemeContextProps)

/**
 * Provide a list of supported themes and the currently selected theme ID with a
 * function to change the selected theme to a new one.
 */
export function ThemeProvider({
  cache,
  children,
}: PropsWithChildren<ThemeProviderProps>) {
  const [theme, setTheme] = useState<ThemeId>(() => {
    const currentTheme = cache.getItem(CURRENT_THEME_KEY)

    if (currentTheme && currentTheme in themes) {
      return currentTheme as ThemeId
    }

    return 'dark'
  })

  const setAndCacheThemeId = useCallback(
    (id: ThemeId) => {
      cache.setItem(CURRENT_THEME_KEY, id)
      setTheme(id)
    },
    [cache]
  )

  const themeState: ThemeContextProps = {
    themes: themes,
    themeId: theme,
    setThemeId: setAndCacheThemeId,
  }

  return (
    <ThemeContext.Provider value={themeState}>
      <FluentProvider theme={themes[theme].theme}>{children}</FluentProvider>
    </ThemeContext.Provider>
  )
}

/**
 * Get available themes.
 */
export function useThemes(): Record<ThemeId, Theme> {
  const { themes } = useContext(ThemeContext)

  if (themes === undefined) {
    throw new Error('useThemes must be used within a ThemeProvider')
  }

  return themes
}

/**
 * Get an array where the first element is the selected theme ID and the
 * second a function to set the ID and also save it to local storage.
 */
export function useThemeId(): [ThemeId, (id: ThemeId) => void] {
  const { themeId, setThemeId } = useContext(ThemeContext)

  return [themeId, setThemeId]
}

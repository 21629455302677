import { MediaDark, MediaLight } from '@axiscommunications/fluent-illustrations'
import { Button, makeStyles, tokens } from '@fluentui/react-components'
import { Dismiss20Regular } from '@fluentui/react-icons'

import { useThemeId } from '../../providers/ThemeProvider'
import { Illustration } from '../Illustration'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: tokens.colorStrokeFocus1,
  },
  dismissButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: tokens.spacingHorizontalS,
  },
  card: {
    padding: tokens.spacingVerticalXXXL,
  },
})

type StreamEndedProps = {
  streamId: string
  onViewRecording: (streamId: string) => void
  onDismiss: (streamId: string) => void
}

export function StreamEnded({
  streamId,
  onViewRecording,
  onDismiss,
}: StreamEndedProps) {
  const styles = useStyles()
  const [themeId] = useThemeId()

  return (
    <div className={styles.container}>
      <Button
        appearance="outline"
        className={styles.dismissButton}
        icon={<Dismiss20Regular />}
        onClick={() => onDismiss(streamId)}
      />

      <Illustration
        title="Stream ended"
        button={{
          text: 'View recording',
          onClick: () => onViewRecording(streamId),
        }}
        illustration={themeId === 'light' ? MediaLight : MediaDark}
      />
    </div>
  )
}

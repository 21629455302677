import { MapType } from '@axteams-one/bws-cloud-maps'

import { Stream } from './stream'

export function bearerIdFromSubject(subject: string) {
  return subject.split('/')[1] || ''
}

export function subjectFromBearerId(bearerId: string) {
  return `Bearer/${bearerId}`
}

export function streamIdFromSubject(subject: string, streams: Stream[]) {
  const bearerId = bearerIdFromSubject(subject)
  const streamId = streams.find((stream) => stream.bearerId === bearerId)?.id

  return streamId
}

export function streamFromSubject(subject: string, streams: Stream[]) {
  const bearerId = bearerIdFromSubject(subject)
  const stream = streams.find((stream) => stream.bearerId === bearerId)

  return stream
}

// Retrieve the selected mapType from browser local storage.
export function getCachedMapType() {
  const mapType = localStorage.getItem('mapType')

  if (!mapType) {
    return null
  }

  try {
    return JSON.parse(mapType) as MapType
  } catch (error) {
    console.warn('Failed parsing cached map type')
  }
  return null
}

// Store the selected mapTyp in browser local storage.
export function setCachedMapType(mapType: MapType) {
  try {
    localStorage.setItem('mapType', JSON.stringify(mapType))
  } catch (error) {
    console.warn('Failed saving cached map type')
  }
}

import { useLocalStorage } from 'usehooks-ts'

export type TailsVisibility = 'all' | 'selected' | 'none'

export default function useTails() {
  const [tailsVisibility, setTailsVisibility] =
    useLocalStorage<TailsVisibility>('tailsVisibility', 'none')

  function handleTailsVisibilityChange(tailsVisibility: TailsVisibility) {
    setTailsVisibility(tailsVisibility)
  }

  return { tailsVisibility, handleTailsVisibilityChange }
}

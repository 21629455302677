import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  MessageBar,
  MessageBarBody,
  Text,
  Tooltip,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { Delete20Regular } from '@fluentui/react-icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalM,
  },
  dialogActions: {
    justifyContent: 'end',
  },
})

type RemoveDialogProps = {
  onDeleteKeys: () => void
}

export function DeleteKeysDialog({ onDeleteKeys }: RemoveDialogProps) {
  const styles = useStyles()
  const { t } = useTranslation('settings-encryption')

  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <Tooltip
          content={t('delete-keys-dialog--tooltip')}
          relationship="label"
        >
          <Button icon={<Delete20Regular />} appearance="subtle" />
        </Tooltip>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody className={styles.dialogBody}>
          <DialogTitle>{t('delete-keys-dialog--delete-keys')}</DialogTitle>
          <DialogContent className={styles.dialogContent}>
            <MessageBar intent="warning">
              <MessageBarBody>
                {t('delete-keys-dialog--warning')}
              </MessageBarBody>
            </MessageBar>
            <Text>{t('delete-keys-dialog--confirmation')}</Text>
          </DialogContent>
          <DialogActions className={styles.dialogActions}>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">
                {t('common:action--cancel')}
              </Button>
            </DialogTrigger>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="primary" onClick={onDeleteKeys}>
                {t('common:action--delete')}
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

import { easeOutCubic } from '@axteams-one/bws-cloud-maps/easings'
import { Position } from '@axteams-one/bws-cloud-maps/layers/tracking'
import { useInterpolatePositionHistories } from '@axteams-one/bws-cloud-maps/react/useInterpolatePositionHistories'
import { useInterpolatePositions } from '@axteams-one/bws-cloud-maps/react/useInterpolatePositions'
import { PositionHistory } from '@axteams-one/bws-cloud-maps/react/useTrailsLayer'
import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { useEffect, useState } from 'react'

import useMap from '../../hooks/useMap'
import useMapType from '../../hooks/useMapType'
import useTailsVisibility from '../../hooks/useTailsVisibility'
import { Stream } from '../../util/stream'
import Labels from './Labels'
import { MapControls } from './MapControls'

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    display: 'flex',
    height: '100%',
    width: '100%',
    visibility: 'hidden',
  },
  map: {
    display: 'flex',
    flexGrow: 1,
    // Add radius to map div larger than radius of parent to prevent unexpected
    // map radius.
    '> div': {
      borderTopLeftRadius: '20px',
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
  },
})

type MapProps = {
  streams: Stream[]
  positions: Position[]
  positionHistories: Map<string, PositionHistory>
  selectedBearers: string[]
  hoveredBearers: string[]
  inactiveBearers: string[]
  showMap: boolean
  onPositionClick: (subject: string) => void
  onPositionHover: (subject?: string | string[]) => void
  onDrop: () => void
  onFullscreen: () => void
  container: HTMLDivElement | null
}

export function Map({
  streams,
  positions,
  positionHistories,
  selectedBearers,
  hoveredBearers,
  inactiveBearers,
  showMap,
  onPositionClick,
  onPositionHover,
  onDrop,
  onFullscreen,
  container,
}: MapProps) {
  const styles = useStyles()
  const [frameAll, setFrameAll] = useState(false)

  const { mapType, handleMapTypeChange } = useMapType()
  const { tailsVisibility, handleTailsVisibilityChange } = useTailsVisibility()

  const interpolatedPositions = useInterpolatePositions({
    positions,
    easing: easeOutCubic,
    duration: 4000,
  })
  const interpolatedPositionHistories = useInterpolatePositionHistories({
    positionHistories,
    interpolatedPositions,
  })

  const {
    mapElementRef,
    map,
    showLabels,
    individualPositions,
    bounds,
    projection,
    zoom,
  } = useMap({
    streams,
    interpolatedPositions,
    interpolatedPositionHistories,
    selectedBearers,
    hoveredBearers,
    inactiveBearers,
    mapType,
    tailsVisibility,
    frameAll,
    onPositionClick,
    onPositionHover,
  })

  useEffect(() => {
    setFrameAll(false)
  }, [frameAll])

  return (
    <div
      onDrop={onDrop}
      onDragOver={(event) => event.preventDefault()}
      className={mergeClasses(styles.container, 'map')}
    >
      <div className={styles.content}>
        <div ref={mapElementRef} className={styles.map} />
        {map && showMap && showLabels && (
          <Labels
            bounds={bounds}
            projection={projection}
            zoom={zoom}
            positions={individualPositions}
            streams={streams}
            inactiveBearers={inactiveBearers}
            selectedBearers={selectedBearers}
            hoveredBearers={hoveredBearers}
            onClick={onPositionClick}
            onHover={onPositionHover}
          />
        )}
      </div>
      <MapControls
        mapType={mapType}
        tailsVisibility={tailsVisibility}
        zoom={zoom}
        container={container}
        onFullscreen={onFullscreen}
        onFrameAll={handleFrameAll}
        onMapTypeChange={handleMapTypeChange}
        onTailsVisibilityChange={handleTailsVisibilityChange}
        onZoomChange={handleZoomChange}
      />
    </div>
  )

  function handleFrameAll() {
    setFrameAll(true)
  }

  function handleZoomChange(zoom: number) {
    map?.map.setZoom(zoom)
  }
}

import { Button, Card, Tooltip, makeStyles } from '@fluentui/react-components'
import { Temporal } from '@js-temporal/polyfill'

import { gpsStringToGeoLocationPosition } from '../../util/gps'
import { PlayerState } from './VideoPlayer'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    overFlowX: 'auto',
    paddingBlockEnd: '80px',
    overflowY: 'auto',
    '& .fui-Card': {
      marginBottom: '5px',
      opacity: 0.9,
    },
    '& dl': {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr',
    },
    '& dd': {
      fontFamily: 'monospace',
    },
    '& details dl': {
      marginBlockEnd: '10px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    width: 'fit-content',
    alignItems: 'stretch',
  },
})

export interface VideoDebugData {
  media: {
    streamId?: string
    organizationId?: string
    storageLocationId?: string
    bearerId?: string
    encrypted?: boolean
  }
  timing: {
    delta?: number
    synced?: boolean
  }
  positioning?: GeolocationPosition
  playerState: PlayerState
}

export interface VideoDebugCardProps {
  data: VideoDebugData
}

interface Position {
  latitude: number
  longitude: number
  eph?: number | null
  heading?: number | null
  transferTimeDelta?: number
  positionTimeDelta?: number
}

export function VideoDebugCard(props: VideoDebugCardProps): JSX.Element {
  const classes = useStyles()

  const trail = allMetadataToTrail(props.data.playerState.allMetadata)

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Card>
          <p>Media</p>
          <dl>
            <dt>Stream ID</dt>
            <dd>{props.data.media.streamId}</dd>

            <dt>Organization ID</dt>
            <dd>{props.data.media.organizationId}</dd>

            <dt>Bearer ID</dt>
            <dd>{props.data.media.bearerId}</dd>

            <dt>Encrypted</dt>
            <dd>{props.data.media.encrypted ? 'yes' : 'no'}</dd>

            <dt>Storage Location ID</dt>
            <dd>{props.data.media.storageLocationId || 'Unknown'}</dd>
          </dl>
        </Card>
        <Card>
          <p>Timing</p>
          <dl>
            <dt>Synced</dt>
            <dd>{props.data.timing.synced === true ? 'yes' : 'no'}</dd>

            <dt>Client Time</dt>
            <dd>{Temporal.Now.instant().toString()}</dd>

            <dt>Server Time</dt>
            <dd>
              {Temporal.Now.instant()
                .add({ milliseconds: Math.round(props.data.timing.delta || 0) })
                .toString()}
            </dd>

            <dt>Clock Delta</dt>
            <dd>{props.data.timing.delta}ms</dd>
          </dl>
        </Card>
        <Card>
          <p>Positioning</p>
          <dl>
            <dt>Timestamp</dt>
            <dd>
              {props.data.positioning?.timestamp
                ? Temporal.Instant.fromEpochMilliseconds(
                    props.data.positioning.timestamp * 1000
                  ).toString()
                : 'N/A'}
            </dd>
            <dt>Latitude</dt>
            <dd>{props.data.positioning?.coords.latitude || 'N/A'}</dd>
            <dt>Longitude</dt>
            <dd>{props.data.positioning?.coords.longitude || 'N/A'}</dd>
            <dt>Accuracy</dt>
            <dd>{props.data.positioning?.coords.accuracy || 'N/A'}</dd>
            <dt>Altitude</dt>
            <dd>{props.data.positioning?.coords.altitude || 'N/A'}</dd>
            <dt>Altitude Accuracy</dt>
            <dd>{props.data.positioning?.coords.altitudeAccuracy || 'N/A'}</dd>
            <dt>Heading</dt>
            <dd>{props.data.positioning?.coords.heading || 'N/A'}</dd>
            <dt>Speed</dt>
            <dd>{props.data.positioning?.coords.speed || 'N/A'}</dd>
          </dl>
        </Card>
        <Card>
          <p>Player</p>
          <dl>
            <dt>State</dt>
            <dd>
              {props.data.playerState.status} (
              {props.data.playerState.lastStateChange
                ? Math.round(
                    (Temporal.Now.instant().epochMilliseconds -
                      props.data.playerState.lastStateChange) /
                      1000
                  )
                : 0}
              s ago)
            </dd>

            <dt>Media Type</dt>
            <dd>{props.data.playerState.isRecording ? 'Recording' : 'Live'}</dd>

            {/* <dt>Restarts</dt>
            <dd>{props.data.playerState.restarts}</dd> */}

            <dt>Video Bitrate</dt>
            <dd>{props.data.playerState.videoBitrate}</dd>

            <dt>Audio Bitrate</dt>
            <dd>{props.data.playerState.audioBitrate}</dd>

            <dt>Position</dt>
            <dd>{props.data.playerState.position}</dd>
            <dt>Presented Position</dt>
            <dd>{props.data.playerState.presentedPosition}</dd>

            <dt>Duration</dt>
            <dd>{props.data.playerState.duration}</dd>
            <dt>Presented Duration</dt>
            <dd>{props.data.playerState.presentedDuration}</dd>

            <dt>Live Gap</dt>
            <dd>{props.data.playerState.liveGap || 'N/A'}</dd>
            <dt>Presented Live Gap</dt>
            <dd>{props.data.playerState.presentedLiveGap || 'N/A'}</dd>

            <dt>Buffer Gap</dt>
            <dd>{props.data.playerState.bufferGap}</dd>
            <dt>Presented Buffer Gap</dt>
            <dd>{props.data.playerState.presentedBufferGap}</dd>

            <dt>Wall Clock Time</dt>
            <dd>{props.data.playerState.wallClockTime || 'N/A'}</dd>

            <dt>Playback Rate</dt>
            <dd>{props.data.playerState.playbackRate}</dd>

            <dt>
              <p>Warnings</p>
            </dt>
            <dd>
              <pre>
                <code>
                  {props.data.playerState.warnings.length === 0
                    ? 'none'
                    : props.data.playerState.warnings.join('\n')}
                </code>
              </pre>
            </dd>

            <dt>Errors</dt>
            <dd>
              <pre>
                <code>
                  {props.data.playerState.errors.length === 0
                    ? 'none'
                    : props.data.playerState.errors.join('\n')}
                </code>
              </pre>
            </dd>
          </dl>
        </Card>
        <Card>
          <p>All positions</p>
          <Tooltip
            relationship="description"
            content="Download trail from positions of video segments that have been fetched. Missing positions, e.g. from missing segments, are not included in the trail."
          >
            <Button appearance="secondary" onClick={() => downloadTrail(trail)}>
              Download trail
            </Button>
          </Tooltip>
          <details>
            <summary>Positions</summary>
            {allPositionsMapper(props.data.playerState.allMetadata)}
          </details>
        </Card>
      </div>
    </div>
  )
}

function allPositionsMapper(
  allMetadata: Record<number, Record<string, string>>
) {
  return Object.entries(allMetadata).map(([key, metadata]) => {
    const positioning = gpsStringToGeoLocationPosition(metadata['gps'])
    if (!positioning) {
      return
    }

    return (
      <dl key={key}>
        <dt>Timestamp</dt>
        <dd>
          {positioning?.timestamp
            ? new Date(positioning.timestamp * 1000).toISOString()
            : 'N/A'}
        </dd>
        <dt>Latitude</dt>
        <dd>{positioning?.coords.latitude || 'N/A'}</dd>
        <dt>Longitude</dt>
        <dd>{positioning?.coords.longitude || 'N/A'}</dd>
        <dt>Accuracy</dt>
        <dd>{positioning?.coords.accuracy || 'N/A'}</dd>
        <dt>Altitude</dt>
        <dd>{positioning?.coords.altitude || 'N/A'}</dd>
        <dt>Altitude Accuracy</dt>
        <dd>{positioning?.coords.altitudeAccuracy || 'N/A'}</dd>
        <dt>Heading</dt>
        <dd>{positioning?.coords.heading || 'N/A'}</dd>
        <dt>Speed</dt>
        <dd>{positioning?.coords.speed || 'N/A'}</dd>
      </dl>
    )
  })
}

/**
 * Create a JSON trail from playerState property 'allMetadata'. The trail can be
 * used with the Fejka User Walk command.
 */
function allMetadataToTrail(
  allMetadata: Record<number, Record<string, string>>
): Position[] {
  const trail: Position[] = []

  let previousLatitude,
    previousLongitude,
    previousTimestamp = 0

  const sortedAllMetaData = Object.values(allMetadata)
    .map((metadata) => gpsStringToGeoLocationPosition(metadata['gps']))
    .sort(compareGeoLocationPositionsOnDescendingTimestamp)

  for (const metadata of sortedAllMetaData) {
    if (!metadata) {
      continue
    }

    // Convert timestamp from seconds to nano seconds since this is the
    // unit of the fejka trail properties 'positionTimeDelta' and
    // 'TransferTimeDelta'.
    const metaDataNanoSeconds = Math.round(metadata.timestamp * 10 ** 9)

    // Ignore duplicate positions. The latest known camera position will be
    // re-sent with each new video segment until a newer position exist.
    if (
      previousLatitude === metadata.coords.latitude &&
      previousLongitude === metadata.coords.longitude &&
      previousTimestamp === metaDataNanoSeconds
    ) {
      continue
    }

    // First position, e.g. no previous timestamp, should have positionTimeDelta
    // 0.
    let positionTimeDelta = 0
    if (previousTimestamp) {
      positionTimeDelta =
        metaDataNanoSeconds - previousTimestamp > 0
          ? metaDataNanoSeconds - previousTimestamp
          : 0
    }

    trail.push({
      latitude: metadata.coords.latitude,
      longitude: metadata.coords.longitude,
      eph: metadata.coords.accuracy,
      heading: metadata.coords.heading,
      transferTimeDelta: positionTimeDelta,
      positionTimeDelta: positionTimeDelta,
    })

    previousLatitude = metadata.coords.latitude
    previousLongitude = metadata.coords.longitude
    previousTimestamp = metaDataNanoSeconds
  }

  return trail
}

async function downloadTrail(trail: Position[]) {
  const blob = new Blob([JSON.stringify(trail)], {
    type: 'application/json',
  })
  const blobURL = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = blobURL
  a.download = 'axis-body-worn-live-trail.json'
  a.click()
}

function compareGeoLocationPositionsOnDescendingTimestamp(
  a?: GeolocationPosition,
  b?: GeolocationPosition
) {
  return (a?.timestamp ?? 0) - (b?.timestamp ?? 0)
}

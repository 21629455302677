import { useEffect } from 'react'

export default function useKeyPress(
  keys: string[],
  onKeyDown: (event: KeyboardEvent) => void
) {
  useEffect(() => {
    const keydownHandler = (event: KeyboardEvent) => {
      if (/INPUT|SELECT|TEXTAREA/.test(document.activeElement?.tagName || '')) {
        return
      }

      if (keys.some((key) => event.code === key)) {
        onKeyDown(event)
      }
    }
    document.addEventListener('keydown', keydownHandler)

    return () => {
      document.removeEventListener('keydown', keydownHandler)
    }
  }, [keys, onKeyDown])
}

import { Position } from '@axteams-one/bws-cloud-maps/layers/tracking'
import { useCallback, useRef } from 'react'

import { MovingAverage } from '../util/MovingAverage'

export type PositionFilter = (
  subject: string,
  position: Position,
  deltaTimeSeconds: number
) => Position

const FILTER_TIMEOUT_SECONDS = 60
const MOVING_AVERAGE_SIZE = 10

export function useEphAverageFilter(): PositionFilter {
  const movingAverages = useRef(new Map<string, MovingAverage>()).current

  return useCallback(
    (subject: string, position: Position, deltaTimeSeconds: number) => {
      if (
        !movingAverages.has(subject) ||
        deltaTimeSeconds > FILTER_TIMEOUT_SECONDS
      ) {
        movingAverages.set(subject, new MovingAverage(MOVING_AVERAGE_SIZE))
      }

      const movingAverage = movingAverages.get(subject)!
      const eph = movingAverage.update(position.eph)

      return {
        ...position,
        eph: Math.floor(eph),
      }
    },
    [movingAverages]
  )
}

import { useFlag } from '@axteams-one/bws-cloud-flags/react'
import { useTrackPageView } from '@axteams-one/bws-cloud-metrics/react'
import { Title2, makeStyles, tokens } from '@fluentui/react-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DisclaimSettings } from '../components/DisclaimSettings/DisclaimSettings'
import { EndToEndEncryptionSettings } from '../components/EndToEndEncryptionSettings/EndToEndEncryptionSettings'
import { LicenseSettings } from '../components/LicenseSettings/LicenseSettings'
import { StreamViewersSettings } from '../components/StreamViewersSettings/StreamViewersSettings'
import { WebhookSettings } from '../components/WebhookSettings/WebhookSettings'
import { HEADER_HEIGHT } from '../constants'
import { useCurrentOrganization } from '../providers/CurrentOrganizationProvider'
import { Flags } from '../util/flags'

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    paddingBottom: tokens.spacingVerticalXXXL,
  },
  header: {
    position: 'sticky',
    paddingTop: tokens.spacingVerticalXXXL,
    paddingBottom: tokens.spacingVerticalS,
    top: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 97,
    backgroundColor: tokens.colorNeutralBackground3,
    paddingInline: tokens.spacingHorizontalL,
  },
  title: {
    fontFamily: `'LubalinGraphECG-Demi', ${tokens.fontFamilyBase}`,
  },
  titleDot: {
    color: tokens.colorNeutralStrokeAccessibleSelected,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
    paddingInline: tokens.spacingHorizontalL,
  },
})

function SettingsPage() {
  useTrackPageView()

  const styles = useStyles()
  const { t } = useTranslation()
  const enableSocSettings = useFlag(Flags.SOC_SETTINGS)?.enabled === true
  const organizationId = useCurrentOrganization().id

  // Override the overflow style when the dialog opens to stop
  // the scrollbar and settingsPage title from getting pushed around.
  // This is a workaround to a problem caused by fluentUIs dialog
  // changing the HTML inline elementstyle to "overflow: hidden"
  // https://github.com/tailwindlabs/headlessui/discussions/2181
  // https://github.com/tailwindlabs/headlessui/discussions/2305
  useEffect(() => {
    document.documentElement.style.overflowY = 'visible'
    return () => {
      document.documentElement.style.overflowY = ''
    }
  }, [])

  return (
    <>
      <div id="page" className={styles.page}>
        <div className={styles.header}>
          <Title2 className={styles.title}>
            {t('common:settings')}
            <span className={styles.titleDot}>.</span>
          </Title2>
        </div>
        <div className={styles.content}>
          <LicenseSettings />
          <EndToEndEncryptionSettings />
          <StreamViewersSettings />
          {enableSocSettings && (
            <WebhookSettings organizationId={organizationId} />
          )}
          <DisclaimSettings />
        </div>
      </div>
    </>
  )
}

export { SettingsPage }

import {
  CreateWebhookEventSubscriptionDocument,
  CreateWebhookEventSubscriptionMutation,
  ErrorCode,
} from '../graphql/__generated__/graphql'
import { ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

type CreateWebhookEventSubscriptionsProps = {
  eventTypes: string[]
  resourceArn: string
  signingKey?: string
  url: string
}

export function useCreateWebhookEventSubscription() {
  const client = useUrqlClient(Api.Acx)

  return function ({
    eventTypes,
    resourceArn,
    signingKey,
    url,
  }: CreateWebhookEventSubscriptionsProps) {
    return client
      ?.mutation<CreateWebhookEventSubscriptionMutation>(
        CreateWebhookEventSubscriptionDocument,
        {
          input: {
            eventTypes,
            resourceArn,
            signingKey,
            url,
          },
        }
      )
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode =
            (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
            InternalErrorCode.NetworkError
          return {
            status: ApiStatus.Rejected,
            error: errorCode,
          }
        } else {
          return {
            status: ApiStatus.Resolved,
          }
        }
      })
  }
}

import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { useDrag } from '@use-gesture/react'
import { useState } from 'react'

const useStyles = makeStyles({
  container: {
    borderRight: `1px solid ${tokens.colorNeutralBackground3}`,
    width: '8px',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    cursor: 'col-resize',
    resize: 'horizontal',
    ':hover': {
      borderRightWidth: '3px',
      borderInlineEndColor: tokens.colorBrandBackground,
    },
    touchAction: 'none',
    transition: 'border 300ms',
  },
  active: {
    borderRightColor: tokens.colorBrandBackground,
  },
})

type SidebarResizerProps = {
  onResize: (deltaWidth: number, isResizing: boolean) => void
}

export default function SidebarResizer({ onResize }: SidebarResizerProps) {
  const styles = useStyles()

  const [isResizing, setIsResizing] = useState<boolean>(false)
  const bind = useDrag(({ down, movement: [x] }) => {
    setIsResizing(down)
    onResize(x, down)
  })

  return (
    <div
      {...bind()}
      className={mergeClasses(
        styles.container,
        'resizer',
        isResizing && styles.active
      )}
    />
  )
}

import { Temporal } from '@js-temporal/polyfill'

import { DashPlaylist } from '../graphql/__generated__/graphql'

export interface Stream {
  bearerId: string
  dash?: DashPlaylist | null
  duration?: Temporal.Duration | null
  id: string
  metadata: Metadata
  organizationId?: string
  ongoing: boolean
}

export interface Metadata {
  bearerName?: string
  bearerShowId?: string
  gps?: GeolocationPosition
  startTimestamp?: Temporal.ZonedDateTime
  triggerTimestamp?: Temporal.ZonedDateTime
}

/** Sort newest stream first */
export function compareTriggerTimestamp(a: Stream, b: Stream): number {
  // Prioritize valid trigger timestamp over undefined.
  if (!a.metadata.triggerTimestamp || !b.metadata.triggerTimestamp) {
    const aCompareValue = a.metadata.triggerTimestamp ? 1 : 0
    const bCompareValue = b.metadata.triggerTimestamp ? 1 : 0
    return bCompareValue - aCompareValue
  }
  return Temporal.ZonedDateTime.compare(
    b.metadata.triggerTimestamp,
    a.metadata.triggerTimestamp
  )
}

import { init } from '@axteams-one/bws-cloud-crypto/backend'
import { useEffect, useState } from 'react'

export function useCrypto() {
  const [cryptoModuleIsLoading, setCryptoModuleIsLoading] =
    useState<boolean>(true)

  // Load the cryptography module asynchronously.
  useEffect(() => {
    init()
      .then(() => setCryptoModuleIsLoading(false))
      .catch((e) => {
        setCryptoModuleIsLoading(false)
        // TODO(alexg): This should be a nice and descriptive alert shown in the UI in the near future
        console.error(
          'failed to initialize cryptography module, you will be unable to configure encryption settings or access encrypted streams',
          e
        )
      })
  }, [])

  return cryptoModuleIsLoading
}

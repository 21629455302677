/** Styling */
export const HEADER_HEIGHT = '46px'
export const DASHBOARD_SIDEBAR_MIN_WIDTH = 250 // in pixels
export const DASHBOARD_SIDEBAR_MAX_WIDTH = 500 // in pixels
export const DASHBOARD_SIDEBAR_COMPRESSED_WIDTH = 48 // in pixels

/** Groups */
export const MAX_GROUP_MEMBER_COUNT = 4

export const TOASTER_ID = 'toaster'

import { Temporal } from '@js-temporal/polyfill'
import { useEffect, useState } from 'react'

/**
 * The accepted age in milliseconds of a GPS coordinate before marking it as
 * greyed out.
 */
export const GPS_MAX_AGE = 30 * 1000 // 30s

export function useLastKnownLocation(
  position: number,
  geoLocationPosition?: GeolocationPosition,
  startTimestamp?: Temporal.ZonedDateTime
) {
  const [lastKnownLocation, setLastKnownLocation] = useState<
    GeolocationPosition | undefined
  >()
  const [lastKnownLocationAge, setLastKnownLocationAge] = useState<number>(-1)

  useEffect(() => {
    const currentPositionTimestamp =
      startTimestamp &&
      startTimestamp.add({ milliseconds: Math.floor(position * 1000) })
        .epochMilliseconds

    if (geoLocationPosition && currentPositionTimestamp) {
      setLastKnownLocation(geoLocationPosition)
      setLastKnownLocationAge(
        currentPositionTimestamp - geoLocationPosition.timestamp * 1000
      )
      return
    }

    const futureLastKnownLocation =
      !!currentPositionTimestamp &&
      !!lastKnownLocation?.timestamp &&
      lastKnownLocation?.timestamp * 1000 - currentPositionTimestamp >
        GPS_MAX_AGE

    let noLocationTimeout: ReturnType<typeof setTimeout>
    // Don't show last known location if scrubbing backwards to a position
    // without location data that is 'too far' (GPS_MAX_AGE) behind the last
    // known location. Showing a future location in this case is misleading.
    if (futureLastKnownLocation) {
      // Timeout to avoid race condition when retrieving gps data.
      noLocationTimeout = setTimeout(() => {
        setLastKnownLocation(undefined)
        clearTimeout(noLocationTimeout)
      }, 500)
    }

    return () => {
      clearTimeout(noLocationTimeout)
    }
  }, [geoLocationPosition, lastKnownLocation, position, startTimestamp])

  return { lastKnownLocation, lastKnownLocationAge }
}

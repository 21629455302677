import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogOpenChangeData,
  DialogOpenChangeEvent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Input,
  InputOnChangeData,
  Label,
  Text,
  makeStyles,
  tokens,
  useId,
} from '@fluentui/react-components'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface AddWebhookDialogData {
  url: string
  secret: string
}

type AddWebhookDialogProps = {
  onAdd: (data: AddWebhookDialogData) => void
  open: boolean
  onCancel: () => void
}

const useStyles = makeStyles({
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalS,
  },
  label: {
    width: 'fit-content',
  },
  input: {
    width: '360px',
  },
  summary: {
    cursor: 'pointer',
    paddingBlockEnd: tokens.spacingVerticalL,
  },
  dialogActions: {
    justifyContent: 'end',
  },
})

export function AddWebhookDialog({
  onAdd,
  open,
  onCancel,
}: AddWebhookDialogProps) {
  const styles = useStyles()
  const [url, setUrl] = useState<string>('')
  const [secret, setSecret] = useState<string>('')
  const urlId = useId('input-outline')
  const secretId = useId('input-outline')
  const { t } = useTranslation('settings-soc')

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogSurface>
        <form onSubmit={handleSubmit}>
          <DialogBody
            aria-describedby={undefined}
            className={styles.dialogBody}
          >
            <DialogTitle>{t('add-soc-integration')}</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <div className={styles.field}>
                <Text as="p" size={200}>
                  {t('add-soc-instructions')}
                </Text>
              </div>
              <div className={styles.field}>
                <Label htmlFor={urlId} required className={styles.label}>
                  {t('common:url')}
                </Label>
                <Input
                  className={styles.input}
                  id={urlId}
                  onChange={handleUrlChange}
                  value={url}
                  type="url"
                />
              </div>
              <div>
                <details>
                  <summary className={styles.summary}>
                    {t('sign-messages')}
                  </summary>
                  <div className={styles.field}>
                    <Label htmlFor={secretId} className={styles.label}>
                      {t('secret')}
                    </Label>
                    <Input
                      className={styles.input}
                      id={secretId}
                      onChange={handleSecretChange}
                      value={secret}
                      pattern="[0-9a-fA-F]{64}"
                      placeholder={t('secret-placeholder')}
                    />
                  </div>
                </details>
              </div>
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <DialogTrigger>
                <Button appearance="secondary">
                  {t('common:action--cancel')}
                </Button>
              </DialogTrigger>
              <Button
                appearance="primary"
                type="submit"
                disabled={url.trim().length === 0}
              >
                {t('common:action--add')}
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )

  function handleOpenChange(
    _event: DialogOpenChangeEvent,
    data: DialogOpenChangeData
  ) {
    if (data.open === false) {
      onCancel()
    }
    setUrl('')
    setSecret('')
  }

  function handleUrlChange(
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setUrl(data.value)
  }

  function handleSecretChange(
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setSecret(data.value)
  }

  function handleSubmit(ev: FormEvent) {
    ev.preventDefault()
    onAdd({ url, secret })
  }
}

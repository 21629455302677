import { Client, Message } from '@axteams-one/bws-cloud-azure-web-pubsub'
import { useEffect, useState } from 'react'

import {
  ErrorCode,
  GetPositioningWebSocketUrlDocument,
  GetPositioningWebSocketUrlQuery,
} from '../graphql/__generated__/graphql'
import { ApiResult, ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

type UsePositioningWebSocketProps = {
  organizationId: string
  onMessage: (event: CustomEvent<Message>) => void
}

/**
 * Establish a WebSocket for consuming positioning events for an organization.
 */
export function usePositioningWebSocket({
  organizationId,
  onMessage,
}: UsePositioningWebSocketProps) {
  const client = useUrqlClient(Api.Bwo)
  const [result, setResult] = useState<ApiResult<string>>({
    status: ApiStatus.Idle,
  })
  const [error, setError] = useState<
    InternalErrorCode | ErrorCode | undefined
  >()
  const [retryCount, setRetryCount] = useState(0)

  useEffect(() => {
    let timeout: NodeJS.Timeout
    function retryPubSubConnection() {
      if (error) {
        // Will re-trigger the websocket query
        setRetryCount((retryCount) => retryCount + 1)

        // Retry the positions connection every 2 minutes,
        // if there's an error.
        timeout = setTimeout(retryPubSubConnection, 120_000)
      }
    }

    retryPubSubConnection()

    return () => clearTimeout(timeout)
  }, [error])

  useEffect(() => {
    if (!client || !organizationId) {
      return
    }

    setResult({ status: ApiStatus.Loading })
    client
      .query<GetPositioningWebSocketUrlQuery>(
        GetPositioningWebSocketUrlDocument,
        {
          options: { organizationId },
        },
        {
          requestPolicy: 'network-only',
        }
      )
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode = result.error.graphQLErrors[0]?.extensions.code
          setResult({
            status: ApiStatus.Rejected,
            error: errorCode as ErrorCode,
          })
        } else {
          const webSocketUrl = result?.data?.getPositioningWebSocketUrl.url
          setResult({
            status: ApiStatus.Resolved,
            data: webSocketUrl || '',
          })
        }
      })
      .catch(() => {
        setResult({
          status: ApiStatus.Rejected,
          error: InternalErrorCode.NetworkError,
        })
      })
  }, [client, organizationId, retryCount])

  // Graphql connection string failure
  useEffect(() => {
    // Don't change the error state while the query is loading.
    if (result.status === ApiStatus.Rejected) {
      setError(result.error)
    } else if (result.status === ApiStatus.Resolved) {
      setError(undefined)
    }
  }, [result])

  useEffect(() => {
    if (result.status !== ApiStatus.Resolved) {
      return
    }

    let webPubClient
    try {
      webPubClient = new Client(result.data)
    } catch (error) {
      setError(ErrorCode.BwoBadRequest)
      console.error(error, organizationId, result.data)
      return
    }

    webPubClient.connect()
    webPubClient.addEventListener('message', onMessage)

    return () => {
      webPubClient?.removeEventListener('message', onMessage)
      webPubClient?.disconnect()
    }
  }, [onMessage, organizationId, result])

  return error
}

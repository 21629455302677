import { useEffect, useState } from 'react'

import { MAX_GROUP_MEMBER_COUNT } from '../constants'
import { Stream, compareTriggerTimestamp } from '../util/stream'

type UseGroupStreamsProps = {
  streams: Stream[]
  bearerIds: string[]
}

export function useGroupStreams({ streams, bearerIds }: UseGroupStreamsProps) {
  const [dismissedStreamIds, setDismissedStreamIds] = useState<string[]>([])
  const [groupStreams, setGroupStreams] = useState(
    streams
      .filter((stream) => stream.ongoing && bearerIds.includes(stream.bearerId))
      .sort((a: Stream, b: Stream) => compareBearerOrder(a, b, bearerIds))
      .slice(0, MAX_GROUP_MEMBER_COUNT)
  )

  useEffect(() => {
    setGroupStreams((previousStreams) => {
      return streams
        .filter((stream: Stream) =>
          streamOngoingOrEndedFilter(
            stream,
            previousStreams,
            dismissedStreamIds,
            bearerIds
          )
        )
        .sort(compareTriggerTimestamp)
        .sort((a: Stream, b: Stream) => compareBearerOrder(a, b, bearerIds))
        .filter(streamDuplicateBearerFilter)
        .slice(0, MAX_GROUP_MEMBER_COUNT)
    })
  }, [bearerIds, dismissedStreamIds, streams])

  function handleDismissStream(streamId: string) {
    setDismissedStreamIds((previous) => [...previous, streamId])
  }

  return { groupStreams, handleDismissStream }
}

/**
 * Include ongoing streams or streams that were previously displayed, i.e
 * ongoing when component was initially mounted.
 */
function streamOngoingOrEndedFilter(
  stream: Stream,
  previousStreams: Stream[],
  dismissedStreamIds: string[],
  bearerIds: string[]
) {
  return (
    bearerIds.includes(stream.bearerId) &&
    (stream.ongoing ||
      previousStreams.find(
        (previousStream) => previousStream.id === stream.id
      )) &&
    !dismissedStreamIds.includes(stream.id)
  )
}

/** Filter duplicate streams from same bearer */
function streamDuplicateBearerFilter(
  stream: Stream,
  index: number,
  streams: Stream[]
) {
  return (
    streams.findIndex(
      (latestStream) => latestStream.bearerId === stream.bearerId
    ) === index
  )
}

/** Sort streams in same order as bearers in group */
function compareBearerOrder(a: Stream, b: Stream, bearerIds: string[]) {
  for (const bearerId of bearerIds) {
    if (b.bearerId === bearerId) {
      return 1
    }
    if (a.bearerId === bearerId) {
      return -1
    }
  }

  return 0
}

import { useCallback, useEffect, useState } from 'react'
import { Client } from 'urql'

import {
  ErrorCode,
  System as GeneratedSystem,
  GetSystemsDocument,
  GetSystemsQuery,
} from '../graphql/__generated__/graphql'
import { ApiResult, ApiStatus, InternalErrorCode } from './types'

export type System = Omit<GeneratedSystem, '__typename'>

export function useSystems(
  client?: Client,
  organizationId?: string
): [ApiResult<System[]>, () => void] {
  const [result, setResult] = useState<ApiResult<System[]>>({
    status: ApiStatus.Idle,
  })

  const getSystems = useCallback(() => {
    if (!client || !organizationId) {
      return
    }
    setResult({ status: ApiStatus.Loading })
    client
      .query<GetSystemsQuery>(
        GetSystemsDocument,
        {
          options: { organizationId },
        },
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then((result) => {
        if (result.error) {
          // If we add a new error which is not in ErrorCode yet it will still
          // be set and the consumer will have to handle it with an else
          const errorCode = result.error.graphQLErrors[0].extensions.code
          setResult({
            status: ApiStatus.Rejected,
            error: errorCode as ErrorCode,
          })
        } else {
          const data = result.data?.getSystems
          if (data) {
            const systems = data.map(systemsMapper)
            setResult({ status: ApiStatus.Resolved, data: systems })
          }
        }
      })
      .catch(() => {
        setResult({
          status: ApiStatus.Rejected,
          error: InternalErrorCode.NetworkError,
        })
      })
  }, [client, organizationId])

  useEffect(() => {
    getSystems()
  }, [getSystems])

  return [result, getSystems]
}

function systemsMapper(data: GeneratedSystem): System {
  return {
    id: data.id,
    name: data.name,
    systemID: data.systemID,
    organizationID: data.organizationID,
    storageLocationID: data.storageLocationID,
  }
}

import { Temporal } from '@js-temporal/polyfill'

/** Return UTC offset(±hh:mm) from UTC offset in seconds. */
export function UtcOffsetFromSecondsToString(offset: number): string {
  const duration = Temporal.Duration.from({ seconds: Math.abs(offset) }).round({
    largestUnit: 'hours',
  })

  const sign = offset < 0 ? '-' : '+'
  const hh = duration.hours.toString().padStart(2, '0')
  const mm = duration.minutes.toString().padStart(2, '0')

  return `${sign}${hh}:${mm}`
}

/** Return duration formatted as 'hh:mm:ss' from duration in seconds. */
export function durationFromSecondsToString(durationRaw: number): string {
  const duration = Temporal.Duration.from({
    seconds: Math.abs(durationRaw),
  }).round({
    largestUnit: 'hours',
  })

  const durationParts: string[] = []

  if (duration.hours) {
    durationParts.push(duration.hours.toString())
    durationParts.push(duration.minutes.toString().padStart(2, '0'))
  } else {
    durationParts.push(duration.minutes.toString())
  }
  durationParts.push(duration.seconds.toString().padStart(2, '0'))

  return durationParts.join(':')
}

/** Return duration formatted as '?h hour(s) ?m minute(s) ?s second(s)'. */
export function durationToString(duration: Temporal.Duration) {
  const durationParts = [duration.hours, duration.minutes, duration.seconds]
  const units = ['hour', 'minute', 'second']

  const format = (durationString: string, value: number, index: number) => {
    if (value > 1) {
      return `${durationString} ${value} ${units[index]}s`
    } else if (value === 1) {
      return `${durationString} 1 ${units[index]}`
    } else {
      return durationString
    }
  }

  return durationParts.reduce(format, '')
}

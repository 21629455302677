import { addPrivateKey, generateKey } from '@axteams-one/bws-cloud-crypto/e2ee'
import {
  decryptDataWithPassphrase,
  encryptDataWithPassphrase,
} from '@axteams-one/bws-cloud-crypto/encryption'
import {
  calculateThumbprint,
  isSealedBoxPrivateJWK,
} from '@axteams-one/bws-cloud-crypto/jwk'
import { useWebApplication } from '@axteams-one/bws-cloud-discovery/react'
import {
  Button,
  Card,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  Subtitle2,
  Text,
  Toast,
  ToastTitle,
  Tooltip,
  makeStyles,
  tokens,
  useToastController,
} from '@fluentui/react-components'
import {
  ArrowImport20Regular,
  Copy20Regular,
  Eye16Regular,
  Info16Regular,
  KeyMultiple20Regular,
} from '@fluentui/react-icons'
import { Buffer } from 'buffer'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TOASTER_ID } from '../../constants'
import { useContentKey } from '../../hooks/useContentKeys'
import { ExternalLink } from '../ExternalLink'
import { DeleteKeysDialog } from './DeleteKeysDialog'
import { ExportKeysDialog } from './ExportKeysDialog'
import { ImportKeysDialog } from './ImportKeysDialog'

const useStyles = makeStyles({
  card: {
    maxWidth: '600px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: tokens.spacingVerticalL,
  },
  contentHeader: {
    alignItems: 'center',
    display: 'flex',
  },
  contentHeaderText: {
    // Use default 'normal' line-height to center align text with tooltip icon.
    lineHeight: 'normal',
  },
  addKeysSection: {
    display: 'flex',
    justifyContent: 'end',
    columnGap: tokens.spacingHorizontalMNudge,
  },
  keySection: {
    display: 'flex',
    justifyContent: 'center',
    margin: `${tokens.spacingHorizontalM} 0`,
    columnGap: tokens.spacingVerticalS,
  },
  thumbSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: tokens.colorNeutralForeground4,
  },
  thumbContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  downloadSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  keyText: {
    wordBreak: 'break-all',
  },
  hidden: {
    display: 'none',
  },
})

interface ImportedKeys {
  fileName: string
  contents: string
}

export function EndToEndEncryptionSettings() {
  const styles = useStyles()
  const manual = useWebApplication('bwl-manual')
  const { dispatchToast } = useToastController(TOASTER_ID)
  const [contentKey, setContentKey] = useContentKey()
  const [publicKeyBase64Encoded, setPublicKeyBase64Encoded] =
    useState<string>('')

  const [showImportKeysDialog, setShowImportKeysDialog] = useState(false)
  const [importedKeys, setImportedKeys] = useState<ImportedKeys | null>(null)
  const [showDownloadKeysButton, setShowDownloadKeysButton] = useState(false)
  const [importKeysErrorMessage, setImportKeysErrorMessage] = useState('')
  const { t } = useTranslation('settings-encryption')

  useEffect(() => {
    if (contentKey) {
      // Get only the public key details for encoding
      const { d: _, ...publicJWK } = contentKey.jwk
      setPublicKeyBase64Encoded(
        Buffer.from(JSON.stringify(publicJWK)).toString('base64')
      )
    } else {
      setShowDownloadKeysButton(false)
      setPublicKeyBase64Encoded('')
      setImportKeysErrorMessage('')
    }
  }, [contentKey])

  const handleGenerateKeys = useCallback(() => {
    ;(async () => {
      // Generate a new key
      const jwk = await generateKey()
      const thumbprint = await calculateThumbprint(jwk)

      // Make it immediately available for streams
      await addPrivateKey(jwk)

      setContentKey({ jwk, thumbprint })
      setShowDownloadKeysButton(true)
    })().catch((error) => {
      console.error('failed to generate key', error)
      dispatchToast(
        <Toast>
          <ToastTitle>{t('failed-to-generate-key')}</ToastTitle>
        </Toast>,
        { toastId: 'key-generation-failed', intent: 'error' }
      )
    })
  }, [setContentKey, dispatchToast, t])

  const handleCloseImportKeysDialog = useCallback(() => {
    setShowImportKeysDialog(false)
    setImportedKeys(null)
    setImportKeysErrorMessage('')
  }, [])

  const handleDeleteKeys = useCallback(() => {
    setContentKey(null)
    dispatchToast(
      <Toast>
        <ToastTitle>{t('successfully-deleted-keys')}</ToastTitle>
      </Toast>,
      { intent: 'success' }
    )
  }, [dispatchToast, setContentKey, t])

  const validateImport = useCallback(
    async (fileName: string, jsonData: string) => {
      try {
        const jwk = JSON.parse(jsonData as string)
        if (isSealedBoxPrivateJWK(jwk)) {
          const thumbprint = await calculateThumbprint(jwk)
          setContentKey({ jwk, thumbprint })
          dispatchToast(
            <Toast>
              <ToastTitle>{t('imported-keys--import-success')}</ToastTitle>
            </Toast>,
            { intent: 'success' }
          )
        } else {
          dispatchToast(
            <Toast>
              <ToastTitle>
                {t('imported-keys--incorrect-format', {
                  fileName,
                })}
              </ToastTitle>
            </Toast>,
            { intent: 'error' }
          )
        }
      } catch (event) {
        dispatchToast(
          <Toast>
            <ToastTitle>
              {t('imported-keys--invalid-json', {
                fileName,
              })}
            </ToastTitle>
          </Toast>,
          { intent: 'error' }
        )
      }

      setShowImportKeysDialog(false)
    },
    [dispatchToast, setContentKey, t]
  )

  const handleImportKeys = useCallback(() => {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = function (event) {
      const files = (event.target as HTMLInputElement).files
      if (!files) {
        return
      }
      const file = files[0]
      if (file.size >= 1024) {
        dispatchToast(
          <Toast>
            {t('imported-keys--incorrect-format', {
              fileName: file.name,
            })}
          </Toast>,
          { intent: 'error' }
        )
        return
      }

      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.addEventListener('load', async () => {
        const contents = (reader.result as string).trim()

        // Assume that, if the file starts with '{', it's raw JSON data and can
        // be imported without decryption. On the other hand, if the file
        // contains something else, assume that we first need to decrypt it.
        if (contents.startsWith('{')) {
          validateImport(file.name, contents)
        } else {
          setShowImportKeysDialog(true)
          setImportedKeys({ fileName: file.name, contents: contents })
        }
      })

      reader.addEventListener('error', () => {
        dispatchToast(
          <Toast>
            <ToastTitle>{t('imported-keys--import-failed')}</ToastTitle>
          </Toast>,
          { intent: 'error' }
        )
      })
    }

    input.click()
  }, [dispatchToast, t, validateImport])

  const handleImportKeysWithPassphrase = useCallback(
    async (passphrase: string) => {
      if (!importedKeys) {
        return
      }
      let jsonData: string
      try {
        jsonData = new TextDecoder().decode(
          await decryptDataWithPassphrase(importedKeys.contents, passphrase)
        )
      } catch (error) {
        console.error('Failed to decrypt file', error)
        setImportKeysErrorMessage(
          t('imported-keys--decryption-failed', {
            fileName: importedKeys.fileName,
          })
        )
        return
      }

      if (jsonData) {
        validateImport(importedKeys.fileName, jsonData)
      }
    },
    [importedKeys, t, validateImport]
  )

  const handleExportKeys = useCallback(
    async (passphrase: string) => {
      if (!contentKey?.jwk) {
        return
      }
      let blob: Blob
      if (passphrase) {
        try {
          const JWK = await encryptDataWithPassphrase(
            JSON.stringify(contentKey.jwk),
            passphrase
          )
          blob = new Blob([JWK], { type: 'text/plain' })
        } catch (error) {
          console.error('Failed to encrypt file', error)
          dispatchToast(
            <Toast>
              <ToastTitle>{t('exported-keys--encryption-failed')}</ToastTitle>
            </Toast>,
            { intent: 'error' }
          )

          return
        }
      } else {
        blob = new Blob([JSON.stringify(contentKey.jwk)], {
          type: 'application/json',
        })
      }

      if ('showSaveFilePicker' in window) {
        try {
          const handle = await window.showSaveFilePicker({
            suggestedName: 'AXIS_body_worn_live_keys',
          })
          const writable = await handle.createWritable()
          await writable.write(blob)
          await writable.close()
        } catch (error) {
          console.error('Failed to export keys', error)
          dispatchToast(
            <Toast>
              <ToastTitle>{t('exported-keys--download-failed')}</ToastTitle>
            </Toast>,
            { intent: 'error' }
          )
          return
        }
      } else {
        // Fallback if the File System Access API is not supported.
        const blobURL = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = blobURL
        a.download = 'keys'
        a.click()
      }

      dispatchToast(
        <Toast>
          <ToastTitle>{t('exported-keys--download-success')}</ToastTitle>
        </Toast>,
        { intent: 'success' }
      )
    },
    [contentKey?.jwk, dispatchToast, t]
  )

  const manualLink = (
    <ExternalLink
      text={t('common:user-manual')}
      href={`${manual?.uri}#enable-end-to-end-encryption`}
    />
  )

  return (
    <Card size="large" className={styles.card}>
      <div className={styles.contentHeader}>
        <Subtitle2 className={styles.contentHeaderText}>{t('e2ee')}</Subtitle2>
        <Tooltip content={t('e2ee-information')} relationship="label">
          <Button appearance="transparent" icon={<Info16Regular />} />
        </Tooltip>
      </div>
      {contentKey ? <KeysSection /> : <NoKeysSection />}
    </Card>
  )

  function KeysSection() {
    return (
      <div className={styles.content}>
        {showDownloadKeysButton && (
          <MessageBar>
            <MessageBarBody>{t('keys-section--download-keys')}</MessageBarBody>
            <MessageBarActions>
              <ExportKeysDialog onExportKeys={handleExportKeys} />
            </MessageBarActions>
          </MessageBar>
        )}
        <Text>
          <Trans
            i18nKey="keys-section--information"
            t={t}
            components={{ manualLink: manualLink }}
          />
        </Text>
        <div className={styles.keySection}>
          <Button
            icon={<Copy20Regular />}
            appearance="primary"
            onClick={() => {
              navigator.clipboard.writeText(publicKeyBase64Encoded)
            }}
          >
            Copy public key
          </Button>
          <DeleteKeysDialog onDeleteKeys={handleDeleteKeys} />
        </div>
        <div className={styles.thumbSection}>
          <Eye16Regular />
          <Text as="p" size={200}>
            {t('keys-section--thumbprint', {
              thumbprint: contentKey?.thumbprint,
            })}
          </Text>
        </div>
      </div>
    )
  }

  function NoKeysSection() {
    return (
      <div className={styles.content}>
        <Text as="p">
          <Trans
            i18nKey="no-keys-section--information"
            t={t}
            components={{ manualLink: manualLink }}
          />
        </Text>

        <div className={styles.addKeysSection}>
          <Button
            icon={<ArrowImport20Regular />}
            appearance="secondary"
            onClick={() => {
              handleImportKeys()
            }}
          >
            {t('no-keys-section--import-keys')}
          </Button>
          {showImportKeysDialog && (
            <ImportKeysDialog
              onClose={handleCloseImportKeysDialog}
              onImportKeys={handleImportKeysWithPassphrase}
              errorMessage={importKeysErrorMessage}
            />
          )}
          <Button
            icon={<KeyMultiple20Regular />}
            appearance="primary"
            onClick={() => {
              handleGenerateKeys()
            }}
          >
            {t('no-keys-section--generate-keys')}
          </Button>
        </div>
      </div>
    )
  }
}

import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { useRef } from 'react'

import { PlayerState } from './VideoPlayer'

const useStyles = makeStyles({
  container: {
    backgroundColor: '#7c7b7b',
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    height: '2px',
    opacity: 0,
  },
  bufferProgress: {
    backgroundColor: '#707070',
    height: '100%',
    position: 'absolute',
  },
  videoProgress: {
    backgroundColor: tokens.colorNeutralStrokeAccessibleSelected,
    display: 'flex',
    height: '100%',
    justifyContent: 'end',
    position: 'absolute',
  },
})

type TimelineProps = {
  isScrubbingRef: React.MutableRefObject<boolean>
  progressWidthRef: React.MutableRefObject<number>
  state: PlayerState
  shouldBeLive: boolean
  scrubPosition: number
}

export default function Timeline({
  progressWidthRef,
  state,
  shouldBeLive,
}: TimelineProps) {
  const styles = useStyles()
  const timelineRef = useRef<HTMLDivElement>(null)

  return (
    <div
      className={mergeClasses(styles.container, 'timeline')}
      ref={timelineRef}
    >
      <div
        className={styles.bufferProgress}
        style={{
          width: `${Math.min(
            ((state.presentedPosition + state.presentedBufferGap) /
              state.presentedDuration) *
              100,
            100
          )}%`,
        }}
      />
      <div
        className={mergeClasses(styles.videoProgress, 'videoProgress')}
        style={{
          width: shouldBeLive ? '100%' : `${progressWidthRef.current}px`,
        }}
      ></div>
    </div>
  )
}

import { AuthStatus, useAuth } from '@axteams-one/auth-provider'
import {
  Avatar,
  Body2,
  Text,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { LoadingPage } from '../components/Loading'
import { DASHBOARD_SIDEBAR_MIN_WIDTH, HEADER_HEIGHT } from '../constants'
import { useContentKeys } from '../hooks/useContentKeys'
import { useRedirectIfNotLoggedIn } from '../hooks/useRedirectIfNotLoggedIn'
import { useStreams } from '../hooks/useStreams'
import { useCurrentOrganization } from '../providers/CurrentOrganizationProvider'
import { useNotificationsClient } from '../providers/NotificationsProvider'
import { Stream } from '../util/stream'
import { NoEndToEndEncryptionPage } from './NoEndToEndEncryptionPage'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '100%',
  },
  sidebar: {
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${HEADER_HEIGHT})`,
    minWidth: `${DASHBOARD_SIDEBAR_MIN_WIDTH}px`,
    width: `${DASHBOARD_SIDEBAR_MIN_WIDTH}px`,
    top: HEADER_HEIGHT,
    padding: `${tokens.spacingVerticalXL} ${tokens.spacingHorizontalS}`,
    gap: tokens.spacingVerticalL,
    '@supports (height: 100dvh)': {
      height: `calc(100dvh - ${HEADER_HEIGHT})`,
    },
  },
  recordingList: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& .selectedBearer': {
      backgroundColor: tokens.colorNeutralBackground1Selected,
      borderRadius: tokens.spacingVerticalXS,
      '& span': {
        color: tokens.colorNeutralForeground1,
      },
    },
  },
  tablistPlaceholder: {
    alignSelf: 'center',
    color: tokens.colorNeutralForegroundDisabled,
    paddingBottom: tokens.spacingVerticalL,
    paddingTop: tokens.spacingVerticalXL,
  },
  recordingItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    borderRadius: tokens.spacingVerticalXS,
    padding: tokens.spacingHorizontalXS,
    color: tokens.colorNeutralForeground2,
    '@media(hover: hover) and (pointer: fine)': {
      ':hover': {
        color: tokens.colorNeutralForeground1,
        backgroundColor: tokens.colorNeutralBackground3Hover,
      },
    },
  },
  recordingItemText: {
    whiteSpace: 'nowrap',
    marginInlineStart: tokens.spacingHorizontalXS,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    pointerEvents: 'none',
  },
  outlet: {
    backgroundColor: tokens.colorNeutralBackground2,
    flexGrow: 1,
    borderTopLeftRadius: tokens.borderRadiusXLarge,
    boxShadow: tokens.shadow8,
  },
})

type RecordingsTabListProps = {
  bearerId: string
  onRecordingSelect: (bearerId: string) => void
  recordings?: Stream[]
  streamId: string
}

type RecordingTabProps = {
  onRecordingSelect: (bearerId: string) => void
  recording: Stream
  selected: boolean
}

function RecordingsPage() {
  const styles = useStyles()
  useRedirectIfNotLoggedIn()
  const { status } = useAuth()
  const organizationId = useCurrentOrganization().id
  const { streamId = '' } = useParams()
  const navigate = useNavigate()
  const [keys, _setKeys] = useContentKeys()
  const [selectedBearer, setSelectedBearer] = useState('')
  const { client: notificationsClient } = useNotificationsClient()
  const streams = useStreams({
    organizationId,
    webPubClient: notificationsClient,
  })
  const recordings = streams?.filter((stream) => !stream.ongoing)

  function handleRecordingSelect(bearerId: string) {
    setSelectedBearer(bearerId)
    navigate('/recordings')
  }

  if (status === AuthStatus.Uninitialized || !recordings) {
    return <LoadingPage />
  }

  if (!keys.length) {
    return <NoEndToEndEncryptionPage />
  }

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <RecordingsList
          bearerId={selectedBearer}
          onRecordingSelect={handleRecordingSelect}
          recordings={recordings}
          streamId={streamId}
        />
      </div>
      <div className={styles.outlet}>
        <Outlet
          context={recordings.filter(
            (recording) => recording.bearerId === selectedBearer
          )}
        />
      </div>
    </div>
  )
}

function RecordingsList({
  bearerId,
  onRecordingSelect,
  recordings,
  streamId,
}: RecordingsTabListProps) {
  const styles = useStyles()
  const { t } = useTranslation('recordings')

  if (recordings?.length === 0) {
    return (
      <Body2 className={styles.tablistPlaceholder}>
        {t('no-recent-streams')}
      </Body2>
    )
  }

  const selectedValue =
    bearerId ||
    recordings?.find((recording) => recording.id === streamId)?.bearerId ||
    ''

  const recordingItems = recordings
    ?.filter(
      (recording, index, recordings) =>
        index ===
        recordings.findIndex((value) => value.bearerId === recording.bearerId)
    )
    .map((recording) => (
      <RecordingItem
        key={recording.id}
        onRecordingSelect={onRecordingSelect}
        recording={recording}
        selected={recording.bearerId === selectedValue}
      />
    ))

  return <div className={styles.recordingList}>{recordingItems}</div>
}

function RecordingItem({
  onRecordingSelect,
  recording,
  selected,
}: RecordingTabProps) {
  const styles = useStyles()

  return (
    <div
      color={tokens.colorBrandForeground1}
      className={RecordingItemClassName()}
      key={recording.bearerId}
      title={recording.metadata.bearerName}
      onClick={() => onRecordingSelect(recording.bearerId)}
      data-testid={recording.metadata.bearerName + 'Recordings'}
    >
      <Avatar size={24} name={recording.metadata.bearerName} />
      <Text className={styles.recordingItemText}>
        {recording.metadata.bearerName}
      </Text>
    </div>
  )

  function RecordingItemClassName() {
    let classes = styles.recordingItem
    if (selected) {
      classes = mergeClasses(classes, 'selectedBearer')
    }
    return classes
  }
}

export { RecordingsPage }

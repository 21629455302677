import { useTrackPageView } from '@axteams-one/bws-cloud-metrics/react'
import { makeStyles } from '@fluentui/react-components'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { GroupViewer } from '../components/GroupViewer'
import { NoGroupBearers } from '../components/NoGroupBearers'
import { useContentKeys } from '../hooks/useContentKeys'
import { useGroupStreams } from '../hooks/useGroupStreams'
import { bearerIdsFromStringToList, getCachedGroup } from '../util/group'
import { NoEndToEndEncryptionPage } from './NoEndToEndEncryptionPage'
import { useStreamsPageContext } from './StreamsPage'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
})

function GroupViewerPage() {
  useTrackPageView()
  const styles = useStyles()
  const navigate = useNavigate()
  const [keys] = useContentKeys()
  const { bearerIds = '', groupId = '' } = useParams()
  const group = useMemo(() => getCachedGroup(groupId), [groupId])
  const bearers = useMemo(
    () => bearerIdsFromStringToList(bearerIds),
    [bearerIds]
  )
  const { streams } = useStreamsPageContext()
  const { groupStreams, handleDismissStream } = useGroupStreams({
    streams,
    bearerIds: bearers,
  })
  // Ended streams for which recording should be shown, i.e. user has clicked
  // 'view recording'.
  const [recordingIds, setRecordingIds] = useState<string[]>([])

  if (!keys.length) {
    return <NoEndToEndEncryptionPage />
  }

  if (group) {
    if (group.bearers.size === 0) {
      return <NoGroupBearers />
    }

    return (
      <div className={styles.container}>
        <GroupViewer
          keys={keys}
          streams={groupStreams}
          recordingIds={recordingIds}
          onViewRecording={handleViewRecording}
          onDismissStream={handleDismissStream}
        />
      </div>
    )
  }

  // If the group doesn't exist in the browser, show the temporary group viewer
  // instead.
  navigate(`/streams/group/tmp/${bearerIds}`)

  function handleViewRecording(streamId: string) {
    setRecordingIds((previous) => [...previous, streamId])
  }
}

export { GroupViewerPage }

/**
 * Creates a marker according to chosen diameter, stroke and text content.
 * @param {number} diameter
 * @param {number} stroke
 * @param {string | number} text
 * @param {{ foreground?: string, background?: string, stroke?: string }} colors
 * @returns
 */
export function insecureClusterMarker(
  diameter: number,
  stroke: number,
  text: string | number,
  colors: { foreground?: string; background?: string; stroke?: string }
) {
  const radius = diameter / 2
  const foregroundColor = colors?.foreground ?? 'transparent'
  const backgroundColor = colors?.background ?? 'transparent'
  const strokeColor = colors?.stroke ?? 'transparent'

  return `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="${diameter}"
    height="${diameter}"
    viewBox="0 0 ${diameter} ${diameter}"
  >
    <circle style="fill: ${backgroundColor}; stroke: ${strokeColor}; stroke-width: ${stroke}px;" r="${radius - stroke - 1}" cx="${radius}" cy="${radius}" />
    <text x="50%" y="${radius}" dy="9" fill="${foregroundColor}" text-anchor="middle" style="font-family:arial; font-size: 24;">${text}</text>
  </svg>`
}

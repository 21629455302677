import {
  Body1,
  Button,
  Card,
  DialogTrigger,
  Spinner,
  Subtitle1,
  Toast,
  ToastTitle,
  makeStyles,
  useToastController,
} from '@fluentui/react-components'
import { AddRegular } from '@fluentui/react-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TOASTER_ID } from '../../constants'
import { WebhookEventSubscription } from '../../graphql/__generated__/graphql'
import { ApiStatus } from '../../hooks/types'
import { useCreateWebhookEventSubscription } from '../../hooks/useCreateWebhookEventSubscription'
import { useDeleteEventSubscription } from '../../hooks/useDeleteEventSubscription'
import { useOrganization } from '../../hooks/useOrganization'
import { AddWebhookDialog, AddWebhookDialogData } from './AddWebhookDialog'
import { WebhookUrlList } from './WebhookUrlList'

export type WebhookUrl = {
  created: string
  eventTypes: string[]
  id: string
  organizationId: string
  updated: string
  target: string
}

type WebHookSettingsProps = {
  organizationId: string
}

const useStyles = makeStyles({
  card: {
    maxWidth: '600px',
  },
  buttonSection: {
    display: 'flex',
    justifyContent: 'center',
  },
})

export function WebhookSettings({ organizationId }: WebHookSettingsProps) {
  const styles = useStyles()
  const { dispatchToast, dismissToast } = useToastController(TOASTER_ID)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const createWebhookEventSubscription = useCreateWebhookEventSubscription()
  const deleteEventSubscription = useDeleteEventSubscription()
  const [organizationQuery, refetchOrganiation] =
    useOrganization(organizationId)
  const { t } = useTranslation('settings-soc')

  if (
    organizationQuery.status === ApiStatus.Idle ||
    organizationQuery.status === ApiStatus.Loading
  ) {
    return null
  }

  if (organizationQuery.status === ApiStatus.Rejected) {
    return (
      <Card className={styles.card}>
        <Subtitle1>{t('soc-integration')}</Subtitle1>
        <Body1>{t('integration-problems')}</Body1>
      </Card>
    )
  }

  const subscriptions =
    organizationQuery.data?.webhookSubscriptions?.filter(streamStartedFilter)

  return (
    <Card size="large" className={styles.card}>
      <Subtitle1>{t('soc-integration')}</Subtitle1>
      <Body1>{t('soc-integration-information')}</Body1>
      <div className={styles.buttonSection}>
        <DialogTrigger>
          <Button
            icon={<AddRegular />}
            appearance="primary"
            onClick={() => setDialogOpen(true)}
          >
            {t('new-soc-integration')}
          </Button>
        </DialogTrigger>
      </div>
      <AddWebhookDialog
        open={dialogOpen}
        onAdd={handleAdd}
        onCancel={() => setDialogOpen(false)}
      />
      {subscriptions && subscriptions.length > 0 && (
        <WebhookUrlList
          list={subscriptions}
          onDelete={handleDeleteWebhookUrl}
        />
      )}
    </Card>
  )

  async function handleAdd({ url, secret }: AddWebhookDialogData) {
    dispatchToast(<AddWebhookProgressToast />, { toastId: 'progress-toast' })

    const response = await createWebhookEventSubscription({
      resourceArn: `arn:organization:${organizationId}`,
      url,
      signingKey: secret,
      eventTypes: ['com.axis.bodyworn.dash.stream.started'],
    })

    dismissToast('progress-toast')

    if (response?.status == ApiStatus.Rejected) {
      dispatchToast(<AddWebhookFailedToast />, { intent: 'error' })
    } else {
      dispatchToast(<AddWebhookSuccessToast />, { intent: 'success' })
      setDialogOpen(false)
      refetchOrganiation()
    }
  }

  async function handleDeleteWebhookUrl(arn: string) {
    const response = await deleteEventSubscription({
      arn: `${arn}`,
    })

    if (response?.status == ApiStatus.Rejected) {
      dispatchToast(<DeleteWebhookFailureToast />, { intent: 'error' })
    } else {
      dispatchToast(<DeleteWebhookSuccessToast />, { intent: 'success' })
      refetchOrganiation()
    }
  }

  function streamStartedFilter(subscription: WebhookEventSubscription) {
    return subscription.eventTypes.includes(
      'com.axis.bodyworn.dash.stream.started'
    )
  }

  function AddWebhookProgressToast() {
    return (
      <Toast>
        <ToastTitle media={<Spinner size="tiny" />}>
          {t('create-event-subscription')}
        </ToastTitle>
      </Toast>
    )
  }

  function AddWebhookFailedToast() {
    return (
      <Toast>
        <ToastTitle>{t('create-event-subscription-failed')}</ToastTitle>
      </Toast>
    )
  }

  function AddWebhookSuccessToast() {
    return (
      <Toast>
        <ToastTitle>{t('create-event-subscription-success')}</ToastTitle>
      </Toast>
    )
  }

  function DeleteWebhookSuccessToast() {
    return (
      <Toast>
        <ToastTitle>{t('delete-soc-integration-success')}</ToastTitle>
      </Toast>
    )
  }

  function DeleteWebhookFailureToast() {
    return (
      <Toast>
        <ToastTitle>{t('delete-soc-integration-failed')}</ToastTitle>
      </Toast>
    )
  }
}

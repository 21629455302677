import {
  BodyWornCamera20Filled,
  BodyWornCamera20Regular,
} from '@axiscommunications/fluent-icons'
import {
  useNavigationStyles,
  useTabStyles,
} from '@axiscommunications/fluent-styles'
import {
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  Text,
  Tooltip,
  makeStyles,
} from '@fluentui/react-components'
import {
  ArrowRotateCounterclockwise20Filled,
  ArrowRotateCounterclockwise20Regular,
  Settings20Filled,
  Settings20Regular,
  bundleIcon,
} from '@fluentui/react-icons'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

const useStyles = makeStyles({
  tooltipText: {
    fontWeight: 'semibold',
    textTransform: 'capitalize',
  },
})

const BwcIcon = bundleIcon(BodyWornCamera20Filled, BodyWornCamera20Regular)
const RecentIcon = bundleIcon(
  ArrowRotateCounterclockwise20Filled,
  ArrowRotateCounterclockwise20Regular
)
const SettingsIcon = bundleIcon(Settings20Filled, Settings20Regular)

type TabItem = {
  value: string
  icon: JSX.Element
  testId: string
}

type StyledTabProps = {
  value: string
  icon: JSX.Element
  selected: boolean
  testId: string
}

function Sidebar() {
  const navigate = useNavigate()
  const location = useLocation()

  const selectedValue = location.pathname.split('/')[1]

  const handleTabSelect = (_event: SelectTabEvent, data: SelectTabData) => {
    const subroute = data.value as string

    navigate(`/${subroute}`)
  }

  const navStyles = useNavigationStyles()

  const tabs: TabItem[] = [
    { value: 'streams', icon: <BwcIcon />, testId: 'streamsButton' },
    { value: 'recordings', icon: <RecentIcon />, testId: 'recordingsButton' },
    { value: 'settings', icon: <SettingsIcon />, testId: 'settingsButton' },
  ]

  return (
    <nav className={navStyles.container}>
      <TabList
        className={navStyles.tabList}
        selectedValue={selectedValue}
        size="large"
        onTabSelect={handleTabSelect}
        vertical
      >
        {tabs.map((item) => (
          <StyledTab
            key={item.value}
            value={item.value}
            icon={item.icon}
            selected={item.value === selectedValue}
            testId={item.testId}
          />
        ))}
      </TabList>
    </nav>
  )
}

function StyledTab({ value, icon, selected, testId }: StyledTabProps) {
  const { rootStyle } = useTabStyles({ selected })

  const styles = useStyles()

  return (
    <Tooltip
      content={<Text className={styles.tooltipText}>{value}</Text>}
      positioning="after"
      relationship="label"
      withArrow
    >
      <NavLink to={value}>
        <Tab
          icon={icon}
          aria-label={value}
          value={value}
          className={rootStyle}
          data-testid={testId}
        />
      </NavLink>
    </Tooltip>
  )
}

export { Sidebar }

import {
  Link,
  Text,
  makeStyles,
  mergeClasses,
} from '@fluentui/react-components'
import { OpenRegular } from '@fluentui/react-icons'

type ExternalLinkProps = {
  className?: string
  href: string
  text: string
  target?: '_self' | '_blank' | '_parent' | '_top'
}

const useStyles = makeStyles({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  text: {
    marginInlineEnd: '.2em',
  },
  icon: {
    flexShrink: 0,
    marginBlockStart: '2px', // Magic number to improve visual alignment
  },
})

export function ExternalLink({
  className,
  href,
  text,
  target = '_blank',
}: ExternalLinkProps) {
  const styles = useStyles()
  const classes = mergeClasses(className, styles.link)
  return (
    <Link href={href} className={classes} target={target}>
      <Text className={styles.text}>{text}</Text>
      <OpenRegular className={styles.icon} />
    </Link>
  )
}

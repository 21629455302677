import { useTrackPageView } from '@axteams-one/bws-cloud-metrics/react'
import { Card } from '@fluentui/react-card'
import {
  Subtitle1,
  Subtitle2,
  Title2,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { NoRecordingsPlaceholder } from '../components/NoRecordingsPlaceholder'
import { HEADER_HEIGHT } from '../constants'
import { Stream } from '../util/stream'
import { durationToString } from '../util/time'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${tokens.spacingHorizontalL}`,
  },
  header: {
    position: 'sticky',
    paddingTop: tokens.spacingVerticalXXXL,
    top: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 97,
    backgroundColor: tokens.colorNeutralBackground2,
  },
  title: {
    fontFamily: `'LubalinGraphECG-Demi', ${tokens.fontFamilyBase}`,
    marginBottom: tokens.spacingVerticalXL,
  },
  titleDot: {
    color: tokens.colorNeutralStrokeAccessibleSelected,
  },
  bearerTitle: {
    fontWeight: 'regular',
    marginBottom: tokens.spacingHorizontalL,
  },
  cardContainer: {
    display: 'grid',
    gap: tokens.spacingVerticalL,
    gridTemplateColumns: 'repeat(auto-fit, minmax(min-content, 360px))',
    overflowY: 'auto',
  },
  card: {
    padding: tokens.spacingHorizontalXXL,
  },
  cardDate: {
    fontWeight: tokens.fontWeightBold,
  },
  cardTime: {
    fontWeight: tokens.fontWeightMedium,
  },
  cardDuration: {
    color: tokens.colorNeutralStrokeAccessibleHover,
    fontWeight: tokens.fontWeightMedium,
  },
})

type RecordingCardProps = {
  recording: Stream
  onRecordingClick: (streamId: string) => void
}

function RecordingsStartPage() {
  useTrackPageView()
  const { t } = useTranslation('recordings')
  const styles = useStyles()
  const navigate = useNavigate()
  const recordings = useOutletContext<Stream[]>()

  if (recordings.length === 0) {
    return <NoRecordingsPlaceholder />
  }

  const handleRecordingClick = (streamId: string) => {
    navigate(`/recordings/${streamId}`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Title2 className={styles.title}>
          Recent Streams<span className={styles.titleDot}>.</span>
        </Title2>
        <Subtitle1 className={styles.bearerTitle}>
          {recordings[0]?.metadata?.bearerName ?? t('user-has-no-streams')}
        </Subtitle1>
      </div>
      <div className={styles.cardContainer}>
        {recordings.map((recording) => (
          <RecordingCard
            key={recording.id}
            recording={recording}
            onRecordingClick={handleRecordingClick}
          />
        ))}
      </div>
    </div>
  )
}

function RecordingCard({ recording, onRecordingClick }: RecordingCardProps) {
  const styles = useStyles()
  const { t } = useTranslation('recordings')

  const triggerTimestamp = recording.metadata.triggerTimestamp
  const duration = recording.duration
    ? durationToString(recording.duration)
    : t('duration-unknown')

  const triggerDate =
    triggerTimestamp?.toPlainDate().toLocaleString() ?? t('date-unknown')
  const triggerTime =
    triggerTimestamp?.toPlainTime().round('seconds').toLocaleString() ??
    t('time-unknown')

  return (
    <div>
      <Card
        className={styles.card}
        onClick={() => onRecordingClick(recording.id)}
        size="large"
        data-testid={`${recording.metadata.bearerName}Recording-${recording.id}`}
      >
        <Subtitle2 className={styles.cardDate}>{triggerDate}</Subtitle2>
        <Subtitle2 className={styles.cardTime}>{triggerTime}</Subtitle2>
        <Subtitle2 className={styles.cardDuration}>{duration}</Subtitle2>
      </Card>
    </div>
  )
}

export { RecordingsStartPage }

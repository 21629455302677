import { useTrackPageView } from '@axteams-one/bws-cloud-metrics/react'
import { makeStyles } from '@fluentui/react-components'
import { useParams } from 'react-router-dom'

import { VideoPlayerContainer } from '../components/VideoPlayer/VideoPlayerContainer'
import { useContentKeys } from '../hooks/useContentKeys'
import { NoEndToEndEncryptionPage } from './NoEndToEndEncryptionPage'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
})

function RecordingPlayerPage() {
  useTrackPageView()
  const styles = useStyles()
  const { streamId = '' } = useParams()
  const [keys] = useContentKeys()

  if (!keys.length) {
    return <NoEndToEndEncryptionPage />
  }

  return (
    <div className={styles.container}>
      <VideoPlayerContainer keys={keys} streamId={streamId} />
    </div>
  )
}

export { RecordingPlayerPage }

import { Client, Message } from '@axteams-one/bws-cloud-azure-web-pubsub'
import { useCallback, useEffect, useMemo } from 'react'

import { CloudEvent, CloudEventType, StreamEventData } from '../util/CloudEvent'
import { useContentKeys } from './useContentKeys'
import { useGetStreams } from './useGetStreams'
import { Api, useUrqlClient } from './useUrqlClient'

// Interval to poll streams in case websocket connection fails
const POLL_INTERVAL = 3000

type UseStreamsProps = {
  organizationId: string
  webPubClient?: Client
}

/**
 * Returns up to date list of streams for an organization. The list of streams
 * is updated every time a stream is stared or ended, by listening for incoming
 * messages to the provide web pub client.
 */
export function useStreams({ organizationId, webPubClient }: UseStreamsProps) {
  const client = useUrqlClient(Api.Bwo)
  const [keys, _setKeys] = useContentKeys()
  const recipients = useMemo(() => keys.map((x) => x.thumbprint), [keys])

  // Fetch streams every time a stream starts or ends.
  const [streams, getStreams] = useGetStreams({
    client,
    organizationId,
    recipients,
  })

  const messageHandler = useCallback(
    (event: CustomEvent<Message>) => {
      const message = event.detail.data as CloudEvent<StreamEventData>

      if (
        message.type === CloudEventType.STREAM_STARTED ||
        message.type === CloudEventType.STREAM_ENDED
      ) {
        getStreams()
      }
    },
    [getStreams]
  )

  useEffect(() => {
    let interval: NodeJS.Timeout
    let client: Client

    if (!webPubClient) {
      interval = setInterval(() => {
        getStreams()
      }, POLL_INTERVAL)
    } else {
      client = webPubClient
      client.addEventListener('message', messageHandler)
    }

    return () => {
      client?.removeEventListener('message', messageHandler)
      clearInterval(interval)
    }
  }, [getStreams, messageHandler, webPubClient])

  return streams
}

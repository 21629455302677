import {
  OrganizationInfo,
  usePopulatedTopbarValues,
} from '@axteams-one/populated-topbar'
import React, { createContext, useContext, useEffect } from 'react'

import { LoadingPage } from '../components/Loading'

type CurrentOrganizationContextProps = OrganizationInfo & { name: string }

const CurrentOrganizationContext =
  createContext<CurrentOrganizationContextProps>({ id: '', arn: '', name: '' })

type CurrentOrganizationProviderProps = {
  children: React.ReactNode
}

function CurrentOrganizationProvider({
  children,
}: CurrentOrganizationProviderProps) {
  const { organization, organizations, loaded, setOrganization } =
    usePopulatedTopbarValues()

  // If the selected organization doesn't exist, we will select
  // the first available organization instead
  useEffect(() => {
    if (organizations && organizations.length !== 0 && !organization) {
      setOrganization(organizations[0].id)
    }
  }, [organization, organizations, setOrganization])

  if (!loaded || !organizations || organizations.length === 0) {
    return <LoadingPage />
  }

  // Cancel rendering until next frame, as the selected
  // organization doesn't exist and will be reset
  if (!organization?.id) {
    return <LoadingPage />
  }

  const currentOrganization = {
    ...organization,
    name: organizations.find((org) => org.id === organization.id)?.label ?? '',
  }

  return (
    <CurrentOrganizationContext.Provider value={currentOrganization}>
      {children}
    </CurrentOrganizationContext.Provider>
  )
}

export function useCurrentOrganization() {
  return useContext(CurrentOrganizationContext)
}

export { CurrentOrganizationProvider }

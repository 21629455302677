import {
  Skip15Backward20Regular,
  Skip15Forward20Regular,
} from '@axiscommunications/fluent-icons'
import useFlag from '@axteams-one/bws-cloud-flags/react/useFlag'
import {
  Body1,
  Body2,
  Button,
  Caption1,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTrigger,
  Text,
  Tooltip,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import {
  FullScreenMaximize16Regular,
  FullScreenMinimize16Regular,
  Info16Regular,
  Map16Regular,
  MoreHorizontal20Filled,
  Pause20Filled,
  PersonVoice16Regular,
  Play20Filled,
  ShieldLock20Regular,
  Speaker216Regular,
  SpeakerOff16Regular,
} from '@fluentui/react-icons'
import { Temporal } from '@js-temporal/polyfill'
import { MutableRefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flags } from '../../util/flags'
import { Stream } from '../../util/stream'
import { durationFromSecondsToString } from '../../util/time'
import { VideoControls } from './VideoControls'
import { PlayerState } from './VideoPlayer'

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    columnGap: tokens.spacingHorizontalL,
    paddingInline: tokens.spacingHorizontalM,
    '> div': {
      alignItems: 'center',
    },
  },
  leftSection: {
    display: 'flex',
    containerType: 'inline-size',
    containerName: 'leftSection',
    justifyContent: 'left',
    gap: tokens.spacingHorizontalXS,
  },
  liveDot: {
    backgroundColor: tokens.colorBrandBackground,
    height: '7px',
    minWidth: '7px',
    width: '7px',
    borderRadius: '50%',
    marginInlineEnd: tokens.spacingHorizontalXS,
    '@container leftSection (inline-size < 100px)': {
      display: 'none',
    },
  },
  bearerName: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    '@container leftSection (inline-size < 60px)': {
      display: 'none',
    },
  },
  e2eeIcon: {
    display: 'flex',
    flexShrink: 0,
    '@container leftSection (inline-size < 100px)': {
      display: 'none',
    },
  },
  centerSection: {
    userSelect: 'auto',
    display: 'flex',
    gridColumnStart: 2,
    justifyContent: 'center',
    gap: tokens.spacingHorizontalXXS,
  },
  rightSection: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    containerType: 'inline-size',
    containerName: 'rightSection',
    gridColumnStart: 3,
    justifyContent: 'space-between',
    gap: tokens.spacingHorizontalXS,
  },
  timeLabel: {
    fontVariantNumeric: 'tabular-nums',
    '@container rightSection (inline-size < 140px)': {
      display: 'none',
    },
  },
  duration: {
    color: tokens.colorNeutralForeground3,
    '@container rightSection (inline-size < 300px)': {
      display: 'none',
    },
  },
  rightSectionIconButtons: {
    display: 'flex',
    justifySelf: 'end',
    gap: tokens.spacingHorizontalS,
    '@container rightSection (inline-size < 230px)': {
      display: 'none',
    },
  },
  playbackRateButton: {
    paddingBlock: '1px',
    paddingInline: '4px',
    width: '24px',
    minWidth: '24px',
  },
  moreMenu: {
    display: 'none',
    justifySelf: 'end',
    gridColumnStart: 2,
    '@container rightSection (inline-size < 230px)': {
      display: 'flex',
    },
  },
})

interface ControlButton {
  className?: string
  disabled?: boolean
  menuDivider?: boolean
  icon?: JSX.Element
  onClick: () => void
  hide?: boolean
  label: string
}

type ControlButtonsProps = {
  stream: Stream
  controlsWrapper: VideoControls
  state: PlayerState
  shouldBeLive: boolean
  showInfoOverlay: boolean
  fullscreen: boolean
  isScrubbingRef: MutableRefObject<boolean>
  displayedPosition: number
  lastKnownLocation?: GeolocationPosition
}

export default function ControlButtons({
  stream,
  controlsWrapper,
  state,
  shouldBeLive,
  showInfoOverlay,
  fullscreen,
  isScrubbingRef,
  displayedPosition,
  lastKnownLocation,
}: ControlButtonsProps) {
  const styles = useStyles()
  const { t } = useTranslation('video-player')

  const enableStreamInfo = useFlag(Flags.STREAM_INFO)?.enabled === true

  const centerControlButtons = getCenterButtons(
    state.isPlaying,
    isScrubbingRef,
    shouldBeLive,
    controlsWrapper
  ).filter((controlButton) => !controlButton.hide)

  const rightControlButtons = getRightSideButtons(
    state.isMuted,
    controlsWrapper,
    showInfoOverlay,
    fullscreen,
    !stream.ongoing,
    enableStreamInfo,
    lastKnownLocation
  ).filter((controlButton) => !controlButton.hide)

  const bearerName = stream.metadata.bearerName || t('common:na')
  const bearerShownId = stream.metadata.bearerShowId

  return (
    <div className={styles.container}>
      <LeftSection bearerName={bearerName} bearerShownId={bearerShownId} />
      <CenterSection buttons={centerControlButtons} />
      <RightSection
        streamOngoing={stream.ongoing}
        startTimestamp={stream.metadata.startTimestamp}
        controlsWrapper={controlsWrapper}
        buttons={rightControlButtons}
        displayedPosition={displayedPosition}
        state={state}
        shouldBeLive={shouldBeLive}
      />
    </div>
  )
}

/**
 * Returns the center buttons for the video controls.
 *
 * Buttons: skip backwards 15 seconds, play/pause video, skip forward 15 seconds.
 **/
function getCenterButtons(
  isPlaying: boolean,
  isScrubbingRef: MutableRefObject<boolean>,
  isLive: boolean,
  controlsWrapper: VideoControls
): ControlButton[] {
  const [playPauseIcon, playPauseLabel, playPauseOnClick] =
    isPlaying && !isScrubbingRef.current
      ? [<Pause20Filled />, 'pause', controlsWrapper.pause]
      : [<Play20Filled />, 'play', controlsWrapper.play]

  const skipForwardFill = isLive
    ? tokens.colorNeutralForegroundDisabled
    : tokens.colorNeutralForeground1

  return [
    {
      icon: <Skip15Backward20Regular fill={tokens.colorNeutralForeground1} />,
      onClick: () => controlsWrapper.skip(-15),
      label: 'skip-backward-15-seconds',
    },
    {
      icon: playPauseIcon,
      onClick: playPauseOnClick,
      label: playPauseLabel,
    },
    {
      icon: <Skip15Forward20Regular fill={skipForwardFill} />,
      onClick: () => controlsWrapper.skip(15),
      disabled: isLive,
      label: 'skip-forward-15-seconds',
    },
  ]
}

/**
 * Returns the right side buttons for the video controls.
 *
 * Buttons: Acknowledge stream, open in Google Maps, mute / unmute video , show / hide info, toggle fullscreen.
 **/
function getRightSideButtons(
  isMuted: boolean,
  controlsWrapper: VideoControls,
  showInfoOverlay: boolean,
  isFullscreen: boolean,
  hideAcknowledgeButton: boolean,
  enableStreamInfo: boolean,
  lastKnownLocation?: GeolocationPosition
): ControlButton[] {
  const [speakerIcon, speakerLabel, speakerOnClick] = isMuted
    ? [<SpeakerOff16Regular />, 'unmute', () => controlsWrapper.unmute()]
    : [<Speaker216Regular />, 'mute', () => controlsWrapper.mute()]

  const infoOverlayLabel = showInfoOverlay
    ? 'hide-video-information'
    : 'video-information'

  const [fullscreenIcon, fullscreenLabel] = isFullscreen
    ? [<FullScreenMinimize16Regular />, 'exit-fullscreen']
    : [<FullScreenMaximize16Regular />, 'fullscreen']

  return [
    {
      icon: <PersonVoice16Regular />,
      onClick: () => controlsWrapper.acknowledgeStream(),
      menuDivider: true,
      hide: hideAcknowledgeButton,
      label: 'acknowledge-stream',
    },
    {
      icon: <Map16Regular />,
      onClick: () => controlsWrapper.OpenInGoogleMaps(),
      disabled: !lastKnownLocation,
      label: 'open-in-google-maps',
    },
    {
      icon: speakerIcon,
      onClick: speakerOnClick,
      label: speakerLabel,
    },
    {
      icon: <Info16Regular />,
      onClick: () => controlsWrapper.toggleInfoOverlay(),
      hide: !enableStreamInfo,
      label: infoOverlayLabel,
    },
    {
      icon: fullscreenIcon,
      onClick: () => controlsWrapper.fullscreen(),
      hide: !document.fullscreenEnabled,
      label: fullscreenLabel,
    },
  ]
}

type LeftSectionProps = {
  bearerName: string
  bearerShownId?: string
}

function LeftSection({ bearerName, bearerShownId }: LeftSectionProps) {
  const styles = useStyles()
  const { t } = useTranslation('video-player')

  const label = t('e2ee-information')

  return (
    <div className={mergeClasses(styles.leftSection)}>
      <span className={mergeClasses(styles.liveDot, 'liveDot')} />
      <Caption1 className={styles.bearerName}>
        {bearerName}
        {bearerShownId && ` (${bearerShownId})`}
      </Caption1>
      <Tooltip
        content={label}
        mountNode={document.fullscreenElement}
        relationship={'description'}
      >
        <ShieldLock20Regular
          className={styles.e2eeIcon}
          color={tokens.colorNeutralForeground2}
        />
      </Tooltip>
    </div>
  )
}

type CenterSectionProps = {
  buttons: ControlButton[]
}

function CenterSection({ buttons }: CenterSectionProps) {
  const styles = useStyles()
  const { t } = useTranslation('video-player')

  const buttonsElements = buttons.map(
    ({ className, disabled, icon, label, onClick }, index) => (
      <Tooltip
        key={index}
        content={t(label)}
        mountNode={document.fullscreenElement}
        relationship={'label'}
      >
        <Button
          key={index}
          className={className}
          disabled={disabled}
          appearance="subtle"
          icon={icon}
          onClick={onClick}
        />
      </Tooltip>
    )
  )

  return <div className={styles.centerSection}>{buttonsElements}</div>
}

type RightSectionProps = {
  startTimestamp?: Temporal.ZonedDateTime
  streamOngoing: boolean
  controlsWrapper: VideoControls
  buttons: ControlButton[]
  displayedPosition: number
  state: PlayerState
  shouldBeLive: boolean
}

function RightSection({
  streamOngoing,
  startTimestamp,
  controlsWrapper,
  buttons,
  displayedPosition,
  state,
  shouldBeLive,
}: RightSectionProps) {
  const styles = useStyles()
  const { t } = useTranslation('video-player')

  const enablePlaybackRate = useFlag(Flags.PLAYBACK_RATE)?.enabled

  const buttonsElements = buttons.map(
    ({ className, disabled, icon, onClick, label }, index) => (
      <Tooltip
        key={index}
        content={t(label)}
        mountNode={document.fullscreenElement}
        relationship={'label'}
      >
        <Button
          className={className}
          disabled={disabled}
          appearance="subtle"
          size="small"
          icon={icon}
          onClick={onClick}
        />
      </Tooltip>
    )
  )

  // The menu is only displayed when the video element window is too narrow.
  const menuItems = buttons.map(
    ({ menuDivider, disabled, icon, onClick, label }, index) => (
      <div key={index}>
        <MenuItem key={index} disabled={disabled} icon={icon} onClick={onClick}>
          {t(label)}
        </MenuItem>
        {menuDivider && <MenuDivider />}
      </div>
    )
  )

  return (
    <div className={styles.rightSection}>
      <TimeLabel
        streamOngoing={streamOngoing}
        startTimestamp={startTimestamp}
        presentedDuration={state.presentedDuration}
        displayedPosition={displayedPosition}
        shouldBeLive={shouldBeLive}
      />
      <div className={styles.rightSectionIconButtons}>
        {enablePlaybackRate && (
          <PlaybackRate
            controlsWrapper={controlsWrapper}
            playbackRate={state.playbackRate || 1}
            isLive={state.isLive}
          />
        )}
        {buttonsElements}
      </div>
      <div className={styles.moreMenu}>
        <Menu mountNode={document.fullscreenElement}>
          <MenuTrigger disableButtonEnhancement>
            <Button appearance="subtle" icon={<MoreHorizontal20Filled />} />
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {menuItems}
              {enablePlaybackRate && (
                <PlaybackRate
                  controlsWrapper={controlsWrapper}
                  playbackRate={state.playbackRate}
                  isLive={state.isLive}
                  isSubMenu={true}
                />
              )}
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </div>
  )
}

type TimeLabel = {
  startTimestamp?: Temporal.ZonedDateTime
  streamOngoing: boolean
  presentedDuration: number
  displayedPosition: number
  shouldBeLive: boolean
}

function TimeLabel({
  startTimestamp,
  streamOngoing,
  presentedDuration,
  displayedPosition,
  shouldBeLive,
}: TimeLabel) {
  const styles = useStyles()
  const { t } = useTranslation('video-player')

  const localTime = startTimestamp
    ? startTimestamp
        .add(
          Temporal.Duration.from({
            seconds: Number(Math.round(displayedPosition)),
          })
        )
        .toPlainTime()
        .toLocaleString()
    : t('common:na')

  if (shouldBeLive) {
    return <Body2 className={styles.timeLabel}>{localTime}</Body2>
  }

  const videoProgress = durationFromSecondsToString(
    Math.round(displayedPosition)
  )

  const offsetFromLive = durationFromSecondsToString(
    Math.round(presentedDuration - displayedPosition)
  )

  const duration = streamOngoing ? `-${offsetFromLive}` : videoProgress
  const tooltipText = streamOngoing
    ? t('offset-from-live')
    : t('video-progress')

  return (
    <div className={styles.timeLabel}>
      <Body2>{localTime}</Body2>
      <Tooltip
        content={tooltipText}
        mountNode={document.fullscreenElement}
        relationship={'label'}
      >
        <Text className={styles.duration}> / {duration}</Text>
      </Tooltip>
    </div>
  )
}

type PlaybackRateProps = {
  controlsWrapper: VideoControls
  playbackRate: number
  isLive: boolean
  isSubMenu?: boolean
}

function PlaybackRate({
  controlsWrapper,
  playbackRate,
  isLive,
  isSubMenu,
}: PlaybackRateProps) {
  const { t } = useTranslation('video-player')
  const styles = useStyles()

  const [checkedPlaybackRate, setCheckedPlaybackRate] = useState<
    Record<string, string[]>
  >({
    playbackRate: [`${playbackRate}`],
  })

  const onPlaybackRateChange: MenuProps['onCheckedValueChange'] = (
    _event,
    { name, checkedItems }
  ) => {
    setCheckedPlaybackRate((s) => ({
      ...s,
      [name]: checkedItems,
    }))
  }

  const playbackRates = [1, 2, 4]

  useEffect(() => {
    setCheckedPlaybackRate({
      playbackRate: [`${playbackRate}`],
    })
  }, [playbackRate])

  return (
    <Menu mountNode={document.fullscreenElement}>
      <MenuTrigger disableButtonEnhancement>
        {isSubMenu ? (
          <Tooltip
            content={t('playback-speed')}
            mountNode={document.fullscreenElement}
            relationship={'label'}
          >
            <MenuItem icon={<Body1>{`${playbackRate}x`}</Body1>}>
              {t('playback-speed')}
            </MenuItem>
          </Tooltip>
        ) : (
          <Tooltip
            content={t('playback-speed')}
            mountNode={document.fullscreenElement}
            relationship={'label'}
          >
            <Button className={styles.playbackRateButton} appearance="subtle">
              {`${playbackRate}x`}
            </Button>
          </Tooltip>
        )}
      </MenuTrigger>
      <MenuPopover>
        <MenuList
          checkedValues={checkedPlaybackRate}
          onCheckedValueChange={onPlaybackRateChange}
        >
          {playbackRates.map((playbackRate) => {
            return (
              <MenuItemRadio
                disabled={isLive && playbackRate > 1}
                key={playbackRate}
                onClick={() => controlsWrapper.setPlaybackRate(playbackRate)}
                value={`${playbackRate}`}
                name={'playbackRate'}
              >
                {`${playbackRate}x`}
              </MenuItemRadio>
            )
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}

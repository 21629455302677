import { AuthProvider, useAuth } from '@axteams-one/auth-provider'
import { useApi } from '@axteams-one/bws-cloud-discovery/react'
import {
  CompoundRetriever,
  LocalFlagRetriever,
  OrganizationalFlagRetriever,
} from '@axteams-one/bws-cloud-flags'
import { FlagProvider } from '@axteams-one/bws-cloud-flags/react'
import { usePopulatedTopbarValues } from '@axteams-one/populated-topbar'
import { ReactNode, useCallback, useMemo } from 'react'

import { config, isDev } from '../config'
import { TopbarProvider } from '../providers/TopbarProvider'

type AuthenticatedRouteProps = {
  readonly children: React.ReactNode
}

export function AuthenticatedRoute({ children }: AuthenticatedRouteProps) {
  const env = config.environment === 'prod' ? 'prod' : 'stage'
  return (
    <AuthProvider
      env={env}
      localDevelopment={isDev(window.location.hostname)}
      baseUrl={config.loginUrl}
    >
      <TopbarProvider>
        <FlagWrapper>{children}</FlagWrapper>
      </TopbarProvider>
    </AuthProvider>
  )
}

type FlagWrapperProps = {
  children?: ReactNode
}

function FlagWrapper({ children }: FlagWrapperProps) {
  const bwoApi = useApi('bwo')
  const { organization, loaded } = usePopulatedTopbarValues()
  const { refresh } = useAuth()

  const getAccessToken = useCallback(async () => {
    const response = await refresh()
    const token = response.data?.access_token
    if (!token) {
      throw new Error('Invalid refresh token response')
    }
    return token
  }, [refresh])

  const flagRetriever = useMemo(() => {
    const localFlagRetriever = new LocalFlagRetriever(localStorage)

    if (!loaded || !organization || !bwoApi) {
      return localFlagRetriever
    }

    return new CompoundRetriever(
      new OrganizationalFlagRetriever(
        bwoApi.uri,
        organization.id,
        getAccessToken
      ),
      localFlagRetriever
    )
  }, [bwoApi, getAccessToken, loaded, organization])

  return <FlagProvider flagRetriever={flagRetriever}>{children}</FlagProvider>
}

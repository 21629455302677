import { PlayerState as DefaultBwoPlayerState } from '@axteams-one/bws-cloud-bwo-player'
import { EncryptionOptions } from '@axteams-one/bws-cloud-bwo-player/player'
import { usePlayer } from '@axteams-one/bws-cloud-bwo-player/react'
import { useTimeSource } from '@axteams-one/bws-cloud-time-source/react'
import {
  Spinner,
  Toast,
  ToastBody,
  ToastTitle,
  makeStyles,
  mergeClasses,
  tokens,
  useToastController,
} from '@fluentui/react-components'
import { Temporal } from '@js-temporal/polyfill'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TOASTER_ID } from '../../constants'
import { ErrorCode } from '../../graphql/__generated__/graphql'
import { ApiStatus } from '../../hooks/types'
import { useAcknowledgeStream } from '../../hooks/useAcknowledgeStream'
import { Alert, useAlerts } from '../../hooks/useAlerts'
import { StoredKey } from '../../hooks/useContentKeys'
import { useLastKnownLocation } from '../../hooks/useLastKnownLocation'
import { metadataMapper } from '../../util/mapper'
import { Metadata, Stream } from '../../util/stream'
import NameBadge from './NameBadge'
import { VideoControls } from './VideoControls'
import { VideoDebugCard, VideoDebugData } from './VideoDebugCard'

const useStyles = makeStyles({
  videoLoaded: {
    '& .videoControls': {
      opacity: 1,
    },
  },
  fullscreen: {
    '& .videoControls': {
      position: 'absolute',
      width: '100%',
      opacity: '0.95',
      backgroundColor: tokens.colorNeutralBackground2,
      bottom: 0,
    },
  },
  pointerFullscreenInactive: {
    '& .videoControls': {
      opacity: 0,
      pointerEvents: 'none',
      transition: 'opacity 500ms',
    },
    '& .nameBadge': {
      display: 'flex',
    },
  },
  pointerInactive: {
    '& .overlayControls': {
      '& .scrubber': {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 200ms',
      },
      '& .timeLabel': {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 100ms',
      },
      '& .liveButton': {
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity 100ms',
      },
      opacity: 0,
      pointerEvents: 'none',
      transition: 'opacity 400ms',
    },
    '& .timelineSection': {
      transform: 'translate(0, 50%)',
      transition: 'transform 300ms',
    },
    '& .timeline': {
      opacity: 1,
      transition: 'opacity 200ms 100ms',
    },
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: 'inherit',
  },
  videoContainer: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: tokens.colorStrokeFocus1,
  },
  video: {
    aspectRatio: 5 / 1,
    height: '100%',
  },
  /** Hide controls until video has loaded */
  videoControls: {
    backgroundColor: tokens.colorNeutralBackground2,
    opacity: 0,
  },
  /** videOverlay is a full-size overlay which handles content scrolling. */
  videoOverlay: {
    position: 'absolute',
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    width: '100%',
    height: '100%',
    padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalM}`,
  },
  spinner: {
    height: '100%',
    position: 'absolute',
  },
  alert: {
    backgroundColor: tokens.colorBackgroundOverlay,
    color: tokens.colorNeutralStrokeOnBrand2,
  },
  alertContainer: {
    position: 'absolute',
    zIndex: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: tokens.spacingVerticalMNudge,
  },
})

export interface PlayerState extends Omit<DefaultBwoPlayerState, 'metadata'> {
  metadata: Metadata
}
type VideoPlayerProps = {
  contentKeys: StoredKey[]
  stream: Stream
  reexecuteGetStream: () => void
  inGroupView?: boolean
}

// Threshold to hide controls in fullscreen.
const POINTER_LAST_MOVE_THRESHOLD = 3000

function VideoPlayer({
  contentKeys,
  stream,
  reexecuteGetStream,
  inGroupView = false,
}: VideoPlayerProps) {
  const styles = useStyles()
  const { t } = useTranslation('video-player')
  const loadedStreamIdRef = useRef<string | null>()
  /**
   * After loading the video the useEffect will be re-triggered. This ref keeps
   * track of whether or not the video should update when the useEffect is
   * triggered. Player will crash if video updates when there is no content
   * loaded and video should therefor not be updated after loading in case
   * loading was caused by video stuck in bad playback state.
   */
  const updateVideoRef = useRef<boolean>()
  const stuckPlaybackPositionRef = useRef<number | null>()
  const containerRef = useRef<HTMLDivElement>(null)
  const inBadStateSinceRef = useRef<number>()
  const badStateIntervalRef = useRef<number>(1000)
  const lostConnectionAlertRef = useRef<Alert>()
  const { dispatchToast, dismissToast } = useToastController(TOASTER_ID)
  const { dispatchAlert, renderedAlerts, removeAlert } = useAlerts()
  const acknowledgeStream = useAcknowledgeStream()
  const [showInfoOverlay, setShowInfoOverlay] = useState<boolean>(false)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [lastInteract, setLastInteract] = useState<Temporal.Instant>(
    Temporal.Now.instant().subtract({
      milliseconds: POINTER_LAST_MOVE_THRESHOLD + 1,
    })
  )
  const [muteOnLoad, setMuteOnLoad] = useState(true)
  const [showControls, setShowControls] = useState(false)
  const [shouldBeLive, setShouldBeLive] = useState<boolean>(stream.ongoing)
  const { clockIsLoading, clockIsSynced, timeSource } = useTimeSource()
  const { player, videoRef, state, controls } = usePlayer()
  const playerState: PlayerState = useMemo(
    () => ({
      ...state,
      metadata: metadataMapper(state.metadata),
    }),
    [state]
  )
  const [debugData, setDebugData] = useState<VideoDebugData>({
    media: {},
    timing: {},
    playerState,
  })
  const { lastKnownLocation } = useLastKnownLocation(
    playerState.position,
    playerState.metadata.gps,
    stream.metadata.startTimestamp
  )

  const containerClassName = () => {
    let classes = styles.container

    const inactiveOrAbsent =
      lastInteract.until(Temporal.Now.instant(), {
        largestUnit: 'millisecond',
      }).milliseconds > POINTER_LAST_MOVE_THRESHOLD && !showControls

    if (loadedStreamIdRef.current) {
      classes = mergeClasses(classes, styles.videoLoaded)
    }
    if (document.fullscreenElement || inGroupView) {
      classes = mergeClasses(classes, styles.fullscreen)
      if (inactiveOrAbsent) {
        classes = mergeClasses(classes, styles.pointerFullscreenInactive)
      }
    }
    if (inactiveOrAbsent) {
      classes = mergeClasses(classes, styles.pointerInactive)
    }
    return classes
  }

  useEffect(() => {
    setDebugData({
      media: {
        streamId: stream.id,
        organizationId: stream.organizationId,
        storageLocationId: stream.dash?.storageLocationId,
        bearerId: stream.bearerId,
        encrypted: (stream.dash?.crypto && true) || false,
      },
      playerState,
      timing: {
        synced: clockIsSynced,
        delta: timeSource?.getDelta(),
      },
      positioning: playerState.metadata.gps,
    })
  }, [playerState, clockIsSynced, timeSource, stream])

  const handleAcknowledgeStream = useCallback(async () => {
    dispatchToast(
      <Toast>
        <ToastTitle media={<Spinner size="tiny" />}>
          {t('sending-acknowledgement')}
        </ToastTitle>
      </Toast>,
      { toastId: 'stream-acknowledgement' }
    )

    const result = await acknowledgeStream(stream.id)

    dismissToast('stream-acknowledgement')

    if (result?.status === ApiStatus.Rejected) {
      if (result.error === ErrorCode.BwoUnauthorized) {
        dispatchToast(
          <Toast>
            <ToastTitle>{t('common:unauthorized')}</ToastTitle>
            <ToastBody>{t('unauthorized-acknowledge-stream')}</ToastBody>
          </Toast>,
          { intent: 'error' }
        )
      } else {
        dispatchToast(
          <Toast>
            <ToastTitle>{t('failed-acknowledge-stream')}</ToastTitle>
            <ToastBody>{t('failed-acknowledge-stream-error')}</ToastBody>
          </Toast>,
          { intent: 'error' }
        )
      }
    } else if (result?.status === ApiStatus.Resolved) {
      dispatchToast(
        <Toast>
          <ToastTitle>Successfully acknowledged the stream</ToastTitle>
        </Toast>,
        { intent: 'success' }
      )
    }
  }, [acknowledgeStream, dismissToast, dispatchToast, stream.id, t])

  const extendedControls: VideoControls = {
    ...controls,
    OpenInGoogleMaps: () => handleOpenInGoogleMaps(lastKnownLocation),
    toggleInfoOverlay: () => {
      setShowInfoOverlay((previous) => !previous)
    },
    acknowledgeStream: handleAcknowledgeStream,
    fullscreen: () => handleFullscreen(containerRef.current),
  }

  // Load stream when player has been setup and the clock has loaded.
  useEffect(() => {
    if (player && stream.dash && !clockIsLoading) {
      if (loadedStreamIdRef.current === stream.id && updateVideoRef.current) {
        player.updateVideo({ dash: stream.dash })
      } else if (loadedStreamIdRef.current !== stream.id) {
        // If the stream is encrypted, specify the encryption options to use
        const encryptionOptions: EncryptionOptions | undefined = stream.dash
          .crypto
          ? {
              keys: contentKeys.map((x) => JSON.stringify(x.jwk)),
              protectionObject: JSON.stringify(stream.dash.crypto),
            }
          : undefined

        player
          .loadVideo({
            startAt: stuckPlaybackPositionRef.current
              ? stuckPlaybackPositionRef.current
              : undefined,
            autoPlay: true,
            muted: muteOnLoad,
            dash: stream.dash,
            timeSource,
            encryptionOptions,
          })
          .then(() => {
            loadedStreamIdRef.current = stream.id
            updateVideoRef.current = false
            stuckPlaybackPositionRef.current = null
          })
      } else {
        updateVideoRef.current = true
      }
    }
  }, [
    player,
    stream.dash,
    stream.ongoing,
    clockIsLoading,
    timeSource,
    contentKeys,
    stream.id,
    muteOnLoad,
  ])

  useEffect(() => {
    // TODO: Improve const naming. Use a more precise and descriptive name.
    // Whether or not the player is able to play.
    const isInBadState =
      stream.ongoing &&
      (playerState.status === 'LOADING' ||
        playerState.status === 'BUFFERING' ||
        playerState.status === 'SEEKING' ||
        playerState.status === 'RELOADING' ||
        playerState.status === 'STOPPED')

    let interval: ReturnType<typeof setInterval>

    // if player is in a bad state, track duration until player is no longer in
    // a bad stat.
    if (isInBadState) {
      inBadStateSinceRef.current =
        inBadStateSinceRef.current ?? Temporal.Now.instant().epochMilliseconds
      interval = setInterval(() => {
        const timeInBadState =
          Temporal.Now.instant().epochMilliseconds -
          (inBadStateSinceRef.current || 0)
        const tenSecondsInBadState = timeInBadState > 10 * 1000
        const threeSecondsInBadState = timeInBadState > 3 * 1000

        setShowSpinner(threeSecondsInBadState)

        // If player is stuck in bad state, reduce tracking frequency
        // to every 5 seconds.
        if (tenSecondsInBadState) {
          badStateIntervalRef.current = 5000

          if (!lostConnectionAlertRef.current) {
            lostConnectionAlertRef.current = dispatchAlert({
              className: styles.alert,
              intent: 'warning',
              message: t('lost-connection-to-stream'),
              unique: true,
            })
          }

          // Reset id, to trigger reload of video on next stream query, in order
          // to reload buffer with missing video segments.
          if (!playerState.isLive || playerState.status === 'STOPPED') {
            loadedStreamIdRef.current = undefined
            /**
             * Add 1 second to position in case segment is missing, e.g due to
             * index corrected chunk.
             */
            stuckPlaybackPositionRef.current = Math.round(
              playerState.position + 1
            )
            // Retain user mute/unmute setting on video reload.
            setMuteOnLoad(state.isMuted)
          }

          // Trigger update or reload of video.
          reexecuteGetStream()
        }
      }, badStateIntervalRef.current)
    } else {
      inBadStateSinceRef.current = undefined
      badStateIntervalRef.current = 1000
      if (lostConnectionAlertRef.current) {
        removeAlert(lostConnectionAlertRef.current)
        lostConnectionAlertRef.current = undefined
      }
      setShowSpinner(false)
    }

    // Clear the timer on cleanup
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [
    dispatchAlert,
    playerState.isLive,
    playerState.position,
    playerState.status,
    reexecuteGetStream,
    removeAlert,
    state.isMuted,
    stream.ongoing,
    styles.alert,
    t,
  ])

  // Replace token before it expires.
  useEffect(() => {
    if (!stream.dash) {
      return
    }
    // Token expiry time. In case token is incorrectly formatted, fallback to
    // current time + 3 min.
    let tokenExpiresAt: Temporal.Instant

    try {
      const tokenExpiresAtParam = new URLSearchParams(
        decodeURIComponent(stream.dash.token)
      ).get('se')
      tokenExpiresAt = tokenExpiresAtParam
        ? Temporal.Instant.from(tokenExpiresAtParam)
        : Temporal.Now.instant().add({ minutes: 3 })
    } catch {
      tokenExpiresAt = Temporal.Now.instant().add({ minutes: 3 })
    }

    const duration = Temporal.Now.instant()
      .until(tokenExpiresAt)
      .round({ largestUnit: 'millisecond' }).milliseconds

    const timeout80 = setTimeout(() => {
      reexecuteGetStream()
      clearTimeout(timeout80)
      // update token when 80% of lifetime has passed.
    }, duration * 0.8)

    const timeout90 = setTimeout(() => {
      reexecuteGetStream()
      // If token is not updated (i.e. stream.dash has not changed), try once
      // more when 90% of lifetime has passed.
      clearTimeout(timeout90)
    }, duration * 0.9)

    return () => {
      clearTimeout(timeout80)
      clearTimeout(timeout90)
    }
  }, [reexecuteGetStream, stream.dash])

  const handleClickOnPlayer = () => setLastInteract(Temporal.Now.instant())
  const handlePointerMoveOnPlayer = () =>
    setLastInteract(Temporal.Now.instant())
  const handlePointerLeavePlayer = () =>
    setLastInteract(
      Temporal.Now.instant().subtract({
        milliseconds: POINTER_LAST_MOVE_THRESHOLD + 100,
      })
    )
  const handlePointerEnterControls = () => setShowControls(true)
  const handlePointerLeaveControls = () => setShowControls(false)

  return (
    <div
      ref={containerRef}
      className={containerClassName()}
      onClick={handleClickOnPlayer}
      onPointerMove={handlePointerMoveOnPlayer}
      onPointerLeave={handlePointerLeavePlayer}
    >
      <div className={styles.alertContainer}>{renderedAlerts}</div>
      <div className={styles.videoContainer}>
        {showSpinner && (
          <Spinner
            appearance="primary"
            className={styles.spinner}
            size="huge"
          />
        )}
        <video
          className={styles.video}
          ref={videoRef}
          data-testid={stream.metadata.bearerName + 'Video'}
        />
        {showInfoOverlay && (
          <div className={styles.videoOverlay}>
            <VideoDebugCard data={debugData} />
          </div>
        )}
      </div>
      <NameBadge
        isRecording={playerState.isRecording}
        shouldBeLive={shouldBeLive}
        bearerName={stream.metadata.bearerName}
        bearerShowId={stream.metadata.bearerShowId}
      />
      <div
        className={mergeClasses(styles.videoControls, 'videoControls')}
        onPointerEnter={handlePointerEnterControls}
        onPointerLeave={handlePointerLeaveControls}
      >
        <VideoControls
          shouldBeLive={shouldBeLive}
          setShouldBeLive={setShouldBeLive}
          stream={stream}
          controls={extendedControls}
          showInfoOverlay={showInfoOverlay}
          fullscreen={
            document.fullscreenElement !== null &&
            document.fullscreenElement === containerRef.current
          }
          state={playerState}
          setLastInteract={setLastInteract}
          lastKnownLocation={lastKnownLocation}
        />
      </div>
    </div>
  )
}

function handleFullscreen(element: HTMLDivElement | null) {
  if (!element) {
    return
  }
  if (document.fullscreenElement && document.fullscreenElement !== element) {
    document.exitFullscreen().then(() =>
      element.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        )
      })
    )
  }

  if (!document.fullscreenElement) {
    element.requestFullscreen().catch((err) => {
      alert(
        `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
      )
    })
  } else {
    document.exitFullscreen()
  }
}

function handleOpenInGoogleMaps(lastKnownLocation?: GeolocationPosition) {
  if (!lastKnownLocation) {
    return
  }

  window.open(
    `https://www.google.com/maps/search/?api=1&query=${
      lastKnownLocation.coords.latitude
    }${encodeURIComponent(',')}${lastKnownLocation.coords.longitude}`,
    'googleMapsLocation'
  )
}

export { VideoPlayer }

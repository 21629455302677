import {
  NoContentDark,
  NoContentLight,
} from '@axiscommunications/fluent-illustrations'
import { useTrackPageView } from '@axteams-one/bws-cloud-metrics/react'
import { makeStyles, tokens } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'

import { Illustration } from '../components/Illustration'
import { useThemeId } from '../providers/ThemeProvider'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: tokens.spacingVerticalM,
    padding: tokens.spacingHorizontalL,
  },
})

export function StreamsStartPage() {
  const { t } = useTranslation('streams')
  useTrackPageView()
  const styles = useStyles()
  const [themeId] = useThemeId()

  return (
    <div className={styles.container}>
      <div className={styles.container}>
        <Illustration
          title={t('no-stream-selected')}
          description={t('start-page-instruction')}
          illustration={themeId === 'light' ? NoContentLight : NoContentDark}
        />
      </div>
    </div>
  )
}

import {
  Avatar,
  Button,
  Card,
  MessageBar,
  MessageBarBody,
  SkeletonItem,
  Spinner,
  Subtitle1,
  Toast,
  ToastBody,
  ToastTitle,
  makeStyles,
  tokens,
  useToastController,
} from '@fluentui/react-components'
import { Certificate20Regular, Clock20Regular } from '@fluentui/react-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TOASTER_ID } from '../../constants'
import { ErrorCode, LicenseType } from '../../graphql/__generated__/graphql'
import { ApiStatus } from '../../hooks/types'
import { PRODUCT_NAME_BWL, useLicenseInfo } from '../../hooks/useLicenseInfo'
import { useStartTrial } from '../../hooks/useStartTrial'
import { Api, useUrqlClient } from '../../hooks/useUrqlClient'
import { useCurrentOrganization } from '../../providers/CurrentOrganizationProvider'
import { useGetPopulatedTopbarUrl } from '../../providers/TopbarProvider'
import { MySystemsApplicationId } from '../../util/MySystemsApplicationId'
import { getActiveLicense } from '../../util/license'
import { ExternalLink } from '../ExternalLink'
import { EndedTrial } from './EndedTrial'
import { NoConnectedSystem } from './NoConnectedSystems'
import { StartTrial } from './StartTrial'

const useStyles = makeStyles({
  card: {
    maxWidth: '600px',
    height: '220px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  actions: {
    display: 'flex',
    flexGrow: '1',
    columnGap: tokens.spacingHorizontalL,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  licenseManagerLink: {
    marginLeft: 'auto',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content auto',
    columnGap: tokens.spacingHorizontalS,
    alignItems: 'center',
  },
  templateList: {
    display: 'grid',
    gridTemplateColumns: '32px 128px 128px',
    columnGap: tokens.spacingHorizontalS,
    alignItems: 'center',
  },
  listItemIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 0',
  },
  listItemTitle: {
    fontWeight: 'bold',
  },
})

export function LicenseSettings() {
  const styles = useStyles()
  const { dispatchToast, dismissToast } = useToastController(TOASTER_ID)
  const { t } = useTranslation('settings-license')
  const organizationId = useCurrentOrganization().id
  const licenseInfo = useLicenseInfo(
    useUrqlClient(Api.Bwo),
    organizationId,
    PRODUCT_NAME_BWL
  )
  const startTrial = useStartTrial()
  const [disableStartTrial, setDisableStartTrial] = useState<boolean>(false)

  const statusLoading =
    licenseInfo.status === ApiStatus.Idle ||
    licenseInfo.status === ApiStatus.Loading

  if (statusLoading) {
    return (
      <SettingsCard>
        <div className={styles.templateList}>
          <div className={styles.listItemIcon}>
            <SkeletonItem size={32} shape="circle" />
          </div>
          <SkeletonItem size={16} />
          <SkeletonItem size={16} />
          <div className={styles.listItemIcon}>
            <SkeletonItem size={32} shape="circle" />
          </div>
          <SkeletonItem size={16} />
          <SkeletonItem size={16} />
        </div>
      </SettingsCard>
    )
  }

  if (licenseInfo.status === ApiStatus.Rejected) {
    if (licenseInfo.error === ErrorCode.BwoUnauthorized) {
      return <NotAuthorized />
    } else if (licenseInfo.error === ErrorCode.BwoOrganizationNotFound) {
      return <NoConnectedSystem />
    } else {
      return <UnknownError />
    }
  }

  // We've successfully resolved license information but the organization is
  // not licensed
  if (!licenseInfo.data.licensed) {
    const trialEnded = licenseInfo.data.licenses.some(
      (l) => l.type === LicenseType.Trial
    )
    if (trialEnded) {
      return <EndedTrial />
    }
    return (
      <StartTrial
        disableStartTrial={disableStartTrial}
        onStartTrial={handleStartTrial}
      />
    )
  }

  const activeLicense = getActiveLicense(licenseInfo.data)

  return (
    <SettingsCard>
      <div className={styles.list}>
        <div className={styles.listItemIcon}>
          <Avatar icon={<Certificate20Regular />} />
        </div>
        <span className={styles.listItemTitle}>{t('status')}</span>
        <span>{activeLicense?.type}</span>
        <div className={styles.listItemIcon}>
          <Avatar icon={<Clock20Regular />} />
        </div>
        <span className={styles.listItemTitle}>{t('license-expiration')}</span>
        <span>
          {activeLicense.expire
            .toZonedDateTimeISO('UTC')
            .toPlainDate()
            .toString()}
        </span>
      </div>
    </SettingsCard>
  )

  async function handleStartTrial() {
    // Prevent multiple clicks
    setDisableStartTrial(true)

    dispatchToast(
      <Toast>
        <ToastTitle media={<Spinner size="tiny" />}>
          {t('start-trial--progress')}
        </ToastTitle>
      </Toast>,
      { toastId: 'progress-toast' }
    )

    const result = await startTrial(organizationId)

    dismissToast('progress-toast')

    if (result?.status === ApiStatus.Rejected) {
      setDisableStartTrial(false)
      if (result.error === ErrorCode.BwoUnauthorized) {
        dispatchToast(
          <Toast>
            <ToastTitle>{t('common:unauthorized')}</ToastTitle>
            <ToastBody>{t('start-trial--unauthorized')}</ToastBody>
          </Toast>,
          { intent: 'error' }
        )
      } else {
        dispatchToast(
          <Toast>
            <ToastTitle>{t('start-trial--unknown-error')}</ToastTitle>
            <ToastBody>{t('start-trial--unknown-error-message')}</ToastBody>
          </Toast>,
          { intent: 'error' }
        )
      }
    } else {
      // TODO(martinlu): Blunt solution until we have a better one (react-query?)
      location.reload()
    }
  }
}

type SettingsCardProps = {
  children: React.ReactNode
  disableStartTrial?: boolean
  showStartTrial?: boolean
  onStartTrial?: () => void
}

function SettingsCard({
  children,
  disableStartTrial = false,
  onStartTrial,
  showStartTrial = false,
}: SettingsCardProps) {
  const styles = useStyles()
  const licenseManagerUrl = useGetPopulatedTopbarUrl(
    MySystemsApplicationId.LICENSE
  )
  const { t } = useTranslation('settings-license')

  return (
    <Card size="large" className={styles.card}>
      <Subtitle1>{t('license')}</Subtitle1>
      <div className={styles.cardContent}>{children}</div>
      <div className={styles.actions}>
        {showStartTrial && (
          <Button
            disabled={disableStartTrial}
            appearance="primary"
            onClick={() => onStartTrial && onStartTrial()}
          >
            {t('start-trial')}
          </Button>
        )}
        <ExternalLink
          className={styles.licenseManagerLink}
          href={licenseManagerUrl}
          text="AXIS License Manager"
        />
      </div>
    </Card>
  )
}

function NotAuthorized() {
  const styles = useStyles()
  const { t } = useTranslation('settings-license')

  return (
    <Card size="large" className={styles.card}>
      <div className={styles.cardContent}>
        <Subtitle1>{t('license')}</Subtitle1>
        <MessageBar>
          <MessageBarBody>{t('show-licenses-unauthorized')}</MessageBarBody>
        </MessageBar>
      </div>
    </Card>
  )
}

function UnknownError() {
  const styles = useStyles()
  const { t } = useTranslation('settings-license')
  return (
    <Card size="large" className={styles.card}>
      <div className={styles.cardContent}>
        <Subtitle1>{t('license')}</Subtitle1>
        <MessageBar>
          <MessageBarBody>{t('show-licence-error')}</MessageBarBody>
        </MessageBar>
      </div>
    </Card>
  )
}

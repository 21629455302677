import { Temporal } from '@js-temporal/polyfill'

import { LicenseType } from '../graphql/__generated__/graphql'
import { LicenseInfo } from '../hooks/useLicenseInfo'

/**
 * Get all the licenses which havn't expired and return the one most meaningful
 * for the user. If they have an active annual license we don't care if they
 * also have a trial license which hasn't expired.
 */
export function getActiveLicense(licenseInfo: LicenseInfo) {
  const activeLicenses = licenseInfo.licenses.filter((l) => {
    return Temporal.Instant.compare(Temporal.Now.instant(), l.expire) === -1
  })
  const sortTable = {
    [LicenseType.Annual]: 0,
    [LicenseType.Demo]: 1,
    [LicenseType.Trial]: 2,
  }
  activeLicenses.sort((l1, l2) => sortTable[l1.type] - sortTable[l2.type])
  return activeLicenses[0]
}
